import { assertNever } from "@shared/util"
import {
  API_ROUTES,
  HttpMethod,
  type API_ROUTES_T,
  type ApiEndpoint,
  type AllowedSecurities,
  type ApiResponseFromKeyT,
} from "@shared/api"
import { logException } from "@/util"

export class ClientApiError {
  public body: string
  public endpoint: ApiEndpoint<unknown, unknown, AllowedSecurities>
  public statusCode: number | undefined
  public originalError: Error | undefined

  constructor({
    body,
    endpoint,
    statusCode,
    originalError,
  }: {
    body: string
    endpoint: ApiEndpoint<unknown, unknown, AllowedSecurities>
    statusCode?: number
    originalError?: Error
  }) {
    this.body = body
    this.endpoint = endpoint
    this.statusCode = statusCode
    this.originalError = originalError
  }
}

export class ApiClient {
  private baseUrl: string | undefined
  private userDetails: Promise<ApiResponseFromKeyT<"getOrAddUser">> | undefined
  private errorCallbacks: ((response: ClientApiError) => void)[]

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl
    this.errorCallbacks = []
  }

  private buildFullUrl(endpointPath: string) {
    return new URL(
      `/api/v1${endpointPath}`,
      this.baseUrl ?? window.location.href
    )
  }

  private async callInner<AT, RT, MS extends AllowedSecurities>(
    args: AT,
    endpoint: ApiEndpoint<AT, RT, MS>
  ): Promise<RT> {
    if (endpoint.minimumSecurity !== "open") {
      // wait to retrieve an auth token before making authenticated call
      if (!this.userDetails) {
        await this.call("getOrAddUser", null)
      }
      await this.userDetails
    }

    const { method, endpointPath, responseSchema } = endpoint
    const stringifiedArgs: string | undefined = JSON.stringify(args)
    let body: string | null = null
    const searchParams = new URLSearchParams()
    switch (method) {
      case HttpMethod.POST:
        body = stringifiedArgs
        break
      case HttpMethod.GET:
        searchParams.set("args", stringifiedArgs)
        break
      default:
        assertNever(method)
    }

    const url = this.buildFullUrl(endpointPath)
    url.search = searchParams.toString()

    let response: Response
    try {
      response = await fetch(url.toString(), {
        method,
        body,
        credentials: "include",
      })
    } catch (err: unknown) {
      logException(err)
      const apiError = new ClientApiError({
        body: "",
        statusCode: undefined,
        endpoint,
        originalError: err as Error,
      })
      throw apiError
    }

    if (response.status < 200 || response.status > 299) {
      const apiError = new ClientApiError({
        body: await response.text(),
        statusCode: response.status,
        endpoint,
      })
      for (const errorCallback of this.errorCallbacks) {
        errorCallback(apiError)
      }
      throw apiError
    }

    const parsed = (await response.json()) as unknown
    const validated = responseSchema.parse(parsed)
    return validated
  }

  call = <
    Endpoint extends keyof API_ROUTES_T,
    ET extends API_ROUTES_T[Endpoint],
    AT extends ET extends ApiEndpoint<infer T, unknown, AllowedSecurities>
      ? T
      : never,
    RT extends ET extends ApiEndpoint<AT, infer T, AllowedSecurities>
      ? T
      : never,
    MS extends ET extends ApiEndpoint<unknown, unknown, infer T> ? T : never
  >(
    endpointName: Endpoint,
    args: AT
  ): Promise<RT> => {
    const endpoint = API_ROUTES[endpointName]
    if (endpointName === "getOrAddUser" && this.userDetails) {
      return this.userDetails as Promise<RT>
    }
    const responsePromise = this.callInner(
      args,
      endpoint as ApiEndpoint<AT, RT, MS>
    )
    if (endpointName === "getOrAddUser") {
      this.userDetails = responsePromise as Promise<
        ApiResponseFromKeyT<"getOrAddUser">
      >
    }
    return responsePromise
  }

  public registerErrorCallback(cb: (apiError: ClientApiError) => void) {
    this.errorCallbacks.push(cb)
  }
}
