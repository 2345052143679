<script lang="ts">
  import {
    faSort,
    faSortDown,
    faSortUp,
  } from "@fortawesome/free-solid-svg-icons"

  import Icon from "../Icon.svelte"

  import { SortDirection, type ColAction } from "./types.ts"
  import Dropdown from "../Dropdown.svelte"
  import DropdownItem from "../DropdownItem.svelte"
  import {
    columnHeaderDataFactory,
    setSortDirection,
    setSortingOnColId,
  } from "@/stores/sheetStore"
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"

  export let columnId: string
  export let sortDirection: SortDirection | undefined = undefined
  export let hideSortControls: boolean = false
  export let transpose: boolean = false
  export let colActions: ColAction[]
  export let sheetId: string

  $: colDataSelector = columnHeaderDataFactory({ sheetId, columnId })
  $: sortDirection = $colDataSelector?.sortDirection

  const sort = (sortDirection: SortDirection) => {
    setSortDirection({ sheetId, sortDirection })
    setSortingOnColId({ sheetId, sortingOnColId: columnId })
  }

  const sortDescending = () => sort(SortDirection.desc)
  const sortAscending = () => sort(SortDirection.asc)

  const nextSort = () => {
    if (sortDirection === SortDirection.asc) {
      sortDescending()
    } else {
      sortAscending()
    }
  }

  const handler = (colAction: ColAction) => () =>
    colAction.handler({ colId: columnId })

  $: noDropdownOptions =
    colActions.filter((colAction) => !colAction.disabled).length > 0 &&
    hideSortControls
</script>

{#key columnId}
  {#if $colDataSelector}
    <th class:transpose>
      <SpacingWrapper sides={["top", "bottom"]}>
        {#if !hideSortControls}
          <button class="sort-button" on:click={nextSort}>
            {#if !sortDirection}
              <span class="sort-icon unsorted"><Icon icon={faSort} /></span>
            {:else if sortDirection === SortDirection.asc}
              <Icon icon={faSortUp} />
            {:else}
              <Icon icon={faSortDown} />
            {/if}
          </button>
        {/if}
        <Dropdown noOptions={noDropdownOptions} label={$colDataSelector.title}>
          {#each colActions as action}
            <DropdownItem
              icon={action.icon}
              on:click={handler(action)}
              disabled={action.disabled}
              variant={action.variant ?? "default"}>{action.label}</DropdownItem
            >
          {/each}
          {#if !hideSortControls}
            {#if !sortDirection}
              <DropdownItem icon={faSortUp} on:click={sortAscending}
                >Sort Ascending</DropdownItem
              >
              <DropdownItem icon={faSortDown} on:click={sortDescending}
                >Sort Descending</DropdownItem
              >
            {:else if sortDirection === SortDirection.asc}
              <DropdownItem icon={faSortDown} on:click={sortDescending}
                >Sort Descending</DropdownItem
              >
            {:else}
              <DropdownItem icon={faSortUp} on:click={sortAscending}
                >Sort Ascending</DropdownItem
              >
            {/if}
          {/if}
        </Dropdown>
      </SpacingWrapper>
    </th>
  {/if}
{/key}

<style>
  th {
    position: relative;
    user-select: none;
    box-sizing: border-box;
    white-space: pre;
    text-align: left;
  }
  th:not(.transpose) {
    background-color: var(--secondary-bg);
  }
  .unsorted {
    opacity: 0.75;
  }
  .sort-button {
    all: unset;
    cursor: pointer;
  }
  .sort-button:focus {
    outline: 2px solid var(--action-alt);
  }
  .inner-wrapper {
    margin: 5px;
  }
</style>
