<script lang="ts">
  import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
  import Button from "./Button.svelte"
  import { darkMode, setDarkMode } from "@/stores/styleStore"

  $: icon = $darkMode ? faSun : faMoon
</script>

<Button
  variant="naked"
  iconLeft={icon}
  on:click={() => setDarkMode(!$darkMode)}
/>

<style>
</style>
