<script lang="ts">
  import DisplayJson from "./DisplayJson.svelte"

  import type { DataFormatEvent, DataFormatOverrides } from "./types"
  import DisplayNumericArray from "./DisplayNumericArray.svelte"
  import DisplayTextArray from "./DisplayTextArray.svelte"
  import DisplayBoolean from "./DisplayBoolean.svelte"
  import DisplayText from "./DisplayText.svelte"
  import type { SvelteComponent } from "svelte"
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import DisplayNumeric from "./DisplayNumeric.svelte"

  type T = $$Generic<EnumT.DataType>
  export let dataType: T
  export let value: GenericDataValue<T> | undefined
  export let formatSpec: FormatSpec
  export let textOnly: boolean = false
  export let dataFormatOverrides: DataFormatOverrides = {}

  type RendererProps<DT extends EnumT.DataType> = {
    formatSpec: FormatSpec
    value: GenericDataValue<DT> | undefined
    textOnly: boolean
    dataFormatOverrides: DataFormatOverrides
  }
  type RendererComponent<DT extends EnumT.DataType> = typeof SvelteComponent<
    RendererProps<DT>
  >
  type RendererComponentT<DT extends EnumT.DataType> = SvelteComponent<
    RendererProps<DT>
  >

  let innerComponent: RendererComponentT<EnumT.DataType> | undefined = undefined
  export const emitEvent = (args: DataFormatEvent) =>
    innerComponent?.emitEvent?.(args)

  type RenderInfo<DT extends EnumT.DataType = EnumT.DataType> = {
    renderer: RendererComponent<DT>
    value: GenericDataValue<DT> | undefined
  }

  $: renderInfo = ((): RenderInfo => {
    switch (dataType as EnumT.DataType) {
      case EnumT.DataType.number:
        return {
          renderer: DisplayNumeric as RendererComponent<EnumT.DataType.number>,
          value: value as GenericDataValue<EnumT.DataType.number> | undefined,
        }
      case EnumT.DataType.text:
        return {
          renderer: DisplayText as RendererComponent<EnumT.DataType.text>,
          value: value as GenericDataValue<EnumT.DataType.text> | undefined,
        }
      case EnumT.DataType.boolean:
        return {
          renderer: DisplayBoolean as RendererComponent<EnumT.DataType.boolean>,
          value: value as GenericDataValue<EnumT.DataType.boolean>,
        }
      case EnumT.DataType.textArray:
        return {
          renderer: DisplayTextArray as RendererComponent<EnumT.DataType.text>,
          value: value as GenericDataValue<EnumT.DataType.text>,
        }
      case EnumT.DataType.numberArray:
        return {
          renderer:
            DisplayNumericArray as RendererComponent<EnumT.DataType.numberArray>,
          value: value as GenericDataValue<EnumT.DataType.numberArray>,
        }
      case EnumT.DataType.json:
        return {
          renderer: DisplayJson as RendererComponent<EnumT.DataType.json>,
          value: value as GenericDataValue<EnumT.DataType.json>,
        }
    }
  })()
</script>

<svelte:component
  this={renderInfo.renderer}
  bind:this={innerComponent}
  value={renderInfo.value}
  {formatSpec}
  {textOnly}
  {dataFormatOverrides}
/>
