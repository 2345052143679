<script lang="ts">
  import { DateTime } from "luxon"
  import Button from "./Button.svelte"
  import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher } from "svelte"

  export let value: DateTime | null | undefined = undefined
  export let includeTime: boolean = false
  export let nofocusClear: boolean = false
  export let autofocus: boolean = false
  export let fullwidth: boolean = false
  export let clearable: boolean = false
  export let disabled: boolean

  const dispatch = createEventDispatcher<{ input: DateTime | undefined }>()

  $: inputValue = includeTime
    ? value?.toFormat("yyyy-LL-dd'T'HH:mm")
    : value?.toISODate()

  const onInput = (e: Event) => {
    const val = (e.target as HTMLInputElement | undefined)?.value
    if (val) {
      value = DateTime.fromISO(val)
    } else {
      value = undefined
    }
    dispatch("input", value)
  }

  const clear = () => {
    value = undefined
    dispatch("input", undefined)
  }
</script>

<div class="wrapper" class:fullwidth>
  <input
    {autofocus}
    {disabled}
    on:keydown
    on:blur
    type={includeTime ? "datetime-local" : "date"}
    value={inputValue}
    on:input={onInput}
    on:click
    class:fullwidth
  />
  {#if clearable}
    <Button
      on:click={clear}
      --background-color="rgba(0,0,0,0)"
      iconRight={faTimesCircle}
      variant="none"
      nofill
      nofocus={nofocusClear}
      {disabled}
    />
  {/if}
</div>

<style>
  .fullwidth {
    width: 100%;
  }
  .wrapper {
    display: flex;
  }
</style>
