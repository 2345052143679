<script lang="ts">
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { stopPropagation, formatPhoneNumber } from "@/util"
  import SourceCard from "@/lib/SourceCard.svelte"
  import CondWrapper from "@/lib/CondWrapper.svelte"
  import { navigateTo } from "@/lib/RouterView"
  import { sourceInfoMap } from "@shared/data/defaults"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.text> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export const emitEvent = () => {
    if (formatSpec.type === EnumT.FormatSpecType.source && sourceUrl) {
      navigateTo(sourceUrl)
    }
  }

  $: displayedValue = ((): string => {
    if (!value) {
      return ""
    }
    switch (formatSpec.type) {
      case EnumT.FormatSpecType.source:
        return sourceInfoMap[value as EnumT.Source].name
      case EnumT.FormatSpecType.none:
      case EnumT.FormatSpecType.text:
      case EnumT.FormatSpecType.longText:
        return value
      case EnumT.FormatSpecType.phone:
        return formatPhoneNumber(value)
      default:
        return `ERR: unsupported format type: ${formatSpec.type}`
    }
  })()

  $: sourceUrl = dataFormatOverrides[DataFormatOverrideType.sourceUrl]

  $: asSource = (): EnumT.Source => value as EnumT.Source
</script>

{#if value}
  {#if textOnly}
    <div class="text-wrapper">
      {displayedValue}
    </div>
  {:else if formatSpec.type === EnumT.FormatSpecType.source}
    <CondWrapper
      tag="a"
      on:click={stopPropagation()}
      tabindex="-1"
      href={sourceUrl}
      target="_blank"
      rel="noopener noreferrer"
      wrap={!!sourceUrl}
    >
      <SourceCard
        islink={!!sourceUrl}
        nofocus={!!dataFormatOverrides[DataFormatOverrideType.nofocus] ||
          !!sourceUrl}
        readonly={!sourceUrl}
        source={asSource()}
      />
    </CondWrapper>
  {:else}
    <div class="text-wrapper">
      {displayedValue}
    </div>
  {/if}
{/if}

<style>
  .text-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
