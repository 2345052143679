export const arithMod = (a: number, b: number) => ((a % b) + b) % b

export const gcd = (a: number, b: number) => {
  let r: number
  while (0 !== b) {
    r = a % b
    a = b
    b = r
  }
  return a
}
