import { nearbyPlacesSliderFS } from "../data/json/nearby_places.ts"
import type { FormatSpecData } from "../data/types.ts"
import { CurrencyCode } from "../schema/currencies.ts"
import { EnumT } from "../schema/index.ts"
import type { FunctionDefinition, GenericDataValue, Nully } from "../types.ts"

export type HostelworldRoomTypePreference =
  | "dorm1to4"
  | "dorm5to8"
  | "dorm9+"
  | "private"

const roomTypePreferences: {
  label: string
  value: HostelworldRoomTypePreference
}[] = [
  { label: "Dorm: 1-4 beds", value: "dorm1to4" },
  { label: "Dorm: 5-8 beds", value: "dorm5to8" },
  { label: "Dorm: 9+ beds", value: "dorm9+" },
  { label: "Private room", value: "private" },
]

export type HostelworldDormGenderPreference =
  | "maleOnly"
  | "femaleOnly"
  | "mixed"

const dormGenderPreferences: {
  label: string
  value: HostelworldDormGenderPreference
}[] = [
  { label: "Mixed Dorm", value: "mixed" },
  { label: "Female Dorm", value: "femaleOnly" },
  { label: "Male Dorm", value: "maleOnly" },
]

export const transitModeOptions: { label: string; value: string }[] = [
  { label: "Walking", value: "walking" },
  { label: "Public Transit", value: "transit" },
  { label: "Bicycling", value: "bicycling" },
  { label: "Driving", value: "driving" },
]

const sum: FunctionDefinition<
  EnumT.FormatSpecType.none,
  EnumT.DataType.number
> = {
  name: "Sum",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: { type: EnumT.FormatSpecType.none },
  handler: (...args: Nully<number>[]) =>
    args.reduce<number>((accum, cur) => (cur ?? 0) + accum, 0),
}

const product: FunctionDefinition<
  EnumT.FormatSpecType.none,
  EnumT.DataType.number
> = {
  name: "Product",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: { type: EnumT.FormatSpecType.none },
  handler: (...args: Nully<number>[]) =>
    args.reduce<number>((accum, cur) => (cur ?? 0) * accum, 0),
}

const airbnbPrice: FunctionDefinition<
  EnumT.FormatSpecType.currency,
  EnumT.DataType.number
> = {
  name: "AirBnB Total Price",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Airbnb Room ID",
      fieldHint: EnumT.Field.externalId,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Source",
      fieldHint: EnumT.Field.source,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check In",
      variableName: "Check In",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check Out",
      variableName: "Check Out",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Number of Guests",
      variableName: "Number of Guests",
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: {
    type: EnumT.FormatSpecType.currency,
    currencyCode: CurrencyCode.USD,
  },
}

const hostelworldPrice: FunctionDefinition<
  EnumT.FormatSpecType.currency,
  EnumT.DataType.number
> = {
  name: "Hostelworld Total Price",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Hostelworld Property ID",
      fieldHint: EnumT.Field.externalId,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Source",
      fieldHint: EnumT.Field.source,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check In",
      variableName: "Check In",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check Out",
      variableName: "Check Out",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Number of Guests",
      variableName: "Number of Guests",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      label: "Room Type Preference",
      fixedList: roomTypePreferences,
    },
    {
      nullable: true,
      dataType: EnumT.DataType.text,
      label: "Dorm Gender Preference",
      fixedList: dormGenderPreferences,
    },
    {
      nullable: true,
      dataType: EnumT.DataType.boolean,
      label: "Free cancellation only",
      variableName: "Free cancellation only",
      formatSpec: { type: EnumT.FormatSpecType.checkbox },
    },
    {
      nullable: true,
      dataType: EnumT.DataType.boolean,
      label: "Breakfast included (if available)",
      variableName: "Breakfast included (if available)",
      formatSpec: { type: EnumT.FormatSpecType.checkbox },
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: {
    type: EnumT.FormatSpecType.currency,
    currencyCode: CurrencyCode.USD,
  },
}

export const equals: FunctionDefinition<
  EnumT.FormatSpecType.checkbox,
  EnumT.DataType.boolean
> = {
  name: "Equals",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.boolean,
  formatSpec: {
    type: EnumT.FormatSpecType.checkbox,
  },
  handler: (...args: (GenericDataValue | undefined)[]) => {
    if (args.length === 0) {
      return true
    }
    const arg1 = args[0]
    return args.every((arg) => arg === arg1)
  },
}

export const commuteDetailsByQuery: FunctionDefinition<
  EnumT.FormatSpecType.commuteDetails,
  EnumT.DataType.json
> = {
  name: "Commute Details",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.json,
      formatSpec: { type: EnumT.FormatSpecType.locationDetails },
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      hidden: false,
      variableName: "Search query",
      formatSpec: { type: EnumT.FormatSpecType.none },
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      label: "Travel Mode",
      fixedList: [
        { label: "Walking", value: "walking" },
        { label: "Transit", value: "transit" },
        { label: "Bicycling", value: "bicycling" },
        { label: "Driving", value: "driving" },
      ],
    },
  ],
  dataType: EnumT.DataType.json,
  formatSpec: { type: EnumT.FormatSpecType.commuteDetails },
}

export const nearbyPlacesByQuery: FunctionDefinition<
  EnumT.FormatSpecType.nearbyPlaces,
  EnumT.DataType.json
> = {
  name: "Nearby Places",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.json,
      formatSpec: { type: EnumT.FormatSpecType.locationDetails },
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      hidden: false,
      variableName: "Search query",
      formatSpec: { type: EnumT.FormatSpecType.none },
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      label: "Search radius",
      formatSpec: nearbyPlacesSliderFS,
    },
  ],
  dataType: EnumT.DataType.json,
  formatSpec: { type: EnumT.FormatSpecType.nearbyPlaces } as const,
}

export const builtins = {
  sum,
  product,
  airbnbPrice,
  hostelworldPrice,
  equals,
  commuteDetailsByQuery,
  nearbyPlacesByQuery,
} as const

export type FunctionResultT<F extends keyof typeof builtins> =
  FormatSpecData[(typeof builtins)[F]["formatSpec"]["type"]]
