import { EnumT } from "@shared/schema/index.ts"
import type { FormatSpec } from "@shared/types.ts"

export enum UserDataType {
  number = "number",
  datetime = "datetime",
  text = "text",
  checkbox = "textbox",
  location = "location",
  duration = "duration",
}

export const userDataTypeDataMap: Record<UserDataType, EnumT.DataType> = {
  [UserDataType.text]: EnumT.DataType.text,
  [UserDataType.checkbox]: EnumT.DataType.boolean,
  [UserDataType.datetime]: EnumT.DataType.number,
  [UserDataType.number]: EnumT.DataType.number,
  [UserDataType.location]: EnumT.DataType.numberArray,
  [UserDataType.duration]: EnumT.DataType.number,
}

export const userDataTypeFormatMap: Record<
  UserDataType,
  { label: string; value: EnumT.FormatSpecType }[]
> = {
  [UserDataType.text]: [],
  [UserDataType.checkbox]: [],
  [UserDataType.number]: [
    { label: "Currency", value: EnumT.FormatSpecType.currency },
  ],
  [UserDataType.datetime]: [],
  [UserDataType.location]: [],
  [UserDataType.duration]: [],
}

export const defaultFormatSpec = (
  userDataType: UserDataType | undefined
): FormatSpec => {
  switch (userDataType) {
    case UserDataType.location:
      return { type: EnumT.FormatSpecType.locationDetails }
    case UserDataType.checkbox:
      return { type: EnumT.FormatSpecType.checkbox }
    case UserDataType.datetime:
      return { type: EnumT.FormatSpecType.datetime, includeTime: false }
    case UserDataType.duration:
      return {
        type: EnumT.FormatSpecType.duration,
        minimumUnits: EnumT.DurationUnits.minutes,
      }
    default:
      return { type: EnumT.FormatSpecType.none }
  }
}

export const columnOptions = [
  { label: "Text", value: UserDataType.text },
  { label: "Number", value: UserDataType.number },
  { label: "Checkbox", value: UserDataType.checkbox },
  { label: "Datetime", value: UserDataType.datetime },
  { label: "Location", value: UserDataType.location },
  { label: "Duration", value: UserDataType.duration },
]
