<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import { hasWriteAccess } from "@/stores/sheetStore"
  import type { DataViewContext } from "./types"

  export let sheetId: string
  export let context: DataViewContext

  $: hasWrite = hasWriteAccess({ sheetId })
</script>

<span class="no-data-label">Nothing here yet</span><span
  class="add-something-btn"
  ><Button
    variant="action"
    size="inherit"
    disabled={!$hasWrite}
    on:click={context.openNewItemSidepanel}>Add Something</Button
  ></span
>

<style>
  .add-something-btn {
    margin-top: 20px;
    font-size: 130%;
  }
  .no-data-label {
    font-size: 120%;
    font-weight: 400;
  }
</style>
