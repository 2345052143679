<script lang="ts">
  import MainNav from "@/lib/MainNav.svelte"
  import SpacingWrapper from "@/lib/layout/SpacingWrapper.svelte"
</script>

<main>
  <MainNav />
  <div class="container">
    <SpacingWrapper sides={["top", "bottom"]}>
      <h1>Privacy Policy</h1>
    </SpacingWrapper>
    <iframe src="/privacy.html" class="iframe" title="Privacy Policy" />
  </div>
</main>

<style>
  .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    max-width: 800px;
  }
  .iframe {
    width: 100%;
    height: calc(100vh - 200px);
  }
</style>
