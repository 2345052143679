import { type LiteralParsedItemType, ParsedItemType } from "../lispable/parsing"
import { EnumT } from "../schema"
import type { PartialRecord } from "../types"

export const DATA_TYPE_TO_PARSED_ITEM_TYPE: PartialRecord<
  EnumT.DataType,
  LiteralParsedItemType
> = {
  [EnumT.DataType.text]: ParsedItemType.strLiteral,
  [EnumT.DataType.number]: ParsedItemType.numLiteral,
  [EnumT.DataType.boolean]: ParsedItemType.boolLiteral,
}
