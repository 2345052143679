import type {
  GenericDataValue,
  JsonDataType,
  LocationDetails,
} from "../../types"

export type LocationDetailsData = JsonDataType<LocationDetails>

export function assertLocationDetails(
  v: GenericDataValue | undefined
): asserts v is LocationDetailsData {
  if ((v as LocationDetailsData).data.latitude == null) {
    throw `invalid location data ${v?.toString()}`
  }
}
