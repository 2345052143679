<script lang="ts">
  import type { ProcessedSheetContent, SpecDefinition } from "@shared/types"
  import EditFilter from "./EditFilter.svelte"
  import Button from "./Button.svelte"
  import { faTrash } from "@fortawesome/free-solid-svg-icons"
  import SpacingWrapper from "./layout/SpacingWrapper.svelte"

  export let filters: SpecDefinition[]
  export let sheetContent: ProcessedSheetContent
  export let onChange: (
    newFilters: SpecDefinition[],
    immediate?: boolean
  ) => void
  export let defaultFilteringColumnId: string | undefined = undefined
  export let disabled: boolean = false

  const updateFilter = (index: number) => (spec: string) => {
    const newFilters = [...filters]
    newFilters[index] = { spec }
    onChange(newFilters)
  }

  const deleteFilter = (index: number) => () => {
    filters.splice(index, 1)
    onChange(filters, true)
  }

  const addFilter = (spec: string) => {
    filters = [...filters, { spec }]
    onChange(filters)
  }
</script>

{#each filters as filter, i}
  <SpacingWrapper>
    <EditFilter
      value={filter.spec}
      onChange={updateFilter(i)}
      {sheetContent}
      {defaultFilteringColumnId}
      {disabled}
      ><Button
        slot="clearButton"
        on:click={deleteFilter(i)}
        iconLeft={faTrash}
        variant="danger"
      /></EditFilter
    >
  </SpacingWrapper>
{/each}
<SpacingWrapper>
  <EditFilter
    value={""}
    onChange={addFilter}
    clearAfterChange
    {sheetContent}
    {defaultFilteringColumnId}
    {disabled}
  />
</SpacingWrapper>
