<script lang="ts">
  import DataTable from "@/lib/DataTable"
  import type { RowAction, ColAction } from "@/lib/DataTable/types"
  import FloatingActionButton from "@/lib/FloatingActionButton.svelte"
  import { confirmDelete } from "@/lib/modals/confirmDelete"
  import { navigateTo } from "@/lib/RouterView"
  import { bottomRightToastManager } from "@/lib/ToastManager"
  import { itemPageRoute } from "@/routes"
  import {
    activeSheetId,
    hasWriteAccess,
    filteredSheetContentFactory,
  } from "@/stores/sheetStore"
  import { transpose } from "@/stores/transpose"
  import { buildCellDataProvider } from "@/util/table"
  import {
    faCircleExclamation,
    faCircleInfo,
    faEdit,
    faEye,
    faEyeSlash,
    faFilter,
    faPlus,
    faSync,
    faTrash,
  } from "@fortawesome/free-solid-svg-icons"
  import { assertDefined, coalesceEmptyArray } from "@shared/util/index.ts"
  import type { DataViewContext } from "./types.ts"
  import DataCellWrapper from "@/lib/DataCellWrapper.svelte"
  import { ACTIONS_AXIS_ID } from "@/lib/DataTable/constants.ts"
  import FixedLayoutVertical from "@/lib/layout/FixedLayoutVertical.svelte"
  import FixedRelativeAnchor from "@/lib/layout/FixedRelativeAnchor.svelte"
  import { getSocket } from "@/client/socket.ts"
  import TableAddMoreNotice from "./TableAddMoreNotice.svelte"

  $: sheetContent = $activeSheetId
    ? $filteredSheetContentFactory($activeSheetId)
    : null
  $: hasWrite = hasWriteAccess({ sheetId: $activeSheetId ?? undefined })

  export let context: DataViewContext
  export let query: URLSearchParams | null

  let flashRow: ((itemId: string) => void) | undefined

  $: {
    const itemId = query?.get("itemId")
    if (itemId && flashRow) {
      flashRow(itemId)
    }
  }

  $: rows = [
    ACTIONS_AXIS_ID,
    ...coalesceEmptyArray(sheetContent?.rows.map(({ id }) => id)),
  ]
  $: columns = [
    ACTIONS_AXIS_ID,
    ...coalesceEmptyArray(
      sheetContent?.columns.filter(({ hidden }) => !hidden).map(({ id }) => id)
    ),
  ]

  const deleteRow = async ({ rowId }: { rowId: string }) => {
    if (!$activeSheetId) {
      bottomRightToastManager.pushToast({
        message: "Error deleting row",
        icon: faCircleExclamation,
        variant: "danger",
      })
      return
    }
    ;(await getSocket()).emit("deleteRow", {
      sheetId: $activeSheetId,
      rowId,
    })
    bottomRightToastManager.pushToast({
      message: "Deleted row",
      icon: faCircleInfo,
    })
  }

  $: buildRowActionsForRow = (rowId: string): RowAction[] => {
    return [
      {
        icon: faEye,
        label: "View all Details",
        handler: () => {
          navigateTo(
            itemPageRoute({
              itemId: rowId,
              sheetId: assertDefined($activeSheetId),
            })
          )
        },
      },
      {
        icon: faSync,
        label: "Refetch Data",
        disabled: !$hasWrite,
        handler: async () => {
          if (!$activeSheetId) {
            bottomRightToastManager.pushToast({
              message: "Error refreshing row",
              icon: faCircleExclamation,
              variant: "danger",
            })
            return
          }
          ;(await getSocket()).emit("refreshRow", {
            sheetId: $activeSheetId,
            rowId,
          })
          bottomRightToastManager.pushToast({
            icon: faSync,
            message: "Refreshing row data",
          })
        },
      },
      {
        icon: faTrash,
        label: "Delete Listing",
        disabled: !$hasWrite,
        variant: "danger",
        handler: async () => {
          // delete row
          try {
            const deleted = await confirmDelete({
              title: "Delete Listing",
              description: "Are you sure you want to delete this row?",
            })
            if (deleted) {
              await deleteRow({ rowId })
            }
          } catch (err) {
            console.error(err)
          }
        },
      },
    ]
  }

  $: buildColActionsForCol = (columnId: string): ColAction[] => {
    const sheetId = $activeSheetId
    if (!sheetId) {
      return []
    }
    return [
      {
        icon: faEdit,
        label: "Edit Column",
        disabled: !$hasWrite,
        handler: async () => {
          context.editColumnSidepanel.open({
            props: {
              sheetId,
              columnId,
              accept: async (args) => {
                // update column
                const { generatorSpec, formatSpec, name } = args
                ;(await getSocket()).emit("editColumn", {
                  sheetId,
                  generatorSpec,
                  formatSpec,
                  name,
                  columnId,
                })
                context.editColumnSidepanel.close()
              },
              openAddVariableModal: context.openNewItemSidepanel,
            },
          })
        },
      },
      {
        icon: faEyeSlash,
        label: "Hide Column",
        disabled: !$hasWrite,
        handler: async () => {
          ;(await getSocket()).emit("setColumnVisible", {
            sheetId,
            columnId,
            visible: false,
          })
        },
      },
      {
        icon: faFilter,
        label: "Filter...",
        handler: () => {
          context.viewEditorSidepanel.open({
            props: { sheetId, filterOnColumnId: columnId },
          })
        },
      },
    ]
  }
</script>

{#if $activeSheetId}
  <FixedRelativeAnchor>
    <FixedLayoutVertical>
      <div class="content-inner">
        <DataTable
          stickyHeader
          className="data-table"
          {columns}
          {rows}
          readonly={!$hasWrite}
          fullwidth
          transpose={$transpose}
          bind:flashRow
          cellDataProvider={buildCellDataProvider({
            sheetId: $activeSheetId,
            buildRowActionsForRow,
            buildColActionsForCol,
          })}
          cellRenderer={DataCellWrapper}
        />
        <TableAddMoreNotice {context} sheetId={$activeSheetId} />
      </div>
      <FloatingActionButton
        disabled={!$hasWrite}
        on:click={context.openNewItemSidepanel}
        icon={faPlus}
        label="New Item"
      />
    </FixedLayoutVertical>
  </FixedRelativeAnchor>
{/if}

<style>
  :global(.data-table) {
    color: var(--secondary-fg);
  }
  :global(.datatable-header-row) {
    background: var(--secondary-bg);
  }
  .content-inner {
    padding-bottom: 90px;
  }
</style>
