<script lang="ts">
  import type {
    FormatSpec,
    GenericDataValue,
    LocationDetails,
    MarkdownDetails,
  } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { EnumT } from "@shared/schema"
  import {
    faEdit,
    faLocationCrosshairs,
  } from "@fortawesome/free-solid-svg-icons"
  import Button from "@/lib/Button.svelte"
  import { globalEditLocationModal } from "@/lib/modals"
  import { Location } from "@shared/util/location"
  import { navigateTo } from "@/lib/RouterView"
  import { stopPropagation } from "@/util"
  import {
    assertLocationDetails,
    type LocationDetailsData,
  } from "@shared/data/json/location"
  import DisplayJson from "./DisplayJson.svelte"
  import { globalEditMarkdownSidepanel } from "@/lib/sidepanels"
  import { writable } from "svelte/store"

  export let autofocus: boolean = false
  export let value: GenericDataValue<EnumT.DataType.json> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export const emitEvent = () => {
    if (formatSpec.type === EnumT.FormatSpecType.locationDetails) {
      viewLocation()
    }
  }

  $: nofocus = dataFormatOverrides[DataFormatOverrideType.nofocus]
  $: viewLocationLink =
    dataFormatOverrides[DataFormatOverrideType.viewLocationLink]

  const acceptEditLocation = (newValue: Location) => {
    value = {
      data: {
        ...(value?.data as LocationDetails | undefined),
        latitude: newValue.lat,
        longitude: newValue.lon,
      },
    } as LocationDetailsData
    dataFormatOverrides[DataFormatOverrideType.commitChange]?.(value)
  }

  const editLocation = () => {
    const initialValue = new Location(
      value?.data as LocationDetails | undefined
    )
    globalEditLocationModal.open({
      props: { initialValue },
      onAccept: acceptEditLocation,
    })
  }

  const viewLocation = () => {
    if (!viewLocationLink) {
      assertLocationDetails(value)
      globalEditLocationModal.open({
        props: {
          initialValue: new Location(value.data),
          readonly: true,
        },
        onAccept: () => {},
      })
    } else {
      navigateTo(viewLocationLink)
    }
  }
  const onClickEditMarkdown = () => {
    const valueStore = writable(
      (value?.data as MarkdownDetails | undefined)?.markdown
    )
    globalEditMarkdownSidepanel.open({ props: { valueStore } })
    valueStore.subscribe((newVal) => {
      console.log("updated to", newVal)
      value = { data: { markdown: newVal } }
      dataFormatOverrides[DataFormatOverrideType.commitChange]?.(value)
    })
  }
</script>

{#if formatSpec.type === EnumT.FormatSpecType.locationDetails}
  <div class="location-wrapper">
    {#if value != null}
      <Button
        iconLeft={faLocationCrosshairs}
        nofocus
        on:click={viewLocationLink ? viewLocation : editLocation}
        to={viewLocationLink}
        size="small">View on Map</Button
      >
    {/if}
    <Button
      {autofocus}
      on:click={stopPropagation(editLocation)}
      --background-color="rgba(0,0,0,0)"
      iconRight={faEdit}
      variant="none"
      nofill
      {nofocus}
    />
  </div>
{:else if formatSpec.type === EnumT.FormatSpecType.markdown}
  <Button size="small" iconLeft={faEdit} on:click={onClickEditMarkdown}
    >Edit</Button
  >
{:else}
  <!-- TODO: editing of generic json is unsupported, fall back to the display component -->
  <DisplayJson {value} {formatSpec} {dataFormatOverrides} {emitEvent} />
{/if}

<style>
  .location-wrapper {
    white-space: pre;
  }
</style>
