<script lang="ts">
  import { apiClient } from "@/api"
  import Button from "@/lib/Button.svelte"
  import Input from "@/lib/Input.svelte"
  import MainNav from "@/lib/MainNav.svelte"
  import MarkdownEditor from "@/lib/MarkdownEditor.svelte"
  import { bottomRightToastManager } from "@/lib/ToastManager"
  import FixedLayoutHorizontal from "@/lib/layout/FixedLayoutHorizontal.svelte"
  import SpacingWrapper from "@/lib/layout/SpacingWrapper.svelte"

  let message: string = ""
  let subject: string = ""
  let email: string = ""

  const onSubmit = async () => {
    await apiClient.call("privacyRequest", { subject, bodyMd: message, email })
    bottomRightToastManager.pushToast({
      message: "Request submitted",
      variant: "success",
    })
  }

  $: submitDisabled = !message || !subject || !email
</script>

<main>
  <MainNav />
  <div class="container">
    <SpacingWrapper sides={["top", "bottom"]}>
      <h1>Privacy Inquiry</h1>
    </SpacingWrapper>
    Contact Email
    <SpacingWrapper sides={["top"]}>
      <Input type="text" placeholder="Contact email" bind:value={email} />
    </SpacingWrapper>
    Subject
    <SpacingWrapper sides={["top"]}>
      <Input type="text" placeholder="Subject" bind:value={subject} />
    </SpacingWrapper>
    Message
    <MarkdownEditor bind:value={message} />
    <SpacingWrapper sides={["left", "top"]}>
      <FixedLayoutHorizontal>
        <Button
          disabled={submitDisabled}
          on:click={onSubmit}
          slot="right"
          variant="success">Submit</Button
        >
      </FixedLayoutHorizontal>
    </SpacingWrapper>
  </div>
</main>

<style>
  .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    max-width: 500px;
  }
</style>
