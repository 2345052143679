import type { EnumT } from "../schema/index.ts"
import type { EvaluationContext, GenericDataValue } from "../types.ts"
import { newTypedArray } from "../util/index.ts"
import { type ParsedItem, ParsedItemType, scriptParser } from "./parsing.ts"
import { MemCache } from "../memcache/index.ts"
import { ReferenceNamespace } from "../schema/enum.ts"

const GLOBAL_SCRIPT_CACHE = new MemCache<string, ParsedItem>()

export const cachedParseScript = (input: string) =>
  GLOBAL_SCRIPT_CACHE.getWithDefaultSync(input, () => scriptParser.parse(input))

export const tryToParse = (s: string) => {
  try {
    return cachedParseScript(s)
  } catch {
    return undefined
  }
}

const serializeNamespace = (namespace: ReferenceNamespace): string => {
  switch (namespace) {
    case ReferenceNamespace.fields:
      return "fields"
    case ReferenceNamespace.columns:
      return "columns"
    case ReferenceNamespace.globals:
      return "globals"
    case ReferenceNamespace.locals:
      return "locals"
  }
}

export const serialize = (item: ParsedItem): string => {
  switch (item.type) {
    case ParsedItemType.nullLiteral:
      return "null"
    case ParsedItemType.numLiteral:
      return item.value.toString()
    case ParsedItemType.strLiteral:
      return `"${item.value.replace('"', '\\"')}"`
    case ParsedItemType.boolLiteral:
      return item.value.toString()
    case ParsedItemType.list:
      return `(${item.items.map(serialize).join(" ")})`
    case ParsedItemType.jsonLiteral:
      return `json:${JSON.stringify(item.value)}`
    case ParsedItemType.reference:
      return `ref:${serializeNamespace(item.namespace)}.${item.identifier}`
    case ParsedItemType.symbol:
      return item.symbol
    case ParsedItemType.funcCall:
      return serialize({
        type: ParsedItemType.list,
        items: [
          { type: ParsedItemType.symbol, symbol: item.funcName },
          ...item.args,
        ],
      })
  }
}

export const evaluate = (
  parsedItem: ParsedItem,
  context: EvaluationContext
): GenericDataValue | undefined => {
  switch (parsedItem.type) {
    case ParsedItemType.nullLiteral:
      return undefined
    case ParsedItemType.numLiteral:
      return parsedItem.value
    case ParsedItemType.strLiteral:
      return parsedItem.value
    case ParsedItemType.boolLiteral:
      return parsedItem.value
    case ParsedItemType.list: {
      const evaluatedList = newTypedArray<GenericDataValue | undefined>()
      for (const item of parsedItem.items) {
        evaluatedList.push(evaluate(item, context))
      }
      return evaluatedList as
        | GenericDataValue
        | GenericDataValue<EnumT.DataType.numberArray>
        | GenericDataValue<EnumT.DataType.textArray>
    }
    case ParsedItemType.reference:
      return context.lookupRef({
        namespace: parsedItem.namespace,
        identifier: parsedItem.identifier,
      })
    case ParsedItemType.funcCall: {
      const evaluatedArgs = newTypedArray<GenericDataValue | undefined>()
      for (const item of parsedItem.args) {
        evaluatedArgs.push(evaluate(item, context))
      }
      return context.resolveFunction({
        funcName: parsedItem.funcName,
        args: evaluatedArgs,
      })
    }
    case ParsedItemType.symbol:
      throw `unexpected symbol ${parsedItem.symbol}`
  }
}

export const evaluateScript = (
  script: string,
  context: EvaluationContext,
  nocache?: boolean
) => {
  const parsedScript = nocache
    ? scriptParser.parse(script)
    : cachedParseScript(script)
  return evaluate(parsedScript, context)
}
