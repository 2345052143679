<script lang="ts">
  import type { GenericDataValue } from "@shared/types"
  import DataCell from "./DataCell.svelte"
  import { createEventDispatcher } from "svelte"
  import { ACTIONS_AXIS_ID } from "./DataTable/constants"
  import RowActionsCell from "./DataTable/RowActionsCell.svelte"
  import type { ColAction, RowAction } from "./DataTable/types"
  import TableHeader from "./DataTable/TableHeader.svelte"

  export let columnId: string
  export let rowId: string
  export let sheetId: string
  export let onChangeCB: (newValue: GenericDataValue | undefined) => any
  export let editing: boolean
  export let active: boolean
  export let transpose: boolean
  export let hideSortControls: boolean
  export let readonly: boolean = false
  export let rowActions: RowAction[]
  export let colActions: ColAction[]
  export let keyboardActive: boolean

  createEventDispatcher<{
    requestStopEdit: undefined
    requestEdit: undefined | Event
    requestFocus: undefined
  }>()
</script>

{#if columnId === ACTIONS_AXIS_ID && rowId === ACTIONS_AXIS_ID}
  <th class:transpose />
{:else if columnId === ACTIONS_AXIS_ID}
  <RowActionsCell
    {active}
    {editing}
    {rowActions}
    {rowId}
    {keyboardActive}
    on:requestFocus
    on:requestEdit
    on:requestStopEdit
  />
{:else if rowId === ACTIONS_AXIS_ID}
  <TableHeader
    {transpose}
    {sheetId}
    {columnId}
    {colActions}
    {hideSortControls}
  />
{:else}
  <DataCell
    {active}
    {columnId}
    {editing}
    {onChangeCB}
    {readonly}
    {rowId}
    {sheetId}
    {keyboardActive}
    on:requestStopEdit
    on:requestEdit
    on:requestFocus
  />
{/if}

<style>
  th:not(.transpose) {
    background-color: var(--secondary-bg);
  }
</style>
