<script lang="ts">
  import QuickFacts from "@/lib/QuickFacts.svelte"
  import { itemPageRoute } from "@/routes"
  import { EnumT } from "@shared/schema/index.ts"
  import type { PartialFieldValueLookup } from "@shared/types.ts"
  import MapPopup from "./MapPopup.svelte"

  export let rowId: string
  export let sheetId: string
  export let fieldValueLookup: PartialFieldValueLookup

  $: title = fieldValueLookup[EnumT.Field.title]
</script>

{#if title}
  <MapPopup to={itemPageRoute({ itemId: rowId, sheetId })} {title}>
    <QuickFacts {fieldValueLookup} />
  </MapPopup>
{/if}
