import { buildGeneratorSpecGraph } from "../lispable/index.ts"
import { builtins } from "../lispable/functions.ts"
import { evaluate, tryToParse } from "../lispable/script.ts"
import { EnumT } from "../schema/index.ts"
import {
  type CellKey,
  type CellValue,
  type EvaluationContext,
  type FieldDataTypeMap,
  type FunctionResolverWithContext,
  type GenericDataValue,
  type ParsedSheetContent,
  type ParsedSheetRow,
  type PartialRecord,
  type ProcessedSheetContent,
  type SheetContent,
  SortDirection,
  type SortInfo,
  type PartialFieldValueLookup,
  type SpecDefinition,
  type FieldFormatSpecTypeMap,
} from "../types.ts"
import type { FormatSpecData } from "../data/types.ts"
import {
  assertDefined,
  coalesceEmptyArray,
  newTypedArray,
  newTypedObject,
  objectValues,
  topologicalSort,
} from "../util/index.ts"
import { buildLookupForSheetContent } from "./lispableUtils.ts"
import { filterSheetContent } from "./index.ts"
import {
  type ParsedItem,
  ParsedItemType,
  type ParsedList,
  assertParsedItemType,
} from "../lispable/parsing.ts"
export { filterSheetContent } from "./filterSheetContent.ts"

export const parseSheetContent = (
  content: SheetContent
): ParsedSheetContent => ({
  ...content,
  cells: content.cells.map((cell) => ({
    ...cell,
    value: cell.value as GenericDataValue,
  })),
  extractedData: content.extractedData.map((item) => ({
    ...item,
    value: item.value as GenericDataValue,
  })),
  columnDefinitions: content.columnDefinitions.map((colDef) => ({
    ...colDef,
    formatSpec: colDef.formatSpec,
  })),
  sheetGlobals: content.sheetGlobals.map((sheetGlobal) => ({
    ...sheetGlobal,
    value: sheetGlobal.value ? (sheetGlobal.value as GenericDataValue) : null,
  })),
})

const getParsedValueAt = ({
  cellKey,
  sheetContent,
}: {
  cellKey: CellKey
  sheetContent: ParsedSheetContent
}): CellValue | undefined => {
  const { rowId, colId } = cellKey
  let userInputData: GenericDataValue | undefined
  let extractedData: GenericDataValue | undefined
  const col = assertDefined(
    sheetContent.columns.find((sheetColumn) => sheetColumn.id === colId)
  )
  const row = assertDefined(
    sheetContent.rows.find((sheetRow) => sheetRow.id === rowId)
  )
  const columnDef = assertDefined(
    sheetContent.columnDefinitions.find(
      (colDef) => colDef.id === col.columnDefinitionId
    )
  )
  const cell = sheetContent.cells.find(
    ($cell) => $cell.sheetRowId === rowId && $cell.sheetColumnId === col.id
  )
  if (cell) {
    userInputData = cell.value as GenericDataValue
  }
  if (columnDef.field) {
    const field = assertDefined(
      sheetContent.fields.find(($field) => $field.refName === columnDef.field)
    )
    const extractedDataValue = sheetContent.extractedData.find(
      (item) =>
        item.field === field.refName &&
        item.sourceKey === row.sourceKey &&
        item.source === row.source
    )
    if (extractedDataValue) {
      extractedData = extractedDataValue.value as GenericDataValue
    }
  }
  if (userInputData != null || extractedData != null) {
    return {
      userInputData,
      extractedData,
    }
  }
}

export const getParsedValueByColumn = <
  DT extends EnumT.DataType = EnumT.DataType
>({
  sheetContent,
  cellKey,
}: {
  sheetContent: ProcessedSheetContent
  cellKey: CellKey
}) => {
  const cellValue = sheetContent.cellValueLookup[buildCellKey(cellKey)]
  return (cellValue?.userInputData ??
    cellValue?.calculatedData ??
    cellValue?.extractedData) as GenericDataValue<DT> | undefined
}

export const getColumnForField = ({
  sheetContent,
  field,
}: {
  sheetContent: ProcessedSheetContent
  field: EnumT.Field
}) =>
  sheetContent.columns.find((col) =>
    sheetContent.columnDefinitions.find(
      (colDef) => colDef.id === col.columnDefinitionId && colDef.field === field
    )
  )

export const getParsedValueByField = <FT extends EnumT.Field>({
  sheetContent,
  rowId,
  field,
}: {
  sheetContent: ProcessedSheetContent
  rowId: string
  field: FT
}): (GenericDataValue<FieldDataTypeMap[FT]> | undefined) &
  (FormatSpecData[FieldFormatSpecTypeMap[FT]] | undefined) => {
  const col = getColumnForField({ sheetContent, field })
  if (!col) {
    const row = assertDefined(sheetContent.rows.find((r) => r.id === rowId))
    for (const ed of sheetContent.extractedData) {
      if (
        ed.sourceKey === row.sourceKey &&
        ed.source === row.source &&
        ed.field === field
      ) {
        return ed.value as GenericDataValue<FieldDataTypeMap[FT]> &
          (FormatSpecData[FieldFormatSpecTypeMap[FT]] | undefined)
      }
    }
    return undefined
  }
  const cellKey = { rowId, colId: col.id }
  return getParsedValueByColumn<FieldDataTypeMap[FT]>({
    sheetContent,
    cellKey,
  }) as GenericDataValue<FieldDataTypeMap[FT]> &
    (FormatSpecData[FieldFormatSpecTypeMap[FT]] | undefined)
}

const getSortedSheetRows = ({
  rows,
  cellValueLookup,
  sortInfo,
}: {
  rows: ParsedSheetRow[]
  cellValueLookup: PartialRecord<string, CellValue | undefined>
  sortInfo?: SortInfo
}) => {
  if (!sortInfo) {
    return rows
  }
  const { sortingOnColId, sortDirection } = sortInfo
  const sortRows = (rowA: ParsedSheetRow, rowB: ParsedSheetRow) => {
    const dir = sortDirection === SortDirection.desc ? -1 : 1
    if (sortingOnColId) {
      /*
        We will probably need information from the column definition in order to do more advanced sorting for special columns,
        but for now this data type based sorting seems like it will do.
        const col = assertDefined(sheetContent.columns.find((_col) => _col.id === sortingOnColId))
        const colDef = assertDefined(sheetContent.columnDefinitions.find((_colDef) => _colDef.id === col.columnDefinitionId))
        */
      const cellAKey = buildCellKey({
        rowId: rowA.id,
        colId: sortingOnColId,
      })
      const cellBKey = buildCellKey({
        rowId: rowB.id,
        colId: sortingOnColId,
      })
      const cellAValue = cellValueLookup[cellAKey]
      const cellBValue = cellValueLookup[cellBKey]

      const cellDataA =
        cellAValue?.userInputData ??
        cellAValue?.calculatedData ??
        cellAValue?.extractedData
      const cellDataB =
        cellBValue?.userInputData ??
        cellBValue?.calculatedData ??
        cellBValue?.extractedData

      if (!cellDataA) {
        return dir
      }
      if (!cellDataB) {
        return -1 * dir
      }

      const cellDataAT = typeof cellDataA
      const cellDataBT = typeof cellDataB
      if (cellDataAT !== cellDataBT) {
        return -1 * dir
      }

      if (typeof cellDataA === "string") {
        return dir * cellDataA.localeCompare(cellDataB as string)
      }
      if (typeof cellDataA === "number") {
        return dir * (cellDataA - (cellDataB as number))
      }
      if (Array.isArray(cellDataA)) {
        return dir * (cellDataA.length - (cellDataB as unknown[]).length)
      }
      if (typeof cellDataA === "boolean") {
        return dir * (+cellDataA - +(cellDataB as boolean))
      }
    }
    return dir * (rowA.sortedOrder - rowB.sortedOrder)
  }

  return [...rows].sort(sortRows)
}

// Warning: mutates sheetContent.cellValueLookup
export const applyGeneratorSpec = ({
  sheetContent,
  resolveFunctionWithContext,
}: {
  sheetContent: ProcessedSheetContent
  resolveFunctionWithContext: FunctionResolverWithContext
}) => {
  const { nodes, edges } = buildGeneratorSpecGraph({
    columns: sheetContent.columns,
    columnDefinitions: sheetContent.columnDefinitions,
  })

  const sortedNodes = topologicalSort(nodes, edges)

  for (const node of sortedNodes) {
    for (const row of sheetContent.rows) {
      const cellKey: CellKey = { rowId: row.id, colId: node.id }
      const cellKeyStr = buildCellKey(cellKey)
      const context: EvaluationContext = {
        resolveFunction: (funcCall) => {
          const funcToInvoke =
            builtins[funcCall.funcName as keyof typeof builtins]
          if (!funcToInvoke) {
            throw `unrecognized function ${funcCall.funcName}`
          }
          if (funcToInvoke.async) {
            return resolveFunctionWithContext({ funcCall, cellKey })
          }
          return funcToInvoke.handler(...funcCall.args)
        },
        lookupRef: buildLookupForSheetContent({ sheetContent, rowId: row.id }),
      }

      const calculatedData = (evaluate(node.parsedScript, context) ??
        undefined) as GenericDataValue

      sheetContent.cellValueLookup[cellKeyStr] = {
        ...sheetContent.cellValueLookup[cellKeyStr],
        calculatedData,
      }
    }
  }
}

// Warning: mutates sheetContent.rows and sheetContent.filteredRows
export const applySheetFilters = ({
  sheetContent,
  filters,
}: {
  sheetContent: ProcessedSheetContent
  filters?: SpecDefinition[]
}) => {
  for (const filterSpec of coalesceEmptyArray(filters ?? undefined)) {
    const filter = tryToParse(filterSpec.spec)
    if (filter) {
      const { failed } = filterSheetContent({
        spec: filter,
        sheetContent,
      })
      failed.forEach(({ id }) => sheetContent.filteredRowIds.add(id))
    }
  }
}

export const buildCellKey = (ck: CellKey) => `${ck.colId},${ck.rowId}`

export const processSheetContent = ({
  sheetContent,
  sortInfo,
  resolveFunctionWithContext,
  filters,
}: {
  sheetContent: ParsedSheetContent
  sortInfo?: SortInfo
  resolveFunctionWithContext: FunctionResolverWithContext
  filters?: SpecDefinition[]
}): ProcessedSheetContent => {
  const cellValueLookup = newTypedObject<string, CellValue | undefined>()

  const sortedColumns = sheetContent.columns.sort(
    (colA, colB) => colA.sortedOrder - colB.sortedOrder
  )

  for (const col of sortedColumns) {
    for (const row of sheetContent.rows) {
      const cellKey = { colId: col.id, rowId: row.id }
      const cellKeyStr = buildCellKey(cellKey)
      cellValueLookup[cellKeyStr] = getParsedValueAt({ sheetContent, cellKey })
    }
  }

  const processedSheetContent: ProcessedSheetContent = {
    ...sheetContent,
    columns: sortedColumns,
    rows: getSortedSheetRows({
      rows: sheetContent.rows,
      cellValueLookup,
      sortInfo,
    }),
    filteredRowIds: new Set(),
    cellValueLookup,
  }

  applyGeneratorSpec({
    sheetContent: processedSheetContent,
    resolveFunctionWithContext,
  })

  applySheetFilters({ sheetContent: processedSheetContent, filters })

  return processedSheetContent
}

export const getSheetColumn = ({
  sheetContent,
  colId,
}: {
  sheetContent: ProcessedSheetContent
  colId: string
}) => sheetContent.columns.find((col) => col.id === colId)

export const getColumnDefinitionForCol = ({
  sheetContent,
  colId,
}: {
  sheetContent: ProcessedSheetContent
  colId: string
}) => {
  const col = assertDefined(getSheetColumn({ sheetContent, colId }))
  return assertDefined(
    sheetContent.columnDefinitions.find(
      (colDef) => colDef.id === col?.columnDefinitionId
    )
  )
}

export const buildFieldValueLookup = ({
  sheetContent,
  rowId,
}: {
  sheetContent: ProcessedSheetContent
  rowId: string
}): PartialFieldValueLookup => {
  const fieldValueLookup = newTypedObject<EnumT.Field, GenericDataValue>()
  for (const field of objectValues(EnumT.Field)) {
    const value = getParsedValueByField({ sheetContent, rowId, field })
    fieldValueLookup[field] = value
  }
  return fieldValueLookup as PartialFieldValueLookup
}

export const convertToParsedListStrict = (
  val: string[] | number[]
): ParsedList => {
  if (val.length === 0) {
    return { type: ParsedItemType.list, items: [] }
  }
  const arrType = typeof val[0]
  const items = newTypedArray<ParsedItem>()
  for (const valItem of val) {
    if (typeof valItem !== arrType) {
      throw `type mismatch: ${valItem} in ${val.toString()}`
    }
    if (arrType === "number") {
      items.push({ type: ParsedItemType.numLiteral, value: valItem as number })
    } else if (arrType === "string") {
      items.push({ type: ParsedItemType.strLiteral, value: valItem as string })
    } else {
      throw `unsupported type ${arrType}`
    }
  }
  return { type: ParsedItemType.list, items }
}

export const convertToNumLiteralArray = (val: ParsedList) => {
  const nums = newTypedArray<number>()
  for (const valItem of val.items) {
    assertParsedItemType(valItem, ParsedItemType.numLiteral)
    nums.push(valItem.value)
  }
  return nums
}
