import type { ParsedLispableSpec } from "../lispable"
import { evaluate } from "../lispable/script"
import type {
  EvaluationContext,
  ProcessedSheetContent,
  SheetRow,
} from "../types"
import { newTypedArray } from "../util"
import {
  buildLookupForSheetContent,
  syncFunctionResolver,
} from "./lispableUtils"

export const filterSheetContent = ({
  sheetContent,
  spec,
}: {
  sheetContent: ProcessedSheetContent
  spec: ParsedLispableSpec
}): { passed: SheetRow[]; failed: SheetRow[] } => {
  const passedRows = newTypedArray<SheetRow>()
  const failedRows = newTypedArray<SheetRow>()

  for (const row of sheetContent.rows) {
    const context: EvaluationContext = {
      resolveFunction: syncFunctionResolver,
      lookupRef: buildLookupForSheetContent({ sheetContent, rowId: row.id }),
    }
    // TODO: maybe loosen the comparison here
    if (evaluate(spec, context) === true) {
      passedRows.push(row)
    } else {
      failedRows.push(row)
    }
  }

  return { passed: passedRows, failed: failedRows }
}

export const filterProcessedSheetContent = (
  sheetContent: ProcessedSheetContent
): ProcessedSheetContent => ({
  ...sheetContent,
  rows: sheetContent.rows.filter(
    ({ id }) => !sheetContent.filteredRowIds.has(id)
  ),
})
