<script lang="ts">
  import { classNames, keyPressWrapper } from "@/util"

  // IMPROVE: In svelte 5 we can use snippets to make this better
  const groupName = crypto.randomUUID()
  export let selectedIndex: number = 0
  export let options: { label: string }[]

  const onLabelClick = (index: number) => () => {
    selectedIndex = index
  }
</script>

<div class="wrapper">
  {#each options as option, i}
    <label
      class={classNames({ chosen: i === selectedIndex }, "option")}
      tabindex="0"
      on:keypress={keyPressWrapper(onLabelClick(i))}
      on:click={onLabelClick(i)}
    >
      <input
        class="radio hidden-visually"
        tabindex="-1"
        name={groupName}
        type="radio"
        checked={i === selectedIndex}
        on:input={onLabelClick(i)}
        on:click={onLabelClick(i)}
      />
      {option.label}
      <slot />
    </label>
  {/each}
</div>

<style>
  .hidden-visually {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .option {
    flex: 1;
    text-align: center;
    padding: var(--spacing-sm);
    border-radius: var(--default-rounding);
    font-weight: bold;
    cursor: pointer;
  }
  .option.chosen {
    background-color: var(--primary-bg);
  }
  .wrapper {
    background: var(--secondary-bg);
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
    border-radius: var(--default-rounding);
    display: flex;
  }
</style>
