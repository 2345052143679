<script lang="ts">
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"

  import { fade, fly } from "svelte/transition"
  import Portal from "svelte-portal"
  import { onDestroy, onMount } from "svelte"
  import Icon from "../Icon.svelte"
  import { faTimes } from "@fortawesome/free-solid-svg-icons"
  import { useTrapFocus } from "@/util"
  import FixedLayoutVertical from "../layout/FixedLayoutVertical.svelte"
  import FixedLayoutHorizontal from "../layout/FixedLayoutHorizontal.svelte"
  import FilterArgumentControl from "../FilterArgumentControl.svelte"

  type T = $$Generic

  const DEFAULT_CLOSE_WITH = ["button", "outside", "escape"]

  export let title: string
  export let description: string
  export let fullscreen: boolean | undefined = undefined
  export let showX: boolean | undefined = undefined
  export let closeWith: ("button" | "outside" | "escape")[] | undefined =
    undefined
  export let onClose: (() => void) | undefined

  $: closeWithSet = new Set(closeWith ?? DEFAULT_CLOSE_WITH)

  let modalElem: HTMLDivElement | undefined = undefined
  let releaseFocus: (() => void) | undefined = undefined

  const outsideClicked = () => {
    if (closeWithSet.has("outside")) {
      onClose?.()
    }
  }

  const descriptionId = crypto.randomUUID()

  const onEscape = () => {
    if (closeWithSet.has("escape")) {
      onClose?.()
    }
  }

  const onXButton = () => {
    if (closeWithSet.has("button")) {
      onClose?.()
    }
  }

  const trapFocus = () => {
    if (releaseFocus || !modalElem) {
      return
    }
    releaseFocus = useTrapFocus({
      elem: modalElem,
      onEscape,
    }).release
  }

  onMount(trapFocus)

  onDestroy(() => {
    releaseFocus?.()
  })
</script>

<Portal target="#modals">
  <div
    role="presentation"
    tabindex={-1}
    transition:fade={{ duration: 150 }}
    on:click={outsideClicked}
    class="background"
    bind:this={modalElem}
  >
    {#if !fullscreen}
      <div
        transition:fly|global={{ y: 100, duration: 200 }}
        role="dialog"
        aria-modal
        aria-label={title}
        aria-describedby={descriptionId}
        on:click|stopPropagation
      >
        <div class="scroll-fixer">
          <FixedLayoutVertical>
            <header slot="header">
              <FixedLayoutHorizontal verticalCenter>
                <h1 slot="left" class="title">{title}</h1>
                <span slot="right">
                  {#if showX}
                    <button on:click={onXButton} class="x-btn"
                      ><Icon icon={faTimes} /></button
                    >
                  {/if}
                </span>
              </FixedLayoutHorizontal>
              <p class="description">{description}</p>
            </header>
            <SpacingWrapper sides={["top"]} size="xl">
              <slot />
            </SpacingWrapper>
          </FixedLayoutVertical>
        </div>
      </div>
    {:else}
      <slot />
    {/if}
  </div>
</Portal>

<style>
  .description {
    display: none;
  }
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(30, 30, 30, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  div[role="dialog"] {
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--primary-bg);
    outline: 4px solid var(--primary-accent-light);
    padding: 30px;
    border-radius: 10px;
    width: 500px;
    max-height: 80vh;
    display: flex;
    position: relative;
  }

  .x-btn {
    font-size: 35px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: var(--primary-fg);
  }
  @media only screen and (max-width: 769px) {
    div[role="dialog"] {
      width: 100%;
    }
    h1 {
      font-size: 26px;
    }
  }
  .scroll-fixer {
    flex: 1;
  }
</style>
