<script lang="ts">
  import { sheetContentFactory, myActiveViewFactory } from "@/stores/sheetStore"
  import SidepanelContent from "./SidepanelContent.svelte"
  import EditFilters from "../EditFilters.svelte"
  import type { SpecDefinition } from "@shared/types"
  import { getSocket } from "@/client"
  import { debounce } from "@/util"
  import { coalesceEmptyArray } from "@shared/util"
  import InternalUi from "../InternalUI.svelte"

  export let sheetId: string
  export let filterOnColumnId: string | undefined = undefined

  $: sheetContent = $sheetContentFactory(sheetId)
  $: myActiveView = $myActiveViewFactory(sheetId)

  let filters: SpecDefinition[] = []
  let loading = false

  const updateViewFilters = (
    newFilters: SpecDefinition[],
    immediate?: boolean
  ) => {
    if (immediate) {
      updateViewFiltersInner(newFilters)
      return
    }
    debouncedUpdateViewFilters(newFilters)
  }

  const setFilters = (newFilters: SpecDefinition[] | null) => {
    filters = coalesceEmptyArray(newFilters ?? undefined)
  }

  const setLoading = (val: boolean) => (loading = val)

  $: if (myActiveView) {
    setFilters(myActiveView.filters)
    setLoading(false)
  }

  const updateViewFiltersInner = async (filters: SpecDefinition[]) => {
    ;(await getSocket()).emit("setCurrentViewFilters", {
      sheetId,
      filters,
    })
  }

  const debouncedUpdateViewFilters = debounce(updateViewFiltersInner, 500)
</script>

<SidepanelContent>
  {#if sheetContent}
    <EditFilters
      {sheetContent}
      {filters}
      onChange={updateViewFilters}
      defaultFilteringColumnId={filterOnColumnId}
      disabled={loading}
    />
  {/if}
  <InternalUi>
    <textarea value={JSON.stringify(filters)} />
  </InternalUi>
</SidepanelContent>
