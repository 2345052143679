<script lang="ts">
  import { writable, type Readable } from "svelte/store"

  import Icon from "../Icon.svelte"

  import type { OptionT } from "@/types"
  import { createSelect } from "@melt-ui/svelte"
  import SelectableItems from "./SelectableItems.svelte"
  import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

  type T = $$Generic
  export let options: OptionT<T>[]
  export let value: T | undefined
  export let placeholder: string
  export let clearable: boolean = false
  export let hideLabel: boolean = false
  export let fullwidth: boolean = false
  export let disabled: boolean = false
  export let onChange: ((v: T) => void) | undefined = undefined

  const selected = writable<OptionT<T>>()

  $: selectedOption = options.find((opt) => opt.value === value)
  $: if (selectedOption != null) selected.set(selectedOption)
  selected.subscribe((newVal) => {
    if (newVal != null) {
      if (onChange != null) {
        onChange(newVal.value)
      } else {
        value = newVal.value
      }
    }
  })

  const {
    elements: { trigger, menu, option, label },
    states: { open },
    helpers: { isSelected },
  } = createSelect<T>({
    forceVisible: true,
    positioning: {
      placement: "bottom",
      fitViewport: true,
      sameWidth: true,
    },
    selected,
    required: !clearable,
    disabled,
  })
</script>

<div class="wrapper">
  <!-- svelte-ignore a11y-label-has-associated-control - $label contains the 'for' attribute -->
  <label class:hidden={hideLabel} {...$label} use:label>{placeholder}</label>
  <button {...$trigger} use:trigger aria-label="Food">
    {#if $selected}
      {#each options as option}
        {#if $isSelected(option.value)}
          {#if option.component}
            <svelte:component this={option.component} value={option.value} />
          {:else}
            <div class="selected-option-chip">
              {option.label}
            </div>
          {/if}
        {/if}
      {/each}
    {:else}
      {placeholder}
    {/if}
    {#if $open}
      <Icon icon={faChevronUp} />
    {:else}
      <Icon icon={faChevronDown} />
    {/if}
  </button>
  {#if $open}
    <SelectableItems
      isSelected={$isSelected}
      menuElem={menu}
      optionElem={option}
      {options}
    />
  {/if}
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  button {
    border: 2px solid var(--primary-accent);
    border-radius: var(--default-rounding);
    padding: var(--spacing-sm);
    background: var(--secondary-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  button:hover {
    background: var(--primary-accent);
  }
  .hidden {
    /* IMPROVE: should not be display none for accessibility */
    display: none;
  }
  .selected-option-chip {
    padding: var(--spacing-md);
    background-color: var(--action-alt);
    border-radius: var(--default-rounding);
    font-weight: bold;
    font-size: 15px;
  }
</style>
