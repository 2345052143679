import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import type { Variant } from "@/types"
import type { CellKey, CellValue } from "@shared/types"

type ButtonAction<ArgT> = {
  icon?: IconDefinition
  handler: (arg: ArgT) => void | Promise<void>
  label: string
  variant?: Variant
  disabled?: boolean
}

export type RowAction = ButtonAction<{ rowId: string }>
export type ColAction = ButtonAction<{ colId: string }>

export type CellRendererProps<T> = {
  data: T | undefined
  editing: boolean
  focused: boolean
  id: string | undefined
  columnId: string | undefined
  metadata: TableCellMetadata | undefined
}

export type TableCellMetadata = {
  cellValue: CellValue | undefined
  cellKey: CellKey
}

export type TableRowOptions = {
  id: string
  expanded?: boolean
  expandable?: boolean
  rowActions?: RowAction[]
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export type CellDataProviderBase = {
  internal?: { skipWrapWithTd?: boolean }
}

export type CellDataProviderIncluded = {
  rowId: string
  columnId: string
  editing: boolean
  active: boolean
  transpose: boolean
  keyboardActive: boolean
}
