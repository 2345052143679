<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import type { DataFormatOverrides } from "./types"
  import type { EnumT } from "@shared/schema"
  import DisplayNumericArray from "./DisplayNumericArray.svelte"

  export let value: GenericDataValue<EnumT.DataType.numberArray> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
</script>

<!-- TODO: editing of generic numeric arrays is unsupported, fall back to the display component -->
<DisplayNumericArray {value} {formatSpec} {dataFormatOverrides} />
