import {
  alt,
  regex,
  seqMap,
  term,
  ParserError,
  forwardDeclaration,
  lex,
  EOF,
  enumParser,
} from "../parser/index.ts"
import { EnumT } from "../schema/index.ts"
import type {
  PartialRecord,
  GenericDataValue,
  JsonValue,
  JsonObject,
} from "../types.ts"
import { assertNever, newTypedArray, newTypedObject } from "../util/index.ts"

const NEGATION_SENTRY = "-"

export enum ParsedItemType {
  boolLiteral = "boolLiteral",
  funcCall = "funcCall",
  list = "list",
  nullLiteral = "null",
  numLiteral = "numLiteral",
  jsonLiteral = "jsonLiteral",
  reference = "reference",
  strLiteral = "strLiteral",
  symbol = "symbol",
}

export interface ParsedItemBase<T extends ParsedItemType> {
  type: T
}

export interface ParsedBoolLiteral
  extends ParsedItemBase<ParsedItemType.boolLiteral> {
  value: boolean
}

export interface ParsedFuncCall
  extends ParsedItemBase<ParsedItemType.funcCall> {
  funcName: string
  args: ParsedItem[]
}

export interface ParsedList extends ParsedItemBase<ParsedItemType.list> {
  items: ParsedItem[]
}

export interface ParsedNullLiteral
  extends ParsedItemBase<ParsedItemType.nullLiteral> {}

export interface ParsedNumLiteral
  extends ParsedItemBase<ParsedItemType.numLiteral> {
  value: number
}

export interface ParsedReference
  extends ParsedItemBase<ParsedItemType.reference> {
  type: ParsedItemType.reference
  namespace: EnumT.ReferenceNamespace
  identifier: string
}

export interface ParsedStrLiteral
  extends ParsedItemBase<ParsedItemType.strLiteral> {
  value: string
}

export interface ParsedSymbol extends ParsedItemBase<ParsedItemType.symbol> {
  symbol: string
}

export interface ParsedJsonLiteral
  extends ParsedItemBase<ParsedItemType.jsonLiteral> {
  value: JsonValue
}

export type ParsedItem =
  | ParsedBoolLiteral
  | ParsedFuncCall
  | ParsedList
  | ParsedNullLiteral
  | ParsedNumLiteral
  | ParsedReference
  | ParsedJsonLiteral
  | ParsedStrLiteral
  | ParsedSymbol

export type ParsedItemByType<T extends ParsedItemType> =
  T extends ParsedItemType.numLiteral
    ? ParsedNumLiteral
    : T extends ParsedItemType.strLiteral
    ? ParsedStrLiteral
    : T extends ParsedItemType.funcCall
    ? ParsedFuncCall
    : T extends ParsedItemType.list
    ? ParsedList
    : T extends ParsedItemType.nullLiteral
    ? ParsedNullLiteral
    : T extends ParsedItemType.reference
    ? ParsedReference
    : T extends ParsedItemType.symbol
    ? ParsedSymbol
    : T extends ParsedItemType.boolLiteral
    ? ParsedBoolLiteral
    : never

export const symbolParser = regex(/[\w]*/).combine<ParsedSymbol>((sym) => ({
  type: ParsedItemType.symbol,
  symbol: sym,
}))

export const nullParser = term("null").combine<ParsedNullLiteral>(() => ({
  type: ParsedItemType.nullLiteral,
}))

export const boolParser = alt(
  term("true").result(true),
  term("false").result(false)
).combine<ParsedBoolLiteral>((value) => ({
  type: ParsedItemType.boolLiteral,
  value,
}))

export const digitParser = alt(
  term("0").result(0),
  term("1").result(1),
  term("2").result(2),
  term("3").result(3),
  term("4").result(4),
  term("5").result(5),
  term("6").result(6),
  term("7").result(7),
  term("8").result(8),
  term("9").result(9)
)

export const parseDigits = (digits: number[], negativeExponents: boolean) => {
  let result = 0
  for (let i = 0; i < digits.length; i++) {
    const exponent = negativeExponents ? -1 * (i + 1) : i
    result += digits[i] * Math.pow(10, exponent)
  }
  return result
}
export const parseDigitsBefore = (digits: number[]) =>
  parseDigits(digits.reverse(), false)
export const parseDigitsAfter = (digits: number[]) => parseDigits(digits, true)

export const numberParser = seqMap({
  sign: alt(term("+"), term("-").result(NEGATION_SENTRY)).optional(),
  magnitude: alt(
    term("Infinity").result(Infinity),
    term("NaN").result(NaN),
    seqMap({
      digitsBefore: digitParser
        .atleast(1)
        .combine(parseDigitsBefore)
        .optional(),
      decimal: term(".").optional(),
      digitsAfter: digitParser.atleast(1).combine(parseDigitsAfter).optional(),
    }).combine(({ digitsBefore, digitsAfter }) => {
      if (digitsBefore === undefined && digitsAfter === undefined) {
        throw new ParserError("no digits", true)
      }
      return (digitsBefore ?? 0) + (digitsAfter ?? 0)
    })
  ),
})
  .combine(({ sign, magnitude }) =>
    sign === NEGATION_SENTRY ? -1 * magnitude : magnitude
  )
  .combine<ParsedNumLiteral>((value) => ({
    type: ParsedItemType.numLiteral,
    value,
  }))

export const stringParser = term('"')
  .then(alt(term('\\"').result('"'), regex(/[^"]/)).many())
  .skip(term('"'))
  .combine<ParsedStrLiteral>((letters) => ({
    type: ParsedItemType.strLiteral,
    value: letters.join(""),
  }))

export const expressionParser = forwardDeclaration<ParsedItem>()

export const referenceParser = seqMap({
  _ref: term("ref:"),
  namespace: enumParser(EnumT.ReferenceNamespace),
  _decimal: term("."),
  identifier: regex(/[\w-]*/),
}).combine<ParsedReference>(({ namespace, identifier }) => ({
  type: ParsedItemType.reference,
  namespace,
  identifier,
}))

export const jsonParser = forwardDeclaration<JsonValue>()

export const jsonSimpleParser = lex(
  alt(
    numberParser.combine(({ value }) => value),
    stringParser.combine(({ value }) => value),
    boolParser.combine(({ value }) => value),
    nullParser.result(null)
  )
)

export const jsonArrayParser = lex(
  seqMap({
    _bracket1: lex(term("[")),
    elements: alt(
      seqMap({
        elements: lex(
          seqMap({ element: lex(jsonParser), _comma: lex(term(",")) }).combine(
            ({ element }) => element
          )
        ).atleast(1),
        lastElement: lex(jsonParser),
      }).combine<JsonValue[]>(({ elements, lastElement }) => [
        ...elements,
        lastElement,
      ]),
      jsonParser.combine((v) => [v])
    ).optionalFactory(newTypedArray<JsonValue>),
    _bracket2: lex(term("]")),
  }).combine(({ elements }) => elements!)
)

const kvParser = lex(
  seqMap({
    key: stringParser,
    _colon: lex(term(":")),
    value: jsonParser,
  })
)

export const jsonObjectParser = seqMap({
  _bracket1: lex(term("{")),
  pairs: alt(
    lex(
      seqMap({
        pairs: lex(
          seqMap({ pair: kvParser, _comma: lex(term(",")) }).combine(
            ({ pair }) => pair
          )
        ).atleast(1),
        lastPair: kvParser,
      }).combine<{ key: string; value: JsonValue }[]>(({ pairs, lastPair }) =>
        [...pairs, lastPair].map((pair) => ({
          key: pair.key.value,
          value: pair.value,
        }))
      )
    ),
    kvParser.combine((pair) => [{ key: pair.key.value, value: pair.value }])
  )
    .combine<PartialRecord<string, JsonValue>>((pairs) => {
      return Object.fromEntries(pairs.map((pair) => [pair.key, pair.value]))
    })
    .optionalFactory(newTypedObject<string, JsonValue>),
  _bracket2: lex(term("}")),
}).combine<JsonObject>(({ pairs }) => pairs!)

jsonParser.become(alt(jsonSimpleParser, jsonArrayParser, jsonObjectParser))

export const jsonTaggedParser = seqMap({
  _tag: term("json:"),
  jsonValue: lex(jsonParser),
}).combine<ParsedJsonLiteral>(({ jsonValue }) => ({
  type: ParsedItemType.jsonLiteral,
  value: jsonValue,
}))

export const simple = alt(
  numberParser,
  referenceParser,
  jsonTaggedParser,
  stringParser,
  nullParser,
  boolParser,
  symbolParser
)

expressionParser.become(
  alt(
    lex(simple),
    term("(")
      .then(alt(lex(simple), lex(expressionParser)).many())
      .skip(term(")"))
      .combine<ParsedList | ParsedFuncCall>((items) => {
        items.slice(1).forEach((item) => {
          if (item.type === ParsedItemType.symbol) {
            throw new ParserError(`unexpected symbol ${item.symbol}`, false)
          }
        })
        if (items[0]?.type === ParsedItemType.symbol) {
          return {
            type: ParsedItemType.funcCall,
            funcName: items[0].symbol,
            args: items.slice(1),
          }
        }
        return {
          type: ParsedItemType.list,
          items,
        }
      })
  )
)

export const scriptParser = lex(expressionParser).skip(EOF)

export function assertParsedItemType<PIT extends ParsedItemType>(
  parsedItem: ParsedItem | undefined,
  parsedItemType: PIT
): asserts parsedItem is typeof parsedItem & { type: PIT } {
  if (parsedItem?.type !== parsedItemType) {
    throw `type mismatch ${parsedItem?.type} !== ${parsedItemType}`
  }
}

export function isParsedItemType<PIT extends ParsedItemType>(
  parsedItem: ParsedItem | undefined,
  parsedItemType: PIT
): parsedItem is typeof parsedItem & { type: PIT } {
  return parsedItem?.type === parsedItemType
}

export type LiteralParsedItemType =
  | ParsedItemType.numLiteral
  | ParsedItemType.boolLiteral
  | ParsedItemType.strLiteral

export function assertValueMatchesLiteralType<
  PIT extends LiteralParsedItemType
>(
  value: GenericDataValue | undefined,
  type: PIT
): asserts value is ParsedItemByType<PIT>["value"] {
  switch (type) {
    case ParsedItemType.strLiteral:
      if (typeof value === "string") {
        return
      }
      break
    case ParsedItemType.boolLiteral:
      if (typeof value === "boolean") {
        return
      }
      break
    case ParsedItemType.numLiteral:
      if (typeof value === "number") {
        return
      }
      break
    default:
      assertNever(type)
  }
  throw `value ${value?.toString()} does not match parsed item type ${type}`
}

const LITERAL_PARSED_ITEM_TYPES = new Set<ParsedItemType>([
  ParsedItemType.numLiteral,
  ParsedItemType.boolLiteral,
  ParsedItemType.strLiteral,
])

export function isLiteralItem(
  parsedItem: ParsedItem | undefined
): parsedItem is typeof parsedItem & {
  type: LiteralParsedItemType
} {
  return parsedItem != null && LITERAL_PARSED_ITEM_TYPES.has(parsedItem.type)
}

export function assertLiteralItem(
  parsedItem: ParsedItem | undefined
): asserts parsedItem is ParsedItemByType<LiteralParsedItemType> {
  if (!isLiteralItem(parsedItem)) {
    throw `${parsedItem?.type} is not a literal type`
  }
}
