<script lang="ts">
  import Editor from "@toast-ui/editor"
  import "@toast-ui/editor/dist/toastui-editor.css" //
  import "@toast-ui/editor/dist/theme/toastui-editor-dark.css" //

  export let value: string

  let editorElem: HTMLDivElement | undefined
  let editor: Editor | undefined

  const onChange = () => {
    value = editor?.getMarkdown() ?? value
  }

  const initializeEditor = (el: HTMLDivElement) => {
    editor = new Editor({
      el,
      height: "500px",
      initialValue: value,
      initialEditType: "wysiwyg",
      theme: "dark",
      events: {
        change: onChange,
      },
      autofocus: false,
    })
  }

  $: if (editorElem != null) {
    initializeEditor(editorElem)
  }
</script>

<div bind:this={editorElem} />
