import { EnumT } from "../../schema"
import type { GenericDataValue, JsonDataType, NearbyPlaces } from "../../types"

export type NearbyPlacesData = JsonDataType<NearbyPlaces>

export function assertNearbyPlaces(
  v: GenericDataValue | undefined
): asserts v is NearbyPlacesData {
  if ((v as NearbyPlacesData).data.places == null) {
    throw `invalid nearby place data ${v?.toString()}`
  }
}

export const nearbyPlacesSliderFS = {
  type: EnumT.FormatSpecType.slider as EnumT.FormatSpecType.slider,
  min: 0.5,
  max: 30,
  step: 0.5,
  round: true,
  units: "mi",
}
