<script lang="ts">
  import { getSocket } from "@/client"
  import MarkdownEditor from "@/lib/MarkdownEditor.svelte"
  import { debounce } from "@/util"
  import { EnumT } from "@shared/schema"
  import { getParsedValueByField } from "@shared/sheet"
  import { findDefaultColumn } from "@shared/sheet/columns"
  import type { GenericDataValue, ProcessedSheetContent } from "@shared/types"

  export let sheetContent: ProcessedSheetContent
  export let rowId: string

  $: notesData = getParsedValueByField({
    sheetContent,
    rowId,
    field: EnumT.Field.notes,
  })

  let value: string = ""

  const updateValue = (v: string) => (value = v)

  $: if (notesData) {
    updateValue(notesData?.data.markdown)
  }

  $: sheetId = sheetContent.sheet.id
  $: colId = findDefaultColumn({
    columns: sheetContent.columns,
    columnDefinitions: sheetContent.columnDefinitions,
    field: EnumT.Field.notes,
  })

  $: debouncedOnChange = debounce(async (data: GenericDataValue) => {
    if (colId) {
      ;(await getSocket()).emit("editCell", {
        rowId,
        colId,
        data,
        sheetId,
      })
    }
  })

  $: if (value) {
    debouncedOnChange({ data: { markdown: value } })
  }
</script>

<MarkdownEditor bind:value />
