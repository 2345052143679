<script lang="ts">
  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import Icon from "./Icon.svelte"

  export let icon: IconDefinition
  export let label: string
  export let disabled: boolean = false
</script>

<button {disabled} aria-label={label} on:click><Icon {icon} /></button>

<style>
  :root {
    --offset-bottom: 20px;
    --offset-right: 20px;
    --offset-top: auto;
    --offset-left: auto;
  }
  button {
    position: absolute;
    bottom: var(--offset-bottom);
    right: var(--offset-right);
    top: var(--offset-top);
    left: var(--offset-left);
    background-color: var(--action-alt);
    border: none;
    font-size: 25px;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 500;
    box-shadow: var(--box-shadow);
  }
  button:hover {
    filter: brightness(85%);
  }
  button:active {
    filter: brightness(65%);
  }
  button:disabled {
    cursor: not-allowed;
    filter: brightness(0.75);
  }
</style>
