<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import { hasWriteAccess, sheetContentFactory } from "@/stores/sheetStore"
  import type { DataViewContext } from "./types"
  import NothingHereYet from "./NothingHereYet.svelte"

  export let sheetId: string
  export let context: DataViewContext

  $: sheetContent = $sheetContentFactory(sheetId)
  $: hasWrite = hasWriteAccess({ sheetId })
  $: numFiltered = sheetContent?.filteredRowIds.size

  const manageFilters = () => {
    context.viewEditorSidepanel.open({ props: { sheetId } })
  }
</script>

<div class="wrapper">
  {#if numFiltered != null}
    <div class="add-button-wrapper">
      {#if numFiltered > 0}
        <span>
          <span class="deemphasized">
            {#if numFiltered == 1}
              One item is being filtered.
            {:else}
              {numFiltered} items are being filtered.
            {/if}
          </span>
          <a href="javascript:void(0)" on:click={manageFilters}
            >Manage filters</a
          >
        </span>
      {:else if sheetContent?.rows.length === 0}
        <NothingHereYet {sheetId} {context} />
      {/if}
      <div>
        <Button
          disabled={!$hasWrite}
          on:click={context.openNewItemSidepanel}
          variant="action">Add Another</Button
        >
      </div>
    </div>
  {/if}
</div>

<style>
  .add-button-wrapper {
    padding-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .deemphasized {
    opacity: 0.8;
  }
  .wrapper {
    margin-top: 10px;
  }
</style>
