<script lang="ts">
  import { beginAuth, beginUnauth } from "@/api"
  import { didInitialLoad, signedIn } from "@/stores/authStore"
  import Button from "./Button.svelte"
  import {
    faArrowRightFromBracket,
    faArrowRightToBracket,
  } from "@fortawesome/free-solid-svg-icons"
</script>

{#if $didInitialLoad && !$signedIn}
  <Button
    iconRight={faArrowRightToBracket}
    variant="success"
    on:click={beginAuth}>Sign In</Button
  >
{:else if $didInitialLoad}
  <Button iconLeft={faArrowRightFromBracket} on:click={beginUnauth}
    >Sign Out</Button
  >
{/if}
