<script lang="ts">
  export let value: number | undefined
  export let min: number
  export let max: number
  export let step: number | undefined = undefined
  export let round: boolean = false

  $: if (round && value != null && value !== min && value !== max) {
    value = Math.round(value)
  }
</script>

<input type="range" bind:value {min} {max} {step} />
