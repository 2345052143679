<script lang="ts">
  import { itemPageRoute } from "@/routes"
  import { getItem, LocalStorageKey, setItem } from "@/util"
  import {
    faCircleInfo,
    faEye,
    faSync,
    faTrash,
  } from "@fortawesome/free-solid-svg-icons"
  import type { ProcessedSheetContent } from "@shared/types.ts"
  import { newTypedObject } from "@shared/util/index.ts"
  import DataTable from "./DataTable/index.ts"
  import type { RowAction } from "./DataTable/types.ts"
  import { confirmDelete } from "./modals/confirmDelete.ts"
  import { navigateTo } from "./RouterView/index.ts"
  import { bottomRightToastManager } from "./ToastManager/index.ts"
  import DataCellWrapper from "./DataCellWrapper.svelte"
  import { buildCellDataProvider } from "@/util/table.ts"
  import { ACTIONS_AXIS_ID } from "./DataTable/constants.ts"
  import { hasWriteAccess } from "@/stores/sheetStore.ts"
  import { getSocket } from "@/client/socket.ts"

  export let sheetContent: ProcessedSheetContent
  export let itemIds: string[] | undefined = undefined
  export let striped = false
  export let layoutFixed: boolean = false

  $: sheetId = sheetContent.sheet.id
  $: rows = [
    ACTIONS_AXIS_ID,
    ...sheetContent.rows
      .map(({ id }) => id)
      .filter((id) => !itemIds || itemIds?.includes(id)),
  ]
  $: columns = sheetContent.columns
    .filter(({ hidden }) => !hidden)
    .map(({ id }) => id)

  $: hasWrite = hasWriteAccess({ sheetId })

  const deleteRow = async ({ rowId }: { rowId: string }) => {
    ;(await getSocket()).emit("deleteRow", {
      sheetId,
      rowId,
    })
    bottomRightToastManager.pushToast({
      message: "Deleted row",
      icon: faCircleInfo,
    })
  }

  const columnResized = (e: CustomEvent<{ id: string; newWidth: number }>) => {
    const { id, newWidth } = e.detail
    const sizes = newTypedObject(
      getItem(LocalStorageKey.columnSizes) ?? undefined
    )
    sizes[id] = newWidth
    setItem(LocalStorageKey.columnSizes, sizes)
  }

  $: buildRowActionsForRow = (rowId: string): RowAction[] => {
    return [
      {
        icon: faEye,
        label: "View all Details",
        handler: () => {
          navigateTo(
            itemPageRoute({
              itemId: rowId,
              sheetId,
            })
          )
        },
      },
      {
        icon: faSync,
        label: "Refetch Data",
        disabled: !$hasWrite,
        handler: async () => {
          ;(await getSocket()).emit("refreshRow", {
            sheetId,
            rowId,
          })
          bottomRightToastManager.pushToast({
            icon: faSync,
            message: "Refreshing row data",
          })
        },
      },
      {
        icon: faTrash,
        label: "Delete Listing",
        disabled: !$hasWrite,
        variant: "danger",
        handler: async () => {
          // delete row
          try {
            const deleted = await confirmDelete({
              title: "Delete Listing",
              description: "Are you sure you want to delete this row?",
            })
            if (deleted) {
              deleteRow({ rowId })
            }
          } finally {
          }
        },
      },
    ]
  }

  const buildColActionsForCol = (colId: string) => {
    // No column actions on the compare view
    return []
  }
</script>

<DataTable
  stickyHeader
  className="data-table"
  {columns}
  {rows}
  fullwidth
  transpose
  on:columnResized={columnResized}
  cellDataProvider={buildCellDataProvider({
    sheetId,
    buildRowActionsForRow,
    buildColActionsForCol,
    hideSortControls: true,
  })}
  {striped}
  {layoutFixed}
  readonly={!$hasWrite}
  cellRenderer={DataCellWrapper}
/>
