import { LocalStorageKey, getItem, setItem } from "@/util"
import { derived, writable } from "svelte/store"

const darkMatcher = window.matchMedia("(prefers-color-scheme: dark)")

const darkModeWritable = writable<boolean>(darkMatcher.matches)

darkMatcher.addEventListener("change", (e: MediaQueryListEvent) => {
  darkModeWritable.set(e.matches)
})

export const darkMode = derived(darkModeWritable, (dmw) => dmw)

const mobileMatcher = window.matchMedia("only screen and ((max-width: 767px))")

export const mobile = writable<boolean>(mobileMatcher.matches)

mobileMatcher.addEventListener("change", (e: MediaQueryListEvent) => {
  mobile.set(e.matches)
})

export const isMobile = () => mobileMatcher.matches

type ThemeVariable =
  | "secondary-bg"
  | "secondary-fg"
  | "primary-bg"
  | "primary-fg"
  | "shadow-color"
  | "bigtext-happy"
  | "bigtext-happy-glow"
  | "marker-primary-color"
  | "marker-secondary-color"
  | "route-color"

export const getThemeVariable = (themeVar: ThemeVariable) =>
  getComputedStyle(document.documentElement)
    .getPropertyValue(`--${themeVar}`)
    .trim()

export const setDarkMode = (isDarkMode: boolean) => {
  darkModeWritable.set(isDarkMode)
  setItem(LocalStorageKey.darkMode, isDarkMode)
  document.documentElement.style.setProperty(
    "--is-dark-theme",
    isDarkMode ? "initial" : " "
  )
  document.documentElement.style.setProperty(
    "--is-light-theme",
    isDarkMode ? " " : "initial"
  )
}

const isDarkMode = getItem(LocalStorageKey.darkMode)
if (isDarkMode != null) {
  setDarkMode(isDarkMode)
}
