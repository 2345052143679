<script lang="ts" context="module">
  const buildCommuteDetailsGeneratorSpec = (
    query: string,
    transitMode: string
  ) =>
    serialize({
      type: ParsedItemType.funcCall,
      funcName: "commuteDetailsByQuery",
      args: [
        {
          type: ParsedItemType.reference,
          namespace: ReferenceNamespace.fields,
          identifier: "location",
        },
        { type: ParsedItemType.strLiteral, value: query },
        { type: ParsedItemType.strLiteral, value: transitMode },
      ],
    })
  const buildNearbyPlacesGeneratorSpec = (
    query: string,
    searchRadiusMi: number
  ) =>
    serialize({
      type: ParsedItemType.funcCall,
      funcName: "nearbyPlacesByQuery",
      args: [
        {
          type: ParsedItemType.reference,
          namespace: ReferenceNamespace.fields,
          identifier: "location",
        },
        { type: ParsedItemType.strLiteral, value: query },
        { type: ParsedItemType.numLiteral, value: searchRadiusMi },
      ],
    })
</script>

<script lang="ts">
  import {
    sheetContentFactory,
    createFunctionResolver,
  } from "@/stores/sheetStore.ts"
  import SidepanelContent from "./SidepanelContent.svelte"
  import { getSocket } from "@/client"
  import { ParsedItemType } from "@shared/lispable/parsing"
  import { serialize } from "@shared/lispable/script"
  import { ReferenceNamespace } from "@shared/schema/enum"
  import Input from "../Input.svelte"
  import SingleSelect from "@/lib/controls/SingleSelect.svelte"
  import { transitModeOptions } from "@shared/lispable/functions"
  import Button from "../Button.svelte"
  import { EnumT } from "@shared/schema"
  import HintMessage from "../HintMessage.svelte"
  import { t } from "@/translation/index.ts"
  import LocationInput from "../LocationInput.svelte"
  import { assertNever, newTypedArray } from "@shared/util"
  import { TRANSIT_MODE_EMOJI_LOOKUP } from "@shared/data/defaults"
  import type { LocationDetails, TransitMode } from "@shared/types"
  import type { ColumnToCreate } from "@shared/api/ws.ts"
  import { getParsedValueByField } from "@shared/sheet"
  import type { LocationDetailsData } from "@shared/data/json/location"
  import { nearbyPlacesSliderFS } from "@shared/data/json/nearby_places"
  import DataFormatEditor from "../DataFormat/display/DataFormatEditor.svelte"
  import PoiMap from "../PoiMap.svelte"
  import TabsHeader from "../TabsHeader.svelte"

  export let sheetId: string
  export let onAccept: () => void

  let newWaypointQuery: string = ""
  let newWaypointTransitMode: TransitMode = "walking"

  $: sheetContent = $sheetContentFactory(sheetId)

  const addNew = async () => {
    const columns = newTypedArray<ColumnToCreate>()
    columns.push({
      name: computedName,
      dataType: EnumT.DataType.json,
      formatSpec: {
        type: EnumT.FormatSpecType.commuteDetails,
      },
      generatorSpec: buildCommuteDetailsGeneratorSpec(
        computedQuery,
        newWaypointTransitMode
      ),
    })
    if (chosenPoiType === "category") {
      columns.push({
        name: `# Nearby: ${computedQuery}`,
        dataType: EnumT.DataType.json,
        formatSpec: {
          type: EnumT.FormatSpecType.nearbyPlaces,
        },
        generatorSpec: buildNearbyPlacesGeneratorSpec(
          computedQuery,
          searchRadius
        ),
      })
    }
    ;(await getSocket()).emit("createColumns", {
      columns,
      sheetId,
    })

    newWaypointQuery = ""
    newWaypointTransitMode = "walking"
    specificLocationAddress = ""
    onAccept()
  }

  $: acceptDisabled =
    (chosenPoiType === "category" && !newWaypointQuery) ||
    (chosenPoiType === "specific" && !specificLocationAddress)

  type Suggestion = { emoji: string; label: string }

  const pickSuggestion = (suggestion: Suggestion) => {
    newWaypointQuery = suggestion.label
  }

  const suggestions: { emoji: string; label: string }[] = [
    { emoji: "🏋️", label: "Gym" },
    { emoji: "🌳", label: "Park" },
    { emoji: "🛒", label: "Grocery store" },
    { emoji: "☕", label: "Cafe" },
    { emoji: "💊", label: "Pharmacy" },
    { emoji: "🏥", label: "Hospital" },
    { emoji: "📚", label: "Library" },
    { emoji: "🚇", label: "Metro station" },
  ]

  let chosenPoiType: "category" | "specific" = "category"
  let specificLocationAddress: string = ""
  let waypointTitle = ""
  let didClickOne = false
  let selectedLocationDetails: LocationDetails | undefined = undefined
  const nearbyPlacesResolver = createFunctionResolver("nearbyPlacesByQuery")
  const commuteDetailsResolver = createFunctionResolver("commuteDetailsByQuery")

  $: computedQuery =
    chosenPoiType === "category" ? newWaypointQuery : specificLocationAddress

  $: computedEmoji =
    TRANSIT_MODE_EMOJI_LOOKUP[newWaypointTransitMode as TransitMode]
  $: computedName = `${
    chosenPoiType === "category"
      ? newWaypointQuery.length > 0
        ? `${computedEmoji} Nearest ${newWaypointQuery.toLowerCase()}`
        : ""
      : waypointTitle.length > 0
      ? `${computedEmoji} Commute to ${waypointTitle.toLowerCase()}`
      : ""
  }`

  $: commuteDetailsQueryArgs = (() => {
    if (sourceLocationData == null) {
      return undefined
    }
    if (chosenPoiType === "category") {
      if (newWaypointQuery == "") {
        return undefined
      }
      return [sourceLocationData, newWaypointQuery, newWaypointTransitMode]
    } else if (chosenPoiType === "specific") {
      if (specificLocationAddress == "") {
        return undefined
      }
      return [
        sourceLocationData,
        specificLocationAddress,
        newWaypointTransitMode,
      ]
    }
    assertNever(chosenPoiType)
  })()
  $: commuteDetailsStore = commuteDetailsResolver.load(commuteDetailsQueryArgs)
  $: commuteDetails = $commuteDetailsStore?.data

  $: sourceRow = (() => {
    if (!sheetContent) {
      return undefined
    }
    for (const row of sheetContent.rows) {
      const location = getParsedValueByField({
        sheetContent,
        rowId: row.id,
        field: EnumT.Field.location,
      }) as Undefined<LocationDetailsData>
      if (location != null) {
        return row.id
      }
    }
  })()
  $: sourceLocationData =
    sourceRow && sheetContent
      ? getParsedValueByField({
          sheetContent,
          rowId: sourceRow,
          field: EnumT.Field.location,
        })
      : undefined
  $: nearbyPlacesStore = nearbyPlacesResolver.load(
    chosenPoiType === "category" &&
      sourceLocationData != null &&
      newWaypointQuery != ""
      ? [sourceLocationData, newWaypointQuery, searchRadius]
      : undefined
  )
  $: nearbyPlaces = $nearbyPlacesStore?.data
  let searchRadius: number = 3

  const setChoice = (choice: typeof chosenPoiType) => () => {
    chosenPoiType = choice
    didClickOne = true
  }

  let selectedIndex: number = 0

  $: if (selectedIndex === 0) {
    chosenPoiType = "category"
  } else {
    chosenPoiType = "specific"
  }
</script>

{#if sheetContent}
  <SidepanelContent>
    <div class="main-grid">
      {#if chosenPoiType !== "category" && didClickOne}
        <div>
          <Button on:click={setChoice("category")}
            >{t("Find the nearest...")}</Button
          >
        </div>
      {:else}
        <div>{t("Search for...")}</div>
      {/if}
      <TabsHeader
        options={[{ label: "Type of Place" }, { label: "Specific Location" }]}
        bind:selectedIndex
      />
      {#if chosenPoiType === "category"}
        <div
          class="choosable-inner"
          class:shrink={didClickOne && chosenPoiType !== "category"}
        >
          <div class="input-wrapper">
            <Input
              bind:value={newWaypointQuery}
              type="text"
              size="large"
              fullwidth
              placeholder="Type or pick an option below"
            />
          </div>
          <div class="suggestions-wrapper">
            {#each suggestions as suggestion}
              <Button on:click={() => pickSuggestion(suggestion)}
                >{suggestion.emoji}&nbsp;{suggestion.label}</Button
              >
            {/each}
          </div>
          <HintMessage>
            You can also specify a restaurant or store like <strong
              >Trader Joe's</strong
            >
            or <strong>CVS</strong>.
          </HintMessage>
          <div>Search radius: {searchRadius} mi</div>
          <DataFormatEditor
            dataType={EnumT.DataType.number}
            formatSpec={nearbyPlacesSliderFS}
            bind:value={searchRadius}
          />
        </div>
      {:else}
        <div
          class="choosable-inner location-picker"
          class:shrink={didClickOne && chosenPoiType !== "specific"}
        >
          Address or name
          <LocationInput
            bind:searchQuery={specificLocationAddress}
            size="large"
          />
          Label
          <Input
            placeholder="Work"
            type="text"
            fullwidth
            size="large"
            bind:value={waypointTitle}
          />
          <HintMessage>
            This option is useful if there is a specific location, like a work
            address, that you want to commute to.
          </HintMessage>
        </div>
      {/if}
      <div>
        <SingleSelect
          placeholder="Transit mode"
          options={transitModeOptions}
          bind:value={newWaypointTransitMode}
        />
      </div>
      {#if computedName && sourceRow}
        <div>
          Preview
          <div class="computed-name">{computedName}</div>
          <PoiMap
            {sheetContent}
            rowId={sourceRow}
            {commuteDetails}
            {nearbyPlaces}
            onSelectPlace={(place) => {
              selectedLocationDetails = place
            }}
          />
        </div>
      {/if}
    </div>
    <div slot="footer" class="footer">
      <Button on:click={addNew} variant="success" disabled={acceptDisabled}
        >Submit</Button
      >
    </div>
  </SidepanelContent>
{/if}

<style>
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .input-wrapper {
    font-size: 120%;
  }
  .computed-name {
    text-align: center;
  }
  .main-grid {
    display: grid;
    grid-template-columns: 100%;
    gap: 16px;
    max-width: 400px;
    align-self: center;
    justify-self: center;
  }
  .suggestions-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  .choosable-inner {
    padding: var(--spacing-md);
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;
  }
  .shrink {
    max-height: 0px;
    padding: 0;
  }
</style>
