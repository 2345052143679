<script lang="ts" context="module">
  const displayModeOrder: Record<WaypointDisplayMode, number> = {
    [WaypointDisplayMode.markerPrimary]: 0,
    [WaypointDisplayMode.markerSecondary]: 1,
    [WaypointDisplayMode.dotSecondary]: 2,
  }
</script>

<script lang="ts">
  import { debounce, useMapView } from "@/util"
  import { getCenter, getPreferredStyle } from "@/util/map"
  import {
    Map,
    Marker,
    GeoJSONSource,
    LngLatBounds,
    NavigationControl,
    Popup,
    type Offset,
  } from "maplibre-gl"
  import { SvelteComponent, onMount, tick } from "svelte"
  import { darkMode } from "@/stores/styleStore.ts"
  import { assertIsDefined, Location } from "@shared/util/index.ts"
  import { routeToGeoJSON } from "@shared/util/location.ts"
  import {
    WaypointDisplayMode,
    type MapRoute,
    type Waypoint,
    type WaypointClickContext,
    type MapContext,
  } from "@/util/map.ts"
  import { getThemeVariable } from "@/stores/styleStore"

  export let waypoints: Waypoint[] = []
  export let routes: MapRoute[] = []
  export let frozen: boolean = false
  export let disableDefaultCenter: boolean = false
  export let gestures: boolean = false

  let mapElem: HTMLDivElement | undefined
  let map: Map | null = null
  let mapLoaded: boolean = false
  let markers: Marker[] = []

  const popup = new Popup({
    closeButton: true,
    closeOnClick: false,
    focusAfterOpen: false,
    offset: {
      bottom: [0, -35],
      left: [15, -10],
      right: [-15, -10],
    } as Offset,
  })

  const initializeMap = async () => {
    await tick()
    if (!mapElem) {
      return
    }
    const positions = waypoints.map(({ location }) => location.toObj())
    const center = getCenter(positions)
    mapLoaded = false
    map = new Map({
      container: mapElem,
      center,
      zoom: 13,
      style: getPreferredStyle($darkMode),
      customAttribution: "",
      cooperativeGestures: gestures,
      interactive: !frozen,
    })
    map.addControl(new NavigationControl({}))
    map.on("load", () => {
      mapLoaded = true
    })
  }

  export let mapContext: MapContext | undefined = undefined
  const setMapContext = (mc: MapContext | undefined) => {
    mapContext = mc
  }
  const getWaypointById = (waypointId: string) =>
    waypoints.find((waypoint) => waypoint.waypointId === waypointId)

  $: setMapContext(
    map
      ? {
          panTo: (loc: Location) => map?.panTo(loc.toObj()),
          centerOnContent: () => {
            const bounds = getContentBounds()
            if (bounds != null) {
              map?.fitBounds(bounds, { padding: 50, maxZoom: 15 })
            }
          },
          setPopup: <P extends Record<string, any>>({
            component,
            props,
            waypointId,
          }: {
            component: typeof SvelteComponent<P>
            props: P
            waypointId: string
          }) => {
            const waypoint = getWaypointById(waypointId)
            if (!waypoint || !map) {
              return
            }
            popup.setLngLat(waypoint.location.toObj()).setHTML("").addTo(map)
            const target = popup
              .getElement()
              .querySelector(".mapboxgl-popup-content")
            assertIsDefined(target)
            new component({ target, props })
          },
          getCenter: () => {
            const center = map?.getCenter()
            // IMPROVE: we need to eliminate places where we pass undefined to
            // Location
            return new Location(
              center ? { lat: center.lat, lon: center.lng } : undefined
            )
          },
        }
      : undefined
  )

  $: map?.setStyle(getPreferredStyle($darkMode))

  const getContentBounds = () => {
    if (markers.length === 0) {
      return undefined
    }
    const bounds = new LngLatBounds()
    markers.forEach((waypoint) => bounds.extend(waypoint.getLngLat()))
    return bounds
  }

  $: {
    markers.forEach((marker) => marker.remove())
    const bounds = new LngLatBounds()
    ;[...waypoints]
      .sort((waypointA, waypointB) => {
        return (
          displayModeOrder[waypointA.displayMode] -
          displayModeOrder[waypointB.displayMode]
        )
      })
      .reverse()
      .forEach((waypoint) => {
        if (map) {
          const loc = waypoint.location
          if (loc.nonZero()) {
            let markerElem: HTMLDivElement | undefined = undefined
            let color: string
            switch (waypoint.displayMode) {
              case WaypointDisplayMode.dotSecondary: {
                markerElem = document.createElement("div")
                markerElem.className = "listable-marker-dot-secondary"
                color = getThemeVariable("marker-secondary-color")
                break
              }
              case WaypointDisplayMode.markerPrimary: {
                color = getThemeVariable("marker-primary-color")
                break
              }
              case WaypointDisplayMode.markerSecondary: {
                color = getThemeVariable("marker-secondary-color")
                break
              }
            }
            const marker = new Marker({
              color,
              element: markerElem,
            })
              .setLngLat(loc)
              .addTo(map)
            markers.push(marker)
            bounds.extend(marker.getLngLat())
            const el = marker.getElement()
            el.classList.add("marker")
            if (waypoint.onClick != null) {
              el.addEventListener("click", (e: MouseEvent) => {
                const setWaypointPopup = <P extends Record<string, any>>(
                  component: typeof SvelteComponent<P>,
                  props: P
                ) => {
                  mapContext?.setPopup({
                    component,
                    props,
                    waypointId: waypoint.waypointId,
                  })
                }
                const clickContext: WaypointClickContext = {
                  setWaypointPopup,
                  ...mapContext!,
                }
                waypoint.onClick?.(e, clickContext)
              })
              el.style.cursor = "pointer"
            }
          }
        }
      })
    if (waypoints.length > 0 && !disableDefaultCenter) {
      map?.fitBounds(bounds, { padding: 50, maxZoom: 15 })
    }
  }
  $: geoJsonRoutes = routes.map(({ route }) => routeToGeoJSON(route))
  $: {
    if (map && mapLoaded) {
      let fullGeoJson: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: geoJsonRoutes,
      }
      const existingSource = map.getSource("routes") as Undefined<GeoJSONSource>
      if (existingSource) {
        existingSource.setData(fullGeoJson)
      } else {
        map.addSource("routes", {
          type: "geojson",
          data: fullGeoJson,
        })
        map.addLayer({
          id: "route-layer",
          type: "line",
          source: "routes",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": getThemeVariable("route-color"),
            "line-width": 4,
          },
        })
      }
    }
  }

  const debouncedInitializeMap = debounce(initializeMap)

  onMount(() => {
    if (mapElem) {
      new ResizeObserver(debouncedInitializeMap).observe(mapElem)
    }
    useMapView()
  })
</script>

<div class="container" bind:this={mapElem} />

<style>
  .container {
    width: 100%;
    height: 100%;
  }
  :global(.listable-marker-dot-secondary) {
    width: 8px;
    height: 8px;
    border-radius: 15px;
    background-color: white;
    border: 5px solid red;
  }
</style>
