<script lang="ts">
  import MapComponent from "./MapComponent.svelte"
  import type { MapContext, MapRoute, Waypoint } from "@/util/map.ts"

  export let waypoints: Waypoint[] = []
  export let routes: MapRoute[] = []
  export let frozen: boolean = false
  export let mapContext: MapContext | undefined = undefined
</script>

<div class="wrapper">
  <MapComponent gestures bind:mapContext {waypoints} {routes} {frozen} />
</div>

<style>
  .wrapper {
    position: relative;
    border-radius: var(--default-rounding);
    overflow: hidden;
  }
  .wrapper {
    width: 100%;
    height: min(80vw, 400px);
  }
</style>
