import { getParsedValueByColumn, getParsedValueByField } from "."
import { builtins } from "../lispable/functions"
import { EnumT } from "../schema"
import type {
  FunctionCall,
  FunctionResolver,
  GenericDataValue,
  ProcessedSheetContent,
} from "../types"
import { assertDefined } from "../util"

export const buildLookupForSheetContent = ({
  sheetContent,
  rowId,
}: {
  sheetContent: ProcessedSheetContent
  rowId: string
}) => {
  return ({
    namespace,
    identifier,
  }: {
    namespace: EnumT.ReferenceNamespace
    identifier: string
  }): GenericDataValue | undefined => {
    switch (namespace) {
      case EnumT.ReferenceNamespace.columns: {
        const colId = identifier
        const cellKey = { colId, rowId }
        return getParsedValueByColumn({ cellKey, sheetContent })
      }
      case EnumT.ReferenceNamespace.fields: {
        const field = identifier as EnumT.Field
        return getParsedValueByField({
          rowId,
          sheetContent,
          field,
        })
      }
      case EnumT.ReferenceNamespace.globals:
        return (
          sheetContent.sheetGlobals.find((sg) => sg.id === identifier)?.value ??
          undefined
        )
      default:
        throw "unimplemented"
    }
  }
}

export const syncFunctionResolver: FunctionResolver = (
  funcCall: FunctionCall
) => {
  const func = assertDefined(
    builtins[funcCall.funcName as keyof typeof builtins]
  )
  if (func.async) {
    throw "Async calls not supported in this context"
  }
  return func.handler(...funcCall.args)
}
