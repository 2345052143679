import type { EnumT } from "../schema"
import type { ParsedSheetContent } from "../types"
import { coalesceEmptyArray } from "../util"

export const findDefaultColumn = ({
  columns,
  columnDefinitions,
  field,
  matchByNames,
}: {
  columns: ParsedSheetContent["columns"]
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
  field: EnumT.Field
  matchByNames?: {
    dataType: EnumT.DataType
    names?: string[]
  }
}) => {
  let refNameMatchColId: string | undefined = undefined
  let nameMatchColId: string | undefined = undefined
  const canonicalizedNames = new Set(
    coalesceEmptyArray(matchByNames?.names).map((name) =>
      name.toLowerCase().trim()
    )
  )
  for (const col of columns) {
    const colDef = columnDefinitions.find(
      (columnDef) => columnDef.id === col.columnDefinitionId
    )
    if (colDef?.field === field) {
      refNameMatchColId = col.id
    }
    const canonicalizedName = col.name.toLowerCase().trim()
    if (
      canonicalizedNames.has(canonicalizedName) &&
      colDef?.dataType === matchByNames?.dataType
    ) {
      nameMatchColId = col.id
    }
  }
  return refNameMatchColId ?? nameMatchColId
}
