<script context="module" lang="ts">
  const getMpgStr = ({
    highwayMpg,
    cityMpg,
  }: {
    highwayMpg?: Undefined<number>
    cityMpg?: Undefined<number>
  }) => {
    if (highwayMpg && cityMpg) {
      return `${highwayMpg} / ${cityMpg} MPG`
    } else if (cityMpg) {
      return `${cityMpg} City MPG`
    } else if (highwayMpg) {
      return `${highwayMpg} Highway MPG`
    }
    return undefined
  }
</script>

<script lang="ts">
  import { fieldToIconMap } from "@/constants"
  import { EnumT } from "@shared/schema/index.ts"
  import type { PartialFieldValueLookup } from "@shared/types.ts"
  import { newTypedObject, objectEntries } from "@shared/util/index.ts"
  import Icon from "./Icon.svelte"

  type Columns = $$Generic<
    Partial<Record<string | symbol | number, TableColumn<any>>>
  >

  export let fieldValueLookup: PartialFieldValueLookup

  $: priceUsd = fieldValueLookup[EnumT.Field.priceUsd]
  $: formattedPrice =
    priceUsd != null
      ? new Intl.NumberFormat(undefined, {}).format(priceUsd)
      : undefined

  $: beds = fieldValueLookup[EnumT.Field.beds]
  $: baths = fieldValueLookup[EnumT.Field.baths]
  $: sqft = fieldValueLookup[EnumT.Field.sqft]
  $: mileage = fieldValueLookup[EnumT.Field.mileage]
  $: formattedMileage =
    mileage != null
      ? new Intl.NumberFormat(undefined, {}).format(mileage)
      : undefined

  $: doors = fieldValueLookup[EnumT.Field.doorCount]
  $: seats = fieldValueLookup[EnumT.Field.seatCount]
  $: highwayMpg = fieldValueLookup[EnumT.Field.highwayMpg]
  $: cityMpg = fieldValueLookup[EnumT.Field.cityMpg]
  $: exteriorColor = fieldValueLookup[EnumT.Field.exteriorColor]
  $: interiorColor = fieldValueLookup[EnumT.Field.interiorColor]
  $: usage = fieldValueLookup[EnumT.Field.usageStatus]
  $: bodyStyle = fieldValueLookup[EnumT.Field.bodyStyle]

  $: quickFacts = newTypedObject<EnumT.Field, string | undefined>({
    [EnumT.Field.priceUsd]: formattedPrice,
    [EnumT.Field.beds]: beds?.toString(),
    [EnumT.Field.baths]: baths?.toString(),
    [EnumT.Field.sqft]: sqft ? `${sqft} ft²` : undefined,
    [EnumT.Field.mileage]: formattedMileage
      ? `${formattedMileage} mi`
      : undefined,
    [EnumT.Field.doorCount]: doors?.toString(),
    [EnumT.Field.seatCount]: seats?.toString(),
    [EnumT.Field.highwayMpg]: getMpgStr({ highwayMpg, cityMpg }),
    [EnumT.Field.exteriorColor]: exteriorColor?.toString(),
    [EnumT.Field.interiorColor]: interiorColor?.toString(),
    [EnumT.Field.usageStatus]: usage?.toString(),
    [EnumT.Field.bodyStyle]: bodyStyle?.toString(),
  })
</script>

{#each objectEntries(quickFacts) as [field, val]}
  {#if fieldToIconMap[field] && val}
    <div class="quick-fact-wrapper">
      <div class="quick-fact">
        <Icon icon={fieldToIconMap[field]} />
        <span class="quick-fact-val">{val}</span>
      </div>
    </div>
  {/if}
{/each}

<style>
  .quick-fact-wrapper {
    display: flex;
  }
  .quick-fact {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .quick-fact-val {
    margin-left: 4px;
  }
</style>
