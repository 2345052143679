<script lang="ts">
  import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
  import Icon from "./Icon.svelte"
</script>

<div class="hint-wrapper">
  <Icon icon={faInfoCircle} />&nbsp;<slot />
</div>

<style>
  .hint-wrapper {
    border: 2px solid var(--primary-fg);
    border-radius: var(--default-rounding);
    padding: 8px;
    opacity: 0.9;
  }
</style>
