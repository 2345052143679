<script lang="ts">
  import NullWrapper from "@/lib/NullWrapper.svelte"
  import SourceCard from "@/lib/SourceCard.svelte"
  import { getThemeVariable } from "@/stores/styleStore"
  import { isMobile } from "@/util"
  import { COMMON_ANIMATION_OPTIONS } from "@/util/animation"
  import { EnumT } from "@shared/schema/index.ts"
  import { objectKeys, randIntInRange } from "@shared/util/index.ts"
  import { scroll, timeline, type TimelineDefinition } from "motion"
  import { onMount } from "svelte"

  type DemoTableCellData = {
    source: EnumT.Source
    price: string
    numBeds: string
    numBaths: string
    travelTime: string
  }
  const DEMO_TABLE_CELL_DATA: DemoTableCellData[] = [
    {
      source: EnumT.Source.streetEasy,
      price: "$1,400",
      numBeds: "2 🛏️",
      numBaths: "2 🚽",
      travelTime: "🚎 14 minutes",
    },
    {
      source: EnumT.Source.trulia,
      price: "$1,800",
      numBeds: "2 🛏️",
      numBaths: "1 🚽",
      travelTime: "🚎 8 minutes",
    },
    {
      source: EnumT.Source.zillow,
      price: "$900",
      numBeds: "1 🛏️",
      numBaths: "1 🚽",
      travelTime: "🚎 29 minutes",
    },
  ]

  const initAnimation = () => {
    const phase1Duration = 4
    const phase2Duration = 4
    const phase3Duration = 2
    const sequence: TimelineDefinition = []
    sequence.push("start")
    sequence.push([
      ".bigtext-glow",
      { opacity: [0, 1] },
      {
        duration: phase1Duration + phase2Duration + phase1Duration / 2,
      },
    ])
    sequence.push([
      ".chaos",
      { opacity: [0, 1] },
      { duration: phase1Duration, at: "start" },
    ])
    sequence.push([".dummy", { opacity: 0 }, { duration: phase1Duration / 6 }])
    sequence.push("organizing")
    sequence.push([
      ".organized",
      { opacity: [0, 1] },
      { duration: phase2Duration },
    ])

    const happyColor = getThemeVariable("bigtext-happy")
    const happyColorGlow = getThemeVariable("bigtext-happy-glow")

    sequence.push([
      ".bigtext-glow",
      {
        boxShadow: `0px 0px 130px 130px ${happyColorGlow}`,
        backgroundColor: happyColorGlow,
      },
      {
        duration: phase2Duration / 2,
        at: "organizing",
      },
    ])
    sequence.push([
      ".chaos",
      { color: happyColor },
      {
        duration: phase2Duration,
        at: "organizing",
      },
    ])
    sequence.push([
      ".organized",
      { color: happyColor },
      {
        duration: phase2Duration,
        at: "organizing",
      },
    ])

    DEMO_TABLE_CELL_DATA.forEach((row, y) => {
      objectKeys(row).forEach((_, x) => {
        const startX = randIntInRange(-50, 50)
        const standardOffset = isMobile() ? -50 : 80
        const maxY = (y + 1) * -200
        const minY = maxY - standardOffset
        const startY = randIntInRange(minY, maxY)
        const cellKey = `.cell-${x}-${y}`
        sequence.push([
          cellKey,
          {
            x: [startX, 0],
            y: [startY, 0],
            opacity: [null, 1],
            boxShadow: ["4px 8px 15px 0px rgba(0, 0, 0, 0.45)", "none"],
          },
          {
            duration: phase2Duration,
            at: "organizing",
          },
        ])

        sequence.push([
          cellKey,
          { opacity: [0, 0.6], scale: [0, 1] },
          {
            duration: phase1Duration,
            at: "start",
          },
        ])
      })
    })

    sequence.push("phase3")

    sequence.push([
      ".demo-table-header",
      { opacity: [0, 1] },
      { duration: phase3Duration },
    ])

    sequence.push([
      ".demo-table",
      {
        boxShadow: `5px 12px 25px 0px ${getThemeVariable("shadow-color")}`,
        backgroundColor: getThemeVariable("primary-bg"),
      },
      { duration: phase3Duration, at: "phase3" },
    ])
    sequence.push([".dummy", { opacity: 0 }, { duration: phase3Duration / 6 }])

    // TODO: add delay of phase2Duration / 2

    scroll(timeline(sequence, { defaultOptions: COMMON_ANIMATION_OPTIONS }), {
      offset: ["start end", "end end"],
      target: document.querySelector(".section-1") ?? undefined,
    })
  }

  onMount(initAnimation)
</script>

<div class="demo-table-wrapper">
  <div class="bigtext-wrapper">
    <div class="bigtext">
      <div class="chaos">chaos</div>
      <div class="organized">organized</div>
      <div class="bigtext-glow" />
    </div>
  </div>
  <div class="demo-table">
    <div class="demo-table-mask" />
    <NullWrapper
      ><div class="demo-table-header">Source</div>
      <div class="demo-table-header">Price</div>
      <div class="demo-table-header">Beds</div>
      <div class="demo-table-header">Baths</div>
      <div class="demo-table-header">Commute</div></NullWrapper
    >
    {#each DEMO_TABLE_CELL_DATA as rowData, i}
      <div class={`cell cell-0-${i}`}>
        <SourceCard size="small" readonly nofocus source={rowData.source} />
      </div>
      <div class={`cell cell-1-${i}`}>{rowData.price}</div>
      <div class={`cell cell-2-${i}`}>{rowData.numBeds}</div>
      <div class={`cell cell-3-${i}`}>{rowData.numBaths}</div>
      <div class={`cell cell-4-${i}`}>{rowData.travelTime}</div>
    {/each}
    <NullWrapper
      ><div class="demo-table-header" />
      <div class="demo-table-header" />
      <div class="demo-table-header" />
      <div class="demo-table-header" />
      <div class="demo-table-header" /></NullWrapper
    >
  </div>
</div>

<style>
  .demo-table-wrapper {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    padding-bottom: 100px;
    overflow: visible;
    padding-top: 150px;
  }
  .demo-table-mask {
    position: absolute;
    right: -50px;
    width: 150px;
    top: 0;
    bottom: 0;
    z-index: 5;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      var(--primary-bg) 61%,
      var(--primary-bg) 100%
    );
  }
  .demo-table {
    margin-bottom: 5px;
    border-radius: 16px;
    background-color: var(--secondary-bg);
    display: grid;
    grid-template-columns: 130px 100px 100px 100px 140px;
    column-gap: 10px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    max-width: 1200px;
    margin-left: 20px;
    z-index: 1000;
  }
  .demo-table-header {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .cell {
    padding-left: 8px;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    text-align: center;
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--primary-bg);
    border-radius: 10px;
    z-index: 3;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre;
  }
  .bigtext {
    z-index: 4;
    font-family: "Sarabun", sans-serif;
    font-size: 60px;
    color: #fe22ad;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: inline-block;
    text-align: center;
    margin-bottom: 150px;
  }
  .bigtext-glow {
    aspect-ratio: 1 / 1;
    width: 80px;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    box-shadow: 10px 10px 130px 130px rgba(236, 1, 98, 0.3);
    border-radius: 100%;
    position: absolute;
    z-index: -1;
    background: rgba(236, 1, 98, 0.3);
  }
  .bigtext-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
  }
  @media only screen and (min-width: 770px) {
    .demo-table {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    .demo-table-wrapper {
      padding-top: 180px;
    }
    .bigtext {
      font-size: 60px;
      margin-bottom: 200px;
    }
    .bigtext-wrapper {
      margin-top: 100px;
    }
    .bigtext-glow {
      width: 100px;
    }
  }
</style>
