import { z } from "zod"
import { EnumT } from "./schema/index.ts"
import { CurrencyCode } from "./schema/currencies.ts"
import type { fieldInfoMap } from "./data/defaults.ts"

export type Exact<A, B> = A extends B ? (B extends A ? A : never) : never
export type PartialRecord<K extends string | number | symbol, V> = Partial<
  Record<K, V>
>
export type Optional<T> = T | null
export type Nully<T> = Optional<T> | undefined
export type ValueOf<T> = T[keyof T]
export type ElementOf<T extends unknown[]> = T[number]
export type RecursivePartial<T> =
  | {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P]
    }

export const ZExtractorKey = z.object({
  sourceKey: z.string(),
  source: z.nativeEnum(EnumT.Source),
})

export type ExtractorKey = z.infer<typeof ZExtractorKey>

export const ZJsonPrimitive = z.union([
  z.boolean(),
  z.number(),
  z.string(),
  z.null(),
])

export const ZJsonValue: z.ZodType<JsonValue> = z.lazy(() =>
  z.union([ZJsonPrimitive, ZJsonArray, ZJsonObject])
)

export const ZJsonArray = z.array(ZJsonValue)

const ZJsonObject = z.record(ZJsonValue)

export const ZJsonData = z.object({
  data: ZJsonValue,
})

export const ZGenericDataValue = z.union([
  z.number(),
  z.string(),
  z.boolean(),
  z.array(z.string()),
  z.array(z.number()),
  ZJsonData,
])

export const ZBaseData = z.object({
  id: z.string().uuid(),
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export const ZExtractedData = ZBaseData.extend({
  source: z.nativeEnum(EnumT.Source),
  field: z.nativeEnum(EnumT.Field),
  sourceKey: z.string(),
  value: z.optional(ZGenericDataValue),
})

export type ExtractedData = z.infer<typeof ZExtractedData>

export const ZFormatSpec = z.union([
  z.object({
    type: z.literal(EnumT.FormatSpecType.currency),
    currencyCode: z.nativeEnum(CurrencyCode),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.images),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.checkbox),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.none),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.text),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.number),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.datetime),
    includeTime: z.boolean(),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.source),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.rating),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.duration),
    minimumUnits: z.optional(z.nativeEnum(EnumT.DurationUnits)),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.phone),
  }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.longText),
  }),
  z.object({ type: z.literal(EnumT.FormatSpecType.locationDetails) }),
  z.object({ type: z.literal(EnumT.FormatSpecType.commuteDetails) }),
  z.object({ type: z.literal(EnumT.FormatSpecType.nearbyPlaces) }),
  z.object({
    type: z.literal(EnumT.FormatSpecType.slider),
    min: z.number(),
    max: z.number(),
    step: z.number().optional(),
    round: z.boolean().optional(),
    units: z.string().optional(),
  }),
  z.object({ type: z.literal(EnumT.FormatSpecType.markdown) }),
])
export type FormatSpec = z.infer<typeof ZFormatSpec>

export const ZSheet = ZBaseData.extend({
  name: z.string(),
  listingType: z.nativeEnum(EnumT.ListingType).nullable(),
  defaultAccessType: z.nativeEnum(EnumT.AccessType),
})

export type Sheet = z.infer<typeof ZSheet>

export const ZSheetColumn = ZBaseData.extend({
  sheetId: z.string().uuid(),
  name: z.string(),
  columnDefinitionId: z.string().uuid(),
  sortedOrder: z.number(),
  hidden: z.optional(z.boolean()),
})

export type SheetColumn = z.infer<typeof ZSheetColumn>

export const ZSheetRow = ZBaseData.extend({
  sheetId: z.string().uuid(),
  source: z.nativeEnum(EnumT.Source),
  sourceKey: z.string(),
  originalInput: z.nullable(z.string()),
  sortedOrder: z.number(),
})

export type SheetRow = z.infer<typeof ZSheetRow>

export const ZColumnDefinition = ZBaseData.extend({
  formatSpec: ZFormatSpec,
  dataType: z.nativeEnum(EnumT.DataType),
  field: z.nullable(z.nativeEnum(EnumT.Field)),
  generatorSpec: z.nullable(z.string()),
})

export type ColumnDefinition = z.infer<typeof ZColumnDefinition>

export const ZFieldSettings = z.object({
  refName: z.nativeEnum(EnumT.Field),
  defaultColumnBehavior: z.nativeEnum(EnumT.DefaultColumnBehavior),
  sortedOrder: z.number(),
})

export type FieldSettings = z.infer<typeof ZFieldSettings>

export const ZCell = ZBaseData.extend({
  sheetRowId: z.string().uuid(),
  sheetColumnId: z.string().uuid(),
  value: z.optional(ZGenericDataValue),
})

export type Cell = z.infer<typeof ZCell>

export const ZSheetGlobal = ZBaseData.extend({
  sheetId: z.string().uuid(),
  columnDefinitionId: z.string().uuid(),
  varName: z.string(),
  value: z.optional(ZGenericDataValue),
  columnScoped: z.nullable(z.boolean()),
})

export type SheetGlobal = z.infer<typeof ZSheetGlobal>

export const ZSpecDefinition = z.object({
  spec: z.string(),
})

export const ZExplicitGroup = z.object({
  name: z.string(),
  predicate: ZSpecDefinition,
})

export type ExplicitGroup = z.infer<typeof ZExplicitGroup>

export enum GroupingType {
  explicit = "explicit",
  columns = "columns",
}

const ZGroupingBase = z.object({ type: z.nativeEnum(GroupingType) })

export const ZGroupingExplicit = ZGroupingBase.extend({
  type: z.literal(GroupingType.explicit),
  groups: z.array(ZExplicitGroup),
})

export type GroupingExplicit = z.infer<typeof ZGroupingExplicit>

export const ZGroupingByColumns = ZGroupingBase.extend({
  type: z.literal(GroupingType.columns),
  columnIds: z.array(z.string()),
})

export type GroupingByColumns = z.infer<typeof ZGroupingByColumns>

export const ZGroupingDefinition = z.union([
  ZGroupingExplicit,
  ZGroupingByColumns,
])

export type GroupingDefinition = z.infer<typeof ZGroupingDefinition>

export type SpecDefinition = z.infer<typeof ZSpecDefinition>

export const ZSheetView = ZBaseData.extend({
  sheetId: z.string().uuid(),
  filters: z.array(ZSpecDefinition).nullable(),
  grouping: ZGroupingDefinition.nullable(),
  userAccountId: z.string().uuid(),
})

export type SheetView = z.infer<typeof ZSheetView>

export const ZSheetContent = z.object({
  extractedData: z.array(ZExtractedData),
  sheet: ZSheet,
  columns: z.array(ZSheetColumn),
  rows: z.array(ZSheetRow),
  columnDefinitions: z.array(ZColumnDefinition),
  fields: z.array(ZFieldSettings),
  cells: z.array(ZCell),
  sheetGlobals: z.array(ZSheetGlobal),
  views: z.array(ZSheetView),
})
export type SheetContent = z.infer<typeof ZSheetContent>

export const ZFunctionCall = z.object({
  funcName: z.string(),
  args: z.array(z.optional(ZGenericDataValue.nullable())),
})

export type FunctionCall = z.infer<typeof ZFunctionCall>

export const ZCachedFuncCall = ZBaseData.extend({
  value: z.optional(ZGenericDataValue).nullable(),
  serializedFuncCall: ZFunctionCall,
})

export type CachedFuncCall = z.infer<typeof ZCachedFuncCall>

export type JsonDataType<T extends JsonValue = JsonValue> = {
  data: T
}

export const ZLatLonObj = z.object({ lat: z.number(), lon: z.number() })

export type LocationDetails = {
  title: string | undefined
  address:
    | {
        streetAddress: string
        city: string
        state: string
        zip: number
      }
    | undefined
  latitude: number
  longitude: number
}

export type MarkdownDetails = {
  markdown: string
}

export type CommuteDetails = {
  origin: LocationDetails
  destination: LocationDetails
  transitMode: string
  route: RouteData
}

export type NearbyPlaces = {
  origin: LocationDetails
  searchRadiusMi: number
  places: LocationDetails[]
}

export type DataTypeMap = {
  [EnumT.DataType.number]: number
  [EnumT.DataType.text]: string
  [EnumT.DataType.boolean]: boolean
  [EnumT.DataType.textArray]: string[]
  [EnumT.DataType.numberArray]: number[]
  [EnumT.DataType.json]: JsonDataType
}

export const ZTransitMode = z.union([
  z.literal("walking"),
  z.literal("bicycling"),
  z.literal("driving"),
  z.literal("transit"),
])

export type TransitMode = z.infer<typeof ZTransitMode>

export type GenericDataValue<T extends keyof DataTypeMap = EnumT.DataType> =
  DataTypeMap[T]

export type ParsedSheetContent = Omit<
  SheetContent,
  "columnDefinitions" | "extractedData" | "cells" | "sheetGlobals"
> & {
  cells: (Omit<ElementOf<SheetContent["cells"]>, "value"> & {
    value: GenericDataValue
  })[]
  extractedData: (Omit<ElementOf<SheetContent["extractedData"]>, "value"> & {
    value: GenericDataValue
  })[]
  columnDefinitions: (Omit<
    ElementOf<SheetContent["columnDefinitions"]>,
    "formatSpec"
  > & {
    formatSpec: FormatSpec
  })[]
  sheetGlobals: (Omit<ElementOf<SheetContent["sheetGlobals"]>, "value"> & {
    value: Optional<GenericDataValue>
  })[]
}

export type ParsedSheetRow = ElementOf<ParsedSheetContent["rows"]>

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export type SortInfo = { sortDirection: SortDirection; sortingOnColId: string }

export type ProcessedSheetContent = ParsedSheetContent & {
  cellValueLookup: PartialRecord<string, CellValue | undefined>
  filteredRowIds: Set<string>
}

export type FieldValueLookup = {
  [F in EnumT.Field]: GenericDataValue<FieldDataTypeMap[F]>
}
export type PartialFieldValueLookup = Partial<FieldValueLookup>

export type CellKey = {
  rowId: string
  colId: string
}

export type FieldDataTypeMap = {
  [K in keyof typeof fieldInfoMap]: (typeof fieldInfoMap)[K]["dataType"]
}

export type FieldFormatSpecTypeMap = {
  [K in keyof typeof fieldInfoMap]: (typeof fieldInfoMap)[K]["formatSpec"]["type"]
}

export type SafeFieldDataType<FT extends EnumT.Field> =
  | GenericDataValue<FieldDataTypeMap[FT]>
  | undefined

export interface NodeBase<KT extends string | number | symbol> {
  id: KT
}

export interface ReferenceBase<KT extends string | number | symbol> {
  to: KT
}

export type Edges<
  Reference extends ReferenceBase<KT>,
  KT extends string | number | symbol
> = PartialRecord<KT, Reference[]>

export type FunctionCallWithContext = {
  funcCall: FunctionCall
  cellKey: CellKey
}

export type FunctionResolver = (
  funcCall: FunctionCall
) => GenericDataValue | undefined

export type FunctionResolverWithContext = (
  funcCallInfo: FunctionCallWithContext
) => GenericDataValue | undefined

export type EvaluationContext = {
  resolveFunction: FunctionResolver
  lookupRef(reference: {
    namespace: EnumT.ReferenceNamespace
    identifier: string
  }): GenericDataValue | undefined
}

export type FunctionArgument = {
  dataType: EnumT.DataType
  formatSpec?: FormatSpec
  nullable: boolean
  label?: string
  fixedList?: { value: string; label: string }[]
  fieldHint?: EnumT.Field
  variableHint?: string
  variableName?: string
  variadric?: boolean
  // If hidden, fieldHint should be supplied
  hidden?: boolean
}

export type FunctionDefinition<
  FT extends EnumT.FormatSpecType,
  DT extends EnumT.DataType
> = {
  args: FunctionArgument[]
  name: string
  dataType: DT
  formatSpec: {
    type: FT
  } & FormatSpec
} & (
  | {
      async: true
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { async: false; handler: (...args: any[]) => GenericDataValue | undefined }
)

export type CellValue = {
  extractedData?: GenericDataValue
  userInputData?: GenericDataValue
  calculatedData?: GenericDataValue
}

export type Json = JsonValue

export type JsonArray = JsonValue[]

export type JsonObject = {
  [K in string]?: JsonValue
}

export type JsonPrimitive = boolean | number | string | null

export type JsonValue = JsonArray | JsonObject | JsonPrimitive

export const ZGeoJsonData = z.object({
  features: ZJsonArray,
  properties: ZJsonObject,
  type: z.string(),
})

export const ZRouteData = z.object({
  coordinates: z.array(z.array(ZLatLonObj)),
  durationSeconds: z.number(),
})

export type RouteData = z.infer<typeof ZRouteData>
