import type {
  GenericDataValue,
  JsonDataType,
  CommuteDetails,
} from "../../types"

export type CommuteDetailsData = JsonDataType<CommuteDetails>

export function assertCommuteDetails(
  v: GenericDataValue | undefined
): asserts v is CommuteDetailsData {
  if ((v as CommuteDetailsData).data.route == null) {
    throw `invalid commute details data ${v?.toString()}`
  }
}
