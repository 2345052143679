<div class="wrapper">
  <div class="content">
    <slot />
  </div>
  <div class="footer">
    <slot name="footer" />
  </div>
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;
    padding: 16px;
  }
  .footer {
    margin-top: 16px;
  }
</style>
