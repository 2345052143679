<script lang="ts">
  import MapPopup from "./MapPopup.svelte"
  import type { Location } from "@shared/util"

  export let title: string
  export let location: Location

  $: googleLink = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}`
</script>

{#if title}
  <MapPopup to={googleLink} {title} />
{/if}
