import { assertDefined } from "@shared/util/index.ts"
import { Loader } from "@googlemaps/js-api-loader"
import { type LatLonObj, Location } from "@shared/util/location"
import type {
  Optional,
  ProcessedSheetContent,
  RouteData,
} from "@shared/types.ts"
import { getParsedValueByField } from "@shared/sheet"
import type { SvelteComponent } from "svelte"
import { EnumT } from "@shared/schema"
import type { LocationDetailsData } from "@shared/data/json/location"
const DEFAULT_LOCATION = { lat: 45, lon: -74 }

export type MapRoute = {
  route: RouteData
}
export enum WaypointDisplayMode {
  markerPrimary = "markerPrimary",
  markerSecondary = "markerSecondary",
  dotSecondary = "dotSecondary",
}
export interface MapContext {
  placeholder?: null
  panTo: (loc: Location) => void
  centerOnContent: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPopup: <P extends Record<string, any>>(args: {
    component: typeof SvelteComponent<P>
    props: P
    waypointId: string
  }) => void
  getCenter: () => Location
}
export interface WaypointClickContext extends MapContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWaypointPopup: <PT extends Record<string, any>>(
    component: typeof SvelteComponent<PT>,
    props: PT
  ) => void
}
export type Waypoint = {
  title: string | undefined
  location: Location
  displayMode: WaypointDisplayMode
  onClick?: (event: MouseEvent, context: WaypointClickContext) => void
  waypointId: string
}

export const getPreferredStyle = (darkMode: boolean) => {
  if (darkMode) {
    return `https://api.maptiler.com/maps/streets-v2-dark/style.json?key=${
      import.meta.env.VITE_APP_MAPTILER_API_KEY as string
    }`
  }
  return `https://api.maptiler.com/maps/streets-v2/style.json?key=${
    import.meta.env.VITE_APP_MAPTILER_API_KEY as string
  }`
}

export const getCenter = (positions: LatLonObj[]) => {
  const coords = positions.reduce(
    ({ lat: pLat, lon: pLon }, { lat: nLat, lon: nLon }) => ({
      lat: pLat + nLat,
      lon: pLon + nLon,
    }),
    { lat: 0, lon: 0 }
  )
  return positions.length > 0
    ? {
        lat: coords.lat / positions.length,
        lon: coords.lon / positions.length,
      }
    : DEFAULT_LOCATION
}

const loader = new Loader({
  apiKey: assertDefined(
    import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY as string | undefined
  ),
  version: "weekly",
  libraries: ["places"],
})

let placesApi: Optional<google.maps.PlacesLibrary> = null
const useMapPlacesApi = async () => {
  if (placesApi) {
    return placesApi
  }
  placesApi = await loader.importLibrary("places")

  return placesApi
}

let geocodingApi: Optional<google.maps.GeocodingLibrary> = null
const useMapGeocodingApi = async () => {
  if (geocodingApi) {
    return geocodingApi
  }
  geocodingApi = await loader.importLibrary("geocoding")

  return geocodingApi
}

let autoCompleteService: Optional<google.maps.places.AutocompleteService> = null
export const useAutocompletePlacesService = async () => {
  if (autoCompleteService) {
    return autoCompleteService
  }
  const { AutocompleteService } = await useMapPlacesApi()
  autoCompleteService = new AutocompleteService()
  return autoCompleteService
}

let geocoderService: Optional<google.maps.Geocoder> = null
export const useGeocoderService = async () => {
  if (geocoderService) {
    return geocoderService
  }
  const { Geocoder } = await useMapGeocodingApi()
  geocoderService = new Geocoder()
  return geocoderService
}

export const buildSheetWaypoints = ({
  sheetContent,
  onClick,
}: {
  sheetContent: ProcessedSheetContent
  onClick?: (rowId: string) => void
}) => {
  return sheetContent.rows.flatMap(({ id: rowId }): Waypoint[] => {
    const locationData =
      (getParsedValueByField<EnumT.Field.location>({
        sheetContent: sheetContent,
        rowId,
        field: EnumT.Field.location,
      }) as Undefined<LocationDetailsData>) ?? undefined
    if (locationData != null) {
      return [
        {
          waypointId: rowId,
          title: locationData?.data.title,
          location: new Location(locationData.data),
          displayMode: WaypointDisplayMode.markerPrimary,
          onClick: onClick
            ? function (_event, _context) {
                onClick(rowId)
              }
            : undefined,
        },
      ]
    }
    return []
  })
}
