import { safeParseFloat } from "."
import type { LocationDetails, RouteData } from "../types"

export type LatLonObj = { lat: number; lon: number }
export type LatLonTuple = [number, number]

function isLatLonObj(v: LatLonObj | LocationDetails): v is LatLonObj {
  return (v as LatLonObj).lat !== undefined
}

export class Location {
  lat: number
  lon: number

  constructor(input?: LatLonObj | LatLonTuple | LocationDetails) {
    if (!input) {
      this.lat = 0
      this.lon = 0
    } else if (Array.isArray(input)) {
      this.lat = input[0]
      this.lon = input[1]
    } else if (isLatLonObj(input)) {
      const { lat, lon } = input
      this.lat = lat
      this.lon = lon
    } else {
      this.lat = input.latitude
      this.lon = input.longitude
    }
  }

  public nonZero = () => !!(this.lat && this.lon)
  public toTuple = (): LatLonTuple => [this.lat, this.lon]
  public toObj = () => ({ lat: this.lat, lon: this.lon })
  public equals = (other: Location) =>
    this.lat === other.lat && this.lon === other.lon
}

const safeCreateLocationFromStringInner = (
  locString: string,
  separator: string | RegExp
) => {
  const separated = locString.trim().split(separator)
  if (separated.length !== 2) {
    return null
  }
  const lat = safeParseFloat(separated[0].trim())
  const lon = safeParseFloat(separated[1].trim())
  if (lat == null || lon == null) {
    return null
  }
  return new Location({ lat, lon })
}

export const safeCreateLocationFromString = (locString: string) => {
  return (
    safeCreateLocationFromStringInner(locString, ";") ??
    safeCreateLocationFromStringInner(locString, ",")
  )
}

export function routeToGeoJSON(route: RouteData): GeoJSON.Feature {
  return {
    type: "Feature",
    geometry: {
      type: "MultiLineString",
      coordinates: route.coordinates.map((coordinateList) =>
        coordinateList.map(({ lon, lat }) => [lon, lat])
      ),
    },
    properties: [],
  }
}
