<script lang="ts">
  import ExpandedView from "@/lib/ExpandedView.svelte"
  import FloatingActionButton from "@/lib/FloatingActionButton.svelte"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { itemPageRoute } from "@/routes"
  import {
    activeSheetId,
    filteredSheetContentFactory,
    hasWriteAccess,
  } from "@/stores/sheetStore"
  import { faPlus } from "@fortawesome/free-solid-svg-icons"
  import { newTypedObject } from "@shared/util/index.ts"
  import type { DataViewContext } from "./types.ts"
  import { buildFieldValueLookup } from "@shared/sheet/index.ts"
  import FixedRelativeAnchor from "@/lib/layout/FixedRelativeAnchor.svelte"
  import FixedLayoutVertical from "@/lib/layout/FixedLayoutVertical.svelte"
  import SpacingWrapper from "@/lib/layout/SpacingWrapper.svelte"

  export let query: URLSearchParams | null
  export let context: DataViewContext

  const cardComponents = newTypedObject<string, { flash: () => void }>()

  $: {
    const itemId = query?.get("itemId")
    if (itemId) {
      cardComponents[itemId]?.flash()
    }
  }

  $: hasWrite = hasWriteAccess({ sheetId: $activeSheetId ?? undefined })

  $: sheetContent = $activeSheetId
    ? $filteredSheetContentFactory($activeSheetId)
    : null
</script>

{#if sheetContent}
  <FixedRelativeAnchor>
    <FixedLayoutVertical>
      <FloatingActionButton
        disabled={!$hasWrite}
        on:click={context.openNewItemSidepanel}
        icon={faPlus}
        label="New item"
      />
      {#each sheetContent.rows as row}
        <SpacingWrapper size="xl">
          <div class="expanded-wrapper">
            <RouterLink
              to={itemPageRoute({
                itemId: row.id,
                sheetId: sheetContent.sheet.id,
              })}
            >
              <ExpandedView
                allDetails
                {sheetContent}
                rowId={row.id}
                pageOptions={["map", "details"]}
                bind:this={cardComponents[row.id]}
                fieldValueLookup={buildFieldValueLookup({
                  sheetContent: sheetContent,
                  rowId: row.id,
                })}
              />
            </RouterLink>
          </div>
        </SpacingWrapper>
      {/each}
    </FixedLayoutVertical>
  </FixedRelativeAnchor>
{/if}

<style>
  .expanded-wrapper {
    background-color: var(--primary-accent);
    transition: background-color 0.25s ease-in-out;
    border-radius: 10px;
    outline: 4px solid var(--primary-accent-light);
  }
</style>
