<script lang="ts">
  import { darkMode } from "@/stores/styleStore"
  import { tick } from "svelte"

  let resetting = false

  darkMode.subscribe(async () => {
    resetting = true
    await tick()
    resetting = false
  })
</script>

{#if !resetting}
  <slot />
{/if}
