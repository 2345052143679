<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import Icon from "../Icon.svelte"
  import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
  import type { TableCellMetadata } from "./types"
  import Popover from "../Popover.svelte"
  import CellMetadata from "../CellMetadata.svelte"

  export let active: boolean
  export let editing: boolean
  export let editOnly: boolean = false
  export let inline: boolean | undefined = undefined
  export let wrapperElem: HTMLElement | undefined
  export let metadata: TableCellMetadata | undefined
  export let keyboardActive: boolean

  let showPopover: boolean = false

  const dispatch = createEventDispatcher<{
    action: undefined
    focused: undefined
    keypress: KeyboardEvent
  }>()

  const doAction = (_: Event) => dispatch("action")
  const doFocused = () => dispatch("focused")
  const doOnKeypress = (e: KeyboardEvent) => {
    if (e.target === wrapperElem) {
      dispatch("keypress", e)
    }
  }

  $: if (active && wrapperElem && (!editing || editOnly)) {
    wrapperElem.focus()
  }

  const closeDetails = () => (showPopover = false)
  const toggleDetails = () => (showPopover = !showPopover)
  const ellipsisClicked = () => {
    toggleDetails()
  }

  $: if (!active) {
    closeDetails()
  }
</script>

<div
  bind:this={wrapperElem}
  class="editable-wrapper"
  class:inline={inline || editing || editOnly}
  class:focused={active && keyboardActive}
  role="button"
  tabindex="0"
  on:click={doAction}
  on:keypress={doOnKeypress}
  on:focus={doFocused}
>
  <slot />{#if metadata?.cellValue?.userInputData}<div
      on:click|stopPropagation={ellipsisClicked}
      class="open-details-wrapper"
      on:keypress={doOnKeypress}
      class:focused={active}
      class:show-popover={showPopover}
    >
      <div class="open-details">
        <Icon icon={faEllipsis} /><Popover bind:active={showPopover}
          ><CellMetadata bind:showPopover {metadata} /></Popover
        >
      </div>
    </div>
  {/if}
</div>

<style>
  .editable-wrapper {
    border: 4px solid rgba(0, 0, 0, 0);
    transition: border 0.08s ease-in-out;
    cursor: pointer;
    padding: 3px;
    min-height: 20px;
    border-radius: 2px;
    position: relative;
    max-width: 300px;
  }
  .open-details-wrapper {
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transform: translateY(100%);
    pointer-events: none;
    z-index: 100;
    transition: opacity 0.08s ease-in-out;
    opacity: 0;
  }
  .open-details {
    background-color: var(--action-alt);
    padding-left: 4px;
    padding-right: 4px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    position: relative;
    margin-right: -4px;
    float: right;
  }
  .open-details-wrapper.focused,
  .editable-wrapper:hover .open-details-wrapper {
    pointer-events: all;
    opacity: 1;
  }
  .open-details-wrapper.show-popover {
    z-index: 9999;
  }
  .open-details.focused:hover {
    filter: brightness(90%);
  }
  .inline {
    display: inline-block;
    vertical-align: middle;
    width: unset;
  }
  .editable-wrapper.focused,
  .editable-wrapper:hover {
    border: 4px solid var(--action-alt);
    outline: none;
  }
</style>
