<script lang="ts">
  import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher } from "svelte"

  import Dropdown from "../Dropdown.svelte"
  import DropdownItem from "../DropdownItem.svelte"
  import EditableCellWrapper from "./EditableCellWrapper.svelte"
  import type { RowAction } from "./types.ts"
  import { eed, keyPressWrapper } from "@/util"
  import DataTableTd from "./DataTableTd.svelte"
  import { ACTIONS_AXIS_ID } from "./constants.ts"

  export let active: boolean
  export let editing: boolean
  export let rowActions: RowAction[]
  export let rowId: string
  export let keyboardActive: boolean

  let wrapperElem: HTMLElement
  let menuOpen = false

  const dispatch = createEventDispatcher<{
    requestFocus: undefined
    requestEdit: undefined
  }>()

  const openActionsDropdown = () => {
    menuOpen = !menuOpen
  }

  const requestFocus = () => dispatch("requestFocus")
  const requestEdit = () => dispatch("requestEdit")

  const doClose = () => {
    requestFocus()
    menuOpen = false
  }

  const wrapperKeypress = (e: KeyboardEvent) =>
    keyPressWrapper(openActionsDropdown)(e)

  $: if (!active) {
    menuOpen = false
  }

  const handler = (rowAction: RowAction) => {
    rowAction.handler({ rowId })
  }
</script>

<DataTableTd {rowId} colId={ACTIONS_AXIS_ID}>
  <EditableCellWrapper
    bind:wrapperElem
    {active}
    {editing}
    {keyboardActive}
    editOnly
    metadata={undefined}
    inline
    on:focused
    on:action={openActionsDropdown}
    on:keypress={eed(wrapperKeypress)}
  >
    <Dropdown
      nofocus
      icon={faEllipsis}
      bind:menuOpen
      on:open={requestEdit}
      on:close={doClose}
    >
      {#each rowActions as rowAction}
        <DropdownItem
          variant={rowAction.variant ?? "default"}
          disabled={rowAction.disabled ?? false}
          on:click={() => handler(rowAction)}
          icon={rowAction.icon}>{rowAction.label}</DropdownItem
        >
      {/each}
    </Dropdown>
  </EditableCellWrapper>
</DataTableTd>
