<script lang="ts">
  import { getColumnDefinitionForCol } from "@shared/sheet"
  import type { ProcessedSheetContent } from "@shared/types"
  import { assertDefined } from "@shared/util"
  import Icon from "./Icon.svelte"
  import { fieldToIconMap } from "@/constants"
  import { fieldInfoMap } from "@shared/data/defaults"

  export let colId: string
  export let sheetContent: ProcessedSheetContent

  $: column = assertDefined(
    sheetContent.columns.find((col) => col.id === colId)
  )
  $: colDef = assertDefined(getColumnDefinitionForCol({ sheetContent, colId }))
</script>

{#if colDef.field}
  <span>
    <Icon icon={fieldToIconMap[colDef.field]} />&nbsp;{fieldInfoMap[
      colDef.field
    ].name}
  </span>
{:else}
  Field: {column.name}
{/if}
