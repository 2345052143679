<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { EnumT } from "@shared/schema"
  import Input from "@/lib/Input.svelte"
  import { eed } from "@/util"
  import SingleSelect from "@/lib/controls/SingleSelect.svelte"
  import {
    newTypedArray,
    objectValues,
    setIntersectionImmutable,
  } from "@shared/util"
  import type { OptionT } from "@/types"
  import SourceCardOption from "@/lib/SourceCardOption.svelte"
  import { sourceInfoMap } from "@shared/data/defaults"
  import ComboBox from "@/lib/controls/ComboBox.svelte"

  export let value: GenericDataValue<EnumT.DataType.text> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export let autofocus: boolean = false
  export let disabled: boolean
  export let clearable: boolean

  const selectOption = (val: unknown) => {
    dataFormatOverrides[DataFormatOverrideType.optionSettings]?.onSelectOption(
      val
    )
  }

  $: sourceOptions = ((): OptionT<EnumT.Source>[] => {
    const allSources = new Set(objectValues(EnumT.Source))
    const passedOptions =
      dataFormatOverrides[DataFormatOverrideType.optionSettings]?.options
    const passedSources = passedOptions
      ? new Set<EnumT.Source>(passedOptions.map((opt) => opt.value.value))
      : undefined
    const availSources = passedSources
      ? setIntersectionImmutable(allSources, passedSources)
      : allSources

    const options = newTypedArray<OptionT<EnumT.Source>>()
    for (const source of availSources) {
      options.push({
        value: source,
        label: sourceInfoMap[source].name,
        component: SourceCardOption,
      })
    }
    return options
  })()

  $: suggestions =
    dataFormatOverrides[DataFormatOverrideType.optionSettings]?.options
</script>

{#if formatSpec.type === EnumT.FormatSpecType.source}
  <SingleSelect
    placeholder="Select a Source"
    options={sourceOptions}
    bind:value
    hideLabel
    {clearable}
  />
{:else if suggestions != null && suggestions.length > 0}
  <ComboBox
    bind:query={value}
    options={suggestions}
    placeholder=""
    onSelect={selectOption}
    {disabled}
  />
{:else}
  <Input
    type="text"
    on:blur={dataFormatOverrides[DataFormatOverrideType.onBlur]}
    on:keydown={dataFormatOverrides[DataFormatOverrideType.onKeydown]}
    on:selectOption={eed(selectOption)}
    {autofocus}
    bind:value
    fullwidth
    {disabled}
  />
{/if}
