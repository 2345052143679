<script lang="ts">
  import type { Size } from "@/types"
  import { faExternalLink } from "@fortawesome/free-solid-svg-icons"
  import type { EnumT } from "@shared/schema/index.ts"
  import Button from "./Button.svelte"
  import { sourceInfoMap } from "@shared/data/defaults"

  export let source: EnumT.Source | undefined = undefined
  export let size: Size | undefined = undefined
  export let islink: boolean = false
  export let nofocus: boolean = false
  export let readonly: boolean = false

  $: sourceInfo = source ? sourceInfoMap[source] : undefined
</script>

{#if sourceInfo}
  <Button
    iconRight={islink ? faExternalLink : undefined}
    {size}
    {nofocus}
    {readonly}
    --background-color={sourceInfo.bg}
    --color="white"
    variant="none">{sourceInfo.name}</Button
  >
{/if}
