import type { OptionT } from "@/types"
import type { GenericDataValue } from "@shared/types"

export enum DataFormatOverrideType {
  viewLocationLink = "viewLocationLink",
  sourceUrl = "sourceUrl",
  nofocus = "nofocus",
  onBlur = "onBlur",
  onKeydown = "onKeydown",
  commitChange = "commitChange",
  stopPropagation = "stopPropagation",
  optionSettings = "optionSettings",
}

export type DataFormatOptionSettings<OT> = {
  onSelectOption: (value: OT | null) => void
  options: OptionT<OT>[]
}

export type DataFormatOverrideDefinitions = {
  [DataFormatOverrideType.viewLocationLink]: string
  [DataFormatOverrideType.sourceUrl]: string
  [DataFormatOverrideType.nofocus]: boolean
  [DataFormatOverrideType.onBlur]: () => void
  [DataFormatOverrideType.onKeydown]: (e: KeyboardEvent) => void
  [DataFormatOverrideType.commitChange]: (
    v: GenericDataValue | undefined
  ) => void
  [DataFormatOverrideType.stopPropagation]: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [DataFormatOverrideType.optionSettings]: DataFormatOptionSettings<any>
}

export type DataFormatOverrides = Partial<DataFormatOverrideDefinitions>

export enum DataFormatEventTypes {
  primaryAction = "primaryAction",
}

export type DataFormatEventDefinitions = {
  [DataFormatEventTypes.primaryAction]: undefined
}

export type DataFormatEvent<
  DFET extends DataFormatEventTypes = DataFormatEventTypes
> = { type: DFET; payload: DataFormatEventDefinitions[DFET] }
