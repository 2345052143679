<script lang="ts">
  import { ParsedItemType, type ParsedItem } from "@shared/lispable/parsing"
  import { serialize, tryToParse } from "@shared/lispable/script"
  import type {
    FunctionDefinition,
    GenericDataValue,
    ProcessedSheetContent,
  } from "@shared/types.ts"
  import type { OpenAddVariableModal } from "./types.ts"
  import { builtins } from "@shared/lispable/functions.ts"
  import {
    assertDefined,
    newTypedArray,
    newTypedObject,
    objectEntries,
  } from "@shared/util/index.ts"
  import { ReferenceNamespace } from "@shared/schema/enum.ts"
  import FunctionArgumentControl from "./FunctionArgumentControl.svelte"
  import type { EnumT } from "@shared/schema/index.ts"

  export let funcName: string
  export let sheetContent: ProcessedSheetContent
  export let value: string | undefined
  export let columnId: string | undefined
  export let openAddVariableModal: OpenAddVariableModal
  export let validated: boolean = false

  let specifiedArguments = newTypedArray<ParsedItem>()
  let variableIndexToId = newTypedObject<number, string>()

  $: premiumFunc = assertDefined(builtins[funcName as keyof typeof builtins])

  const updateLocals = (
    v: string,
    pf: FunctionDefinition<EnumT.FormatSpecType, EnumT.DataType>
  ) => {
    const parsed = tryToParse(v)
    if (parsed?.type === ParsedItemType.funcCall) {
      parsed.args.forEach((arg, i) => {
        if (
          arg.type === ParsedItemType.reference &&
          arg.namespace === ReferenceNamespace.locals
        ) {
          variableIndexToId[i] = arg.identifier
        } else {
          specifiedArguments[i] = arg
        }
      })
      if (pf.args.length === parsed.args.length) {
        validated = true
      }
    }
  }

  $: if (value && premiumFunc) updateLocals(value, premiumFunc)

  $: {
    const resolvedArguments = newTypedArray<ParsedItem>()
    let incomplete = false
    for (let index = 0; index < premiumFunc.args.length; index++) {
      const funcArg = assertDefined(premiumFunc.args[index])
      if (specifiedArguments[index]) {
        resolvedArguments[index] = specifiedArguments[index]
      } else if (funcArg.fieldHint) {
        resolvedArguments[index] = {
          type: ParsedItemType.reference,
          namespace: ReferenceNamespace.fields,
          identifier: funcArg.fieldHint,
        }
      } else if (funcArg.nullable) {
        resolvedArguments[index] = {
          type: ParsedItemType.nullLiteral,
        }
      } else {
        incomplete = true
      }
    }
    if (!incomplete) {
      value = serialize({
        type: ParsedItemType.funcCall,
        funcName,
        args: resolvedArguments,
      })
      let foundMissingValue = false
      for (let index = 0; index < premiumFunc.args.length; index++) {
        const funcArg = assertDefined(premiumFunc.args[index])
        const resolvedArg = assertDefined(resolvedArguments[index])
        if (
          resolvedArg.type === ParsedItemType.nullLiteral &&
          !funcArg.nullable
        ) {
          foundMissingValue = true
        }
      }
      validated = !foundMissingValue
    } else {
      validated = false
    }
  }

  const onChangeArg = (index: number) => (parsedItem: ParsedItem) =>
    (specifiedArguments[index] = parsedItem)

  $: variablesPopulated = (() => {
    const valueList = newTypedArray<GenericDataValue | undefined>()
    for (const [index, id] of objectEntries(variableIndexToId)) {
      valueList[index] = id
    }
    return valueList
  })()
</script>

<div>
  {#each premiumFunc.args as funcArg, index}
    {#if !funcArg.hidden}
      <div class="func-arg-wrapper">
        <FunctionArgumentControl
          onChange={onChangeArg(index)}
          {funcArg}
          {sheetContent}
          {columnId}
          {openAddVariableModal}
          value={specifiedArguments[index]}
        />
        {#if !variablesPopulated[index]}
          <div class="missing">Required</div>
        {/if}
      </div>
    {/if}
  {/each}
</div>

<style>
  .missing {
    align-self: center;
    color: var(--danger-fg);
    font-size: 14px;
    background: var(--danger-bg);
    padding: 4px;
    border-radius: 6px;
    display: none;
  }
  .func-arg-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
