<script lang="ts">
  import { classNames } from "@/util"
  import type { Side } from "./types.ts"
  import type { StandardSize } from "@/types/index.ts"

  export let axis: "y" | "x" | undefined = undefined
  export let sides: Side[] = ["left", "right", "top", "bottom"]
  export let size: StandardSize = "md"

  $: computedSides = (() => {
    if (axis === "y") {
      return ["top", "bottom"]
    } else if (axis === "x") {
      return ["left", "right"]
    } else {
      return sides
    }
  })()

  let spacingElem: HTMLDivElement | undefined

  $: spacingElem?.style.setProperty("--margin-width", `var(--spacing-${size})`)
</script>

<div bind:this={spacingElem} class={classNames(...computedSides)}><slot /></div>

<style>
  :root {
    --margin-width: var(--spacing-md);
  }
  .left {
    margin-left: var(--margin-width);
  }
  .right {
    margin-right: var(--margin-width);
  }
  .top {
    margin-top: var(--margin-width);
  }
  .bottom {
    margin-bottom: var(--margin-width);
  }
</style>
