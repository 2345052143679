<script lang="ts">
  import { fly } from "svelte/transition"
  import { faCheck } from "@fortawesome/free-solid-svg-icons"
  import Icon from "../Icon.svelte"
  import type { OptionT } from "@/types"
  import type { AnyMeltElement } from "@melt-ui/svelte/internal/helpers"
  import type { OptionMeltElem } from "./types.ts"

  type T = $$Generic
  export let menuElem: AnyMeltElement
  export let optionElem: OptionMeltElem
  export let options: OptionT<T>[]
  export let isSelected: (option: T) => boolean

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $: binder = (opt: OptionT<T>) => $optionElem(opt) as any
</script>

<ul {...$menuElem} use:menuElem transition:fly={{ duration: 150, y: -5 }}>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <div
    class="flex max-h-full flex-col gap-0 overflow-y-auto bg-white px-2 py-2 text-black options-container"
    tabindex="0"
  >
    {#each options as curOption, index (index)}
      <li {...binder(curOption)} use:optionElem class="option-item">
        {#if isSelected(curOption.value)}
          <div>
            <Icon icon={faCheck} />&nbsp;
          </div>
        {/if}
        <div class="pl-4">
          {#if curOption.component}
            <svelte:component
              this={curOption.component}
              value={curOption.value}
            />
          {:else}
            <span class="font-medium">{curOption.label}</span>
          {/if}
        </div>
      </li>
    {:else}
      <li class="option-item">No results found</li>
    {/each}
  </div>
</ul>

<style>
  .option-item {
    position: relative;
    padding: var(--spacing-md);
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
  .option-item[data-highlighted] {
    background: var(--primary-accent);
  }
  .option-item[data-disabled] {
    opacity: 0.7;
  }
  .option-item[data-selected] {
    background: var(--primary-accent);
  }
  .options-container {
    max-height: 100%;
    overflow-y: auto;
  }
  ul,
  .options-container {
    display: flex;
    flex-direction: column;
  }
  ul {
    background: var(--secondary-bg);
    margin: 0;
    padding: 0;
    margin-top: var(--spacing-xs);
    border-radius: var(--default-rounding);
    border: 2px solid var(--primary-accent);
    list-style-type: none;
    max-height: 300px;
    z-index: 1000000;
  }
  .hidden {
    visibility: hidden;
  }
</style>
