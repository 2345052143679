<script lang="ts">
  import type { OptionT, Size } from "@/types"
  import { debounce } from "@/util"
  import { useAutocompletePlacesService, useGeocoderService } from "@/util/map"
  import { Location } from "@shared/util/index.ts"
  import LocationRow from "./LocationRow.svelte"
  import ComboBox from "./controls/ComboBox.svelte"

  export let value: Location | undefined = undefined
  export let size: Size = "default"
  export let searchQuery: string | undefined = undefined

  let options: OptionT<{ placeId: string; types: string[]; label: string }>[] =
    []

  const getSuggestions = async () => {
    if (!searchQuery) {
      return
    }
    const autocompleteService = await useAutocompletePlacesService()
    const { predictions } = await autocompleteService.getPlacePredictions({
      input: searchQuery,
    })
    options = predictions.map((result) => ({
      value: {
        placeId: result.place_id,
        types: result.types,
        label: result.description,
      },
      component: LocationRow,
      label: result.description,
    }))
  }

  const debouncedGetSuggestions = debounce(getSuggestions)

  const selectOption = async ({ placeId }: { placeId: string }) => {
    const geocoderService = await useGeocoderService()
    const { results } = await geocoderService.geocode({ placeId })
    const result = results[0]
    value = new Location({
      lat: result.geometry.location.lat(),
      lon: result.geometry.location.lng(),
    })
    options = []
    searchQuery = result.formatted_address
  }

  $: if (searchQuery != null) {
    debouncedGetSuggestions()
  }
</script>

<ComboBox
  placeholder="1600 Pennsylvania Avenue, Washington, DC"
  fullwidth
  {options}
  {size}
  freeform
  bind:query={searchQuery}
  onSelect={selectOption}
/>
