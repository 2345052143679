import type { AnyMeltElement } from "@melt-ui/svelte/internal/helpers"

export const buildOptionalMelt = <E extends AnyMeltElement>(
  meltElem: E | undefined
) => {
  if (meltElem != null) {
    return meltElem
  }
  return () => {}
}
