<script lang="ts">
  import { classNames, isMobile, keyPressWrapper, useTrapFocus } from "@/util"
  import { faTimes } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher, onDestroy, onMount } from "svelte"
  import { fly } from "svelte/transition"
  import Icon from "../Icon.svelte"

  export let side: "left" | "right" = "right"
  export let title: string

  let sidepanelElem: HTMLDivElement | undefined = undefined
  let releaseFocus: (() => void) | undefined

  const dispatch = createEventDispatcher<{ close: undefined }>()

  const close = () => dispatch("close")

  const onEscape = () => {
    close()
  }

  const trapFocus = () => {
    if (releaseFocus || !sidepanelElem) {
      return
    }
    releaseFocus = useTrapFocus({
      elem: sidepanelElem,
      onEscape,
      nthToFocusFirst: 1,
    }).release
  }

  onMount(trapFocus)

  $: if (sidepanelElem) {
    trapFocus()
  }

  onDestroy(() => {
    releaseFocus?.()
  })

  $: flyOpts = (() => {
    const defaultOpts = { duration: 200 }
    if (isMobile()) {
      return defaultOpts
    }
    if (side === "left") {
      return { ...defaultOpts, x: -100 }
    } else if (side === "right") {
      return { ...defaultOpts, x: 100 }
    }
  })()
</script>

<div class="outside" on:click={close}>
  <div
    transition:fly={flyOpts}
    bind:this={sidepanelElem}
    on:click|stopPropagation
    class={classNames("sidepanel", {
      left: side === "left",
      right: side === "right",
    })}
  >
    <div class="header">
      <h1>{title}</h1>
      <span
        class="close-btn"
        role="button"
        tabindex="0"
        aria-label="close"
        on:click={close}
        on:keydown={keyPressWrapper(close)}><Icon icon={faTimes} /></span
      >
    </div>
    <slot />
  </div>
</div>

<style>
  .outside {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(30, 30, 30, 0.15);
    z-index: 8000;
    backdrop-filter: blur(2px);
    overflow: hidden;
  }
  .sidepanel {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--primary-bg);
    padding: 20px;
    display: flex;
    flex-direction: column;
    outline: 4px solid var(--primary-accent-light);
  }
  @media only screen and (max-width: 769px) {
    .sidepanel {
      left: 0;
      right: 0;
    }
  }
  @media only screen and (min-width: 770px) {
    .sidepanel.left {
      left: 0;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .sidepanel.right {
      right: 0;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    .sidepanel {
      min-width: 350px;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .close-btn {
    align-self: center;
    font-size: 25px;
    cursor: pointer;
  }
</style>
