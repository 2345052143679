<svelte:options immutable={true} />

<script lang="ts">
  import { cellDataFactory, fieldValueFactory } from "@/stores/sheetStore"
  import DataFormatEditor from "./DataFormat/display/DataFormatEditor.svelte"
  import EditableCellWrapper from "./DataTable/EditableCellWrapper.svelte"
  import type {
    ElementOf,
    GenericDataValue,
    ProcessedSheetContent,
  } from "@shared/types"
  import { createEventDispatcher } from "svelte"
  import DataFormatDisplay from "./DataFormat/display/DataFormatDisplay.svelte"
  import { EnumT } from "@shared/schema"
  import {
    DataFormatEventTypes,
    DataFormatOverrideType,
  } from "./DataFormat/display/types"
  import { ArrowKey, ArrowKeys, EscapeKeys } from "@/constants"
  import { debounce, eed, keyPressWrapper } from "@/util"
  import { mapRoute } from "@/routes"

  type EditData = {
    oldVal: GenericDataValue | undefined
    colDef: ElementOf<ProcessedSheetContent["columnDefinitions"]>
  }

  export let columnId: string
  export let rowId: string
  export let sheetId: string
  export let onChangeCB: (newValue: GenericDataValue | undefined) => any
  export let editing: boolean
  export let active: boolean
  export let readonly: boolean = false
  export let keyboardActive: boolean

  let wrapperElem: HTMLDivElement | undefined = undefined
  let editData: EditData | null = null
  let editValue: GenericDataValue | undefined
  let inner:
    | DataFormatDisplay<EnumT.DataType>
    | DataFormatEditor<EnumT.DataType>
    | undefined = undefined

  const dispatch = createEventDispatcher<{
    requestStopEdit: undefined
    requestEdit: undefined | Event
    requestFocus: undefined
  }>()
  $: cellData = $cellDataFactory({ sheetId, columnId, rowId })
  $: urlValueSelector = fieldValueFactory({
    sheetId,
    field: EnumT.Field.url,
    rowId,
  })
  $: urlValue = $urlValueSelector as
    | GenericDataValue<EnumT.DataType.text>
    | undefined
  $: cellKey = { colId: columnId, rowId }
  $: cellValue =
    cellData?.cellValue?.userInputData ??
    cellData?.cellValue?.calculatedData ??
    cellData?.cellValue?.extractedData
  $: metadata = { cellValue: cellData?.cellValue, cellKey }
  $: dataType = cellData?.colDef.dataType
  $: formatSpec = cellData?.colDef.formatSpec
  $: editable =
    !readonly &&
    ((): boolean => {
      if (!formatSpec || !dataType) {
        return false
      }
      switch (dataType) {
        case EnumT.DataType.boolean:
          return true
        case EnumT.DataType.text:
          return ![EnumT.FormatSpecType.source].includes(formatSpec.type)
        case EnumT.DataType.number:
          return true
        case EnumT.DataType.textArray:
          return ![EnumT.FormatSpecType.images].includes(formatSpec.type)
        case EnumT.DataType.numberArray:
          return true
        case EnumT.DataType.json:
          return false
      }
    })()

  const cancelEditing = () => {
    editData = null
    dispatch("requestStopEdit")
  }

  const doOnChange = () => {
    if (editData === null) {
      return
    }
    const dataToSend = editValue
    //const dataToSend = process(editData)
    if (editData?.oldVal === dataToSend) {
      editData = null
      return
    }
    editData = null
    onChangeCB(dataToSend)
  }

  const startEditing = () => {
    if (!cellData) {
      return
    }
    const cellValueData =
      cellData.cellValue?.userInputData ??
      cellData.cellValue?.calculatedData ??
      cellData.cellValue?.extractedData
    if (editData === null) {
      editValue = cellValueData
      editData = {
        oldVal: cellValueData,
        colDef: cellData.colDef,
      }
    }
  }

  $: editOnly =
    formatSpec?.type === EnumT.FormatSpecType.locationDetails ||
    formatSpec?.type === EnumT.FormatSpecType.markdown
  $: if (editing && editable) {
    startEditing()
  }
  $: if (!editing) {
    doOnChange()
  }

  const updateValue = (v: GenericDataValue | undefined) => (editValue = v)
  $: if (editOnly) updateValue(cellValue)

  const doAction = () => {
    inner?.emitEvent({
      type: DataFormatEventTypes.primaryAction,
      payload: undefined,
    })
    dispatch("requestEdit")
  }

  const wrapperKeypress = (event: KeyboardEvent) => {
    keyPressWrapper(doAction)(event)
  }

  const doOnKeydown = (e: KeyboardEvent) => {
    if (
      dataType === EnumT.DataType.text ||
      (dataType === EnumT.DataType.number &&
        (!formatSpec ||
          [
            EnumT.FormatSpecType.number,
            EnumT.FormatSpecType.currency,
            EnumT.FormatSpecType.none,
          ].includes(formatSpec.type)))
    ) {
      // Do special keydown handling for text inputs
      const inputElem = e.currentTarget as HTMLInputElement
      if (ArrowKeys.has(e.key)) {
        if (
          (inputElem.selectionStart === inputElem.value.length &&
            e.key === ArrowKey.right) ||
          (inputElem.selectionStart === 0 && e.key === ArrowKey.left) ||
          e.key === ArrowKey.up ||
          e.key === ArrowKey.down
        ) {
          dispatch("requestStopEdit")
          return
        }
      }
      if (EscapeKeys.has(e.key)) {
        cancelEditing()
        return
      }
      if (e.key === "Enter") {
        doOnChange()
        dispatch("requestStopEdit")
        e.stopImmediatePropagation()
        e.preventDefault()
      }
      e.stopPropagation()
    }
  }

  $: debouncedOnChangeCB = debounce(onChangeCB)

  $: dataFormatOverrides = {
    [DataFormatOverrideType.onKeydown]: doOnKeydown,
    [DataFormatOverrideType.sourceUrl]: urlValue,
    [DataFormatOverrideType.stopPropagation]:
      formatSpec?.type === EnumT.FormatSpecType.images,
    [DataFormatOverrideType.viewLocationLink]:
      formatSpec?.type === EnumT.FormatSpecType.locationDetails
        ? mapRoute({ sheetId, itemId: rowId, selectedColumn: columnId })
        : undefined,
    [DataFormatOverrideType.commitChange]: debouncedOnChangeCB,
  }

  $: inline =
    formatSpec &&
    [EnumT.FormatSpecType.images, EnumT.FormatSpecType.source].includes(
      formatSpec.type
    )
</script>

<EditableCellWrapper
  {active}
  {metadata}
  {inline}
  {editOnly}
  {editing}
  {keyboardActive}
  on:focused={() => dispatch("requestFocus")}
  on:keypress={eed(wrapperKeypress)}
  on:action={doAction}
  bind:wrapperElem
>
  {#if cellData}
    {#if editData && editing && !editOnly}
      <DataFormatEditor
        bind:this={inner}
        bind:value={editValue}
        dataType={editData.colDef.dataType}
        formatSpec={editData.colDef.formatSpec}
        {dataFormatOverrides}
        autofocus
      />
    {:else if editOnly && !readonly}
      {#key editValue}
        <DataFormatEditor
          bind:this={inner}
          bind:value={editValue}
          dataType={cellData.colDef.dataType}
          formatSpec={cellData.colDef.formatSpec}
          {dataFormatOverrides}
        />
      {/key}
    {:else if !cellData.cellValue}
      (No data)
    {:else}
      {#key cellValue}
        <DataFormatDisplay
          value={cellValue}
          dataType={cellData.colDef.dataType}
          formatSpec={cellData.colDef.formatSpec}
          bind:this={inner}
          {dataFormatOverrides}
        />
      {/key}
    {/if}
  {/if}
</EditableCellWrapper>
