<script lang="ts">
  export let createSheet: () => void
</script>

<div class="wrapper">
  <div class="container">
    <h1 id="how-it-works">How it Works</h1>
    <div class="content-row">
      <div class="info-container">
        <p>
          <a href="javascript:void(0)" on:click={createSheet}>Create a list</a>,
          then paste a link to a listing
        </p>
      </div>
      <div class="info-container">
        <img
          class="screenshot"
          src="/add_listing_dark.png"
          alt="screenshot of pasting in a listing URL to Listable"
        />
      </div>
    </div>
    <div class="content-row">
      <div class="info-container">
        <p>
          Listable will gather information about your listings and organize
          everything into a table
        </p>
      </div>
      <div class="info-container">
        <img
          class="screenshot"
          src="/table_dark.png"
          alt="screenshot of pasting in a listing URL to Listable"
        />
      </div>
    </div>
    <div class="content-row">
      <div class="info-container">
        <p>
          Add Points of Interest to visualize your commute and nearby amenities
        </p>
      </div>
      <div class="info-container">
        <img
          class="screenshot"
          src="/poi_dark.png"
          alt="screenshot of pasting in a listing URL to Listable"
        />
      </div>
    </div>
    <div class="content-row">
      <div class="info-container"><p>View everything on a map</p></div>
      <div class="info-container">
        <img
          class="screenshot"
          src="/map_dark.png"
          alt="screenshot of pasting in a listing URL to Listable"
        />
      </div>
    </div>
  </div>
</div>

<style>
  h1 {
    align-self: center;
    padding-top: 20px;
    padding-bottom: 80px;
    font-size: 40px;
  }
  .info-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 25px;
    font-weight: semibold;
    padding: var(--spacing-xl);
    align-self: center;
    max-width: 600px;
    padding: var(--spacing-lg);
    background-color: var(--primary-bg);
    outline: 4px solid var(--primary-accent);
    border-radius: var(--rounding-2x);
  }
  .wrapper {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 1200px;
  }
  .content-row {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-xl);
  }
  .screenshot {
    width: 400px;
    border-radius: 22px;
    border: 8px solid var(--primary-bg);
    outline: 4px solid var(--primary-accent);
  }
  @media only screen and (max-width: 769px) {
    .container {
      width: 100%;
    }
    .content-row {
      flex-direction: column;
      gap: 40px;
      margin-top: 100px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .container {
      width: 100%;
    }
    .screenshot {
      width: 300px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .content-row:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
</style>
