<script lang="ts" context="module">
  export type ImageInfo = {
    src: string
    caption?: string
  }
</script>

<script lang="ts">
  import {
    faChevronLeft,
    faChevronRight,
  } from "@fortawesome/free-solid-svg-icons"
  import { arithMod } from "@shared/util/index.ts"
  import { onDestroy, onMount } from "svelte"
  import Icon from "./Icon.svelte"

  export let images: ImageInfo[]
  export let hidePage: boolean | undefined = undefined
  export let maxHeight: number | undefined = undefined
  export let globalArrowKeys: boolean = false

  let activeIndex: number = 0
  let loaded: boolean = false
  let centerImage: HTMLDivElement | undefined
  let activeImage: ImageInfo | undefined = undefined
  let contentElem: HTMLDivElement | undefined = undefined

  const incIndex = () => activeIndex++
  const decIndex = () => activeIndex--

  const checkArrowKeys = (e: KeyboardEvent) => {
    if (
      !(e.target as HTMLElement | undefined)?.contains(contentElem ?? null) &&
      !globalArrowKeys
    ) {
      return
    }
    let stopPropagation = true
    if (e.key === "ArrowRight") {
      incIndex()
    } else if (e.key === "ArrowLeft") {
      decIndex()
    } else {
      stopPropagation = false
    }
    if (stopPropagation) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  $: actualIndex = arithMod(activeIndex, images.length)
  $: {
    activeImage = images[actualIndex]
    loaded = false
  }

  const loadStart = () => (loaded = false)
  const loadEnd = () => (loaded = true)

  onMount(() => {
    document.addEventListener("keydown", checkArrowKeys)
  })

  onDestroy(() => {
    document.removeEventListener("keydown", checkArrowKeys)
  })
</script>

<div
  class="content"
  bind:this={contentElem}
  on:click|stopPropagation
  on:keydown|stopPropagation
>
  <div class="content-inner">
    {#if images.length > 1}
      <div class="arrow" on:click={decIndex}>
        <Icon icon={faChevronLeft} />
      </div>
    {/if}
    <div
      class="center-image"
      class:loading={!loaded}
      bind:this={centerImage}
      style:max-height={maxHeight ? `${maxHeight}px` : null}
    >
      <img
        class="image"
        on:loadstart={loadStart}
        on:load={loadEnd}
        src={activeImage?.src}
      />
    </div>
    {#if images.length > 1}
      <div class="arrow" on:click={incIndex}>
        <Icon icon={faChevronRight} />
      </div>
    {/if}
  </div>
  {#if !hidePage}
    <div class="page">{actualIndex + 1}/{images.length}</div>
  {/if}
</div>

<style>
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .page {
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    color: white;
  }
  .content-inner {
    display: flex;
    flex-direction: row;
  }
  .center-image {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  .center-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
    background: var(--primary-bg);
  }
  .arrow {
    cursor: pointer;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary-bg);
    font-size: 20px;
    width: 60px;
  }
  .arrow:hover {
    background-color: var(--secondary-bg);
  }
  .image {
    max-width: 100%;
  }
  .loading {
    opacity: 0.6;
  }
</style>
