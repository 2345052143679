<script lang="ts">
  import type { MapContext } from "@/util/map"
  import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
  import type { Location } from "@shared/util/location"
  import Icon from "../Icon.svelte"
  import LocationInput from "../LocationInput.svelte"
  import type { AcceptCallback } from "../modalManager/types.ts"
  import ModalFooter from "./ModalFooter.svelte"
  import PreviewMap from "../PreviewMap.svelte"

  export let initialValue: Location | undefined = undefined
  export let onAccept: AcceptCallback<Location> | undefined
  export let readonly: boolean = false

  let value: Location | undefined = initialValue
  let mapContext: MapContext | undefined

  const acceptModal = () => {
    const center = mapContext!.getCenter()
    onAccept?.(center)
  }

  $: if (value) {
    mapContext?.panTo(value)
  }

  $: acceptDisabled = !mapContext
</script>

{#if !readonly}
  <div class="input-wrapper">
    <LocationInput bind:value />
  </div>
{/if}
<div class="wrapper">
  <PreviewMap bind:mapContext />
  <div class="marker"><Icon icon={faLocationDot} /></div>
</div>
<ModalFooter
  mode={readonly ? "cancelonly" : "default"}
  cancelText={readonly ? "Close" : "Cancel"}
  on:close
  onAccept={acceptModal}
  {acceptDisabled}
/>

<style>
  .wrapper {
    position: relative;
    border-radius: var(--default-rounding);
    overflow: hidden;
  }
  .wrapper {
    width: 100%;
    height: min(80vw, 400px);
  }
  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 40px;
    color: var(--marker-primary-color);
  }
  .input-wrapper {
    display: flex;
    padding-bottom: 8px;
  }
</style>
