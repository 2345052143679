<script lang="ts">
  import type { OptionT, Size } from "@/types"

  import { createCombobox } from "@melt-ui/svelte"
  import Input from "../Input.svelte"
  import SelectableItems from "./SelectableItems.svelte"

  type T = $$Generic
  export let options: OptionT<T>[]
  export let showAll: boolean = false
  export let query: string | undefined = undefined
  export let placeholder: string
  export let fullwidth: boolean = false
  export let size: Size = "default"
  export let onSelect: ((v: T) => void) | undefined = undefined
  export let disabled: boolean = false
  export let freeform: boolean = false

  const {
    elements: { menu, input, option, label },
    states: { open, inputValue, touchedInput, selected },
    helpers: { isSelected },
  } = createCombobox<OptionT<T>>({
    preventScroll: false,
    closeOnOutsideClick: true,
  })

  $: if (!$open && !freeform) {
    $inputValue = $selected?.label ?? ""
  }

  selected.subscribe((v) => v != null && onSelect?.(v.value as T))

  $: if (query) inputValue.set(query)
  inputValue.subscribe((newVal) => (query = newVal))

  $: filteredOptions =
    $touchedInput && (showAll || $inputValue != "")
      ? options.filter((option) =>
          option.label.toUpperCase().includes($inputValue.toUpperCase())
        )
      : []
</script>

<div>
  <!-- svelte-ignore a11y-label-has-associated-control - $label contains the 'for' attribute -->
  <label class="hidden" {...$label} use:label>
    <span>{placeholder}</span>
  </label>

  <div>
    <Input
      {placeholder}
      {size}
      {fullwidth}
      meltElem={input}
      type="text"
      bind:value={$inputValue}
    />
  </div>
</div>
{#if $open && (showAll || filteredOptions.length > 0)}
  <SelectableItems
    options={filteredOptions}
    menuElem={menu}
    optionElem={option}
    isSelected={$isSelected}
  />
{/if}

<style>
  .hidden {
    visibility: hidden;
    height: 0;
    width: 0;
    /* A11y: remove this */
    display: none;
  }
</style>
