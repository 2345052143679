<script lang="ts">
  import type { Size, Variant } from "@/types"
  import type { ChangeEventHandler } from "svelte/elements"

  export let checked: boolean | undefined | null
  export let size: Size = "default"
  export let variant: Variant = "default"
  export let variants: Variant[] = []
  export let label: string | undefined = undefined
  export let labelId: string = crypto.randomUUID()
  export let nofocus: boolean = false
  export let onChange: ((value: boolean) => void) | undefined = undefined
  export let autofocus: boolean = false
  export let disabled: boolean = false

  const inputId = crypto.randomUUID()
  const doOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = !!e.currentTarget.checked
    if (onChange) {
      onChange(val)
    } else {
      checked = val
    }
  }

  let computedVariantClasses: string[] = []

  $: computedVariantClasses = [...variants, variant].map((v) => `variant-${v}`)
</script>

<input
  class={`size-${size} ${computedVariantClasses.join(" ")}`}
  type="checkbox"
  id={inputId}
  {checked}
  {autofocus}
  {disabled}
  on:change={doOnChange}
  aria-labelledby={labelId}
  tabindex={nofocus ? -1 : 0}
/>
{#if label}
  <label id={labelId} for={inputId}>{label}</label>
{/if}

<style>
  input:not(.variant-naked) {
    cursor: pointer;
  }
  input:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  input.variant-default {
    background: var(--bg-secondary);
  }
  input.size-default {
    width: 20px;
    height: 20px;
  }
  * {
    cursor: pointer;
    user-select: none;
  }
</style>
