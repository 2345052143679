import * as Sentry from "@sentry/svelte"
import { DateTime } from "luxon"
import { EnumT } from "@shared/schema/index.ts"
import type { SortDirection } from "@/lib/DataTable/types"
import {
  assertDefined,
  newTypedArray,
  objectEntries,
  stringifyJSONKey,
} from "@shared/util/index.ts"
import { findDefaultColumn } from "@shared/sheet/columns.ts"
import type {
  CellKey,
  ParsedSheetContent,
  ProcessedSheetContent,
} from "@shared/types.ts"
import { buildCellKey, getParsedValueByField } from "@shared/sheet"
import { EscapeKeys, TabKey } from "@/constants"
import { tick } from "svelte"
import type { Focusable } from "@/types"

export const truncText = (text: string, maxLength: number | undefined) => {
  if (!maxLength || maxLength >= text.length) {
    return text
  }
  if (text.length === 1) {
    return text
  }
  return text.slice(0, maxLength - 1).trim() + "…"
}

export enum LocalStorageKey {
  authRedirectUrl = "authRedirectUrl",
  columnSizes = "columnSizes",
  sortDirection = "sortDirection",
  sortingOnColId = "sortingOnColId",
  darkMode = "darkMode",
}

export type LocalStorageValueMap = {
  [LocalStorageKey.authRedirectUrl]: { href: string; timestamp: number }
  [LocalStorageKey.columnSizes]: Partial<Record<string, number>>
  [LocalStorageKey.sortDirection]: SortDirection | undefined
  [LocalStorageKey.sortingOnColId]: string | null
  [LocalStorageKey.darkMode]: boolean | undefined
}

const buildKey = (key: string, scopeKey?: string) =>
  stringifyJSONKey({ key, scopeKey })

export const setItem = <Key extends LocalStorageKey>(
  key: Key,
  value: LocalStorageValueMap[Key],
  scopeKey?: string
) => localStorage.setItem(buildKey(key, scopeKey), JSON.stringify(value))

export const getItem = <Key extends LocalStorageKey>(
  key: Key,
  scopeKey?: string
) => {
  const val = localStorage.getItem(buildKey(key, scopeKey))
  if (!val) {
    return null
  }
  return JSON.parse(val) as LocalStorageValueMap[Key]
}

export const timeAgo = (timestamp: number) =>
  DateTime.fromSeconds(timestamp, { zone: "utc" }).toLocal().toRelative()

export const findDefaultTitleColumn = (
  columns: ParsedSheetContent["columns"],
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
) =>
  findDefaultColumn({
    columns,
    columnDefinitions,
    field: EnumT.Field.title,
    matchByNames: { names: ["name", "title"], dataType: EnumT.DataType.text },
  })

export const findDefaultLocationColumn = (
  columns: ParsedSheetContent["columns"],
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
) =>
  findDefaultColumn({
    columns,
    columnDefinitions,
    field: EnumT.Field.location,
    matchByNames: { names: ["location"], dataType: EnumT.DataType.numberArray },
  })

export const findDefaultUrlColumn = (
  columns: ParsedSheetContent["columns"],
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
) =>
  findDefaultColumn({
    columns,
    columnDefinitions,
    field: EnumT.Field.url,
    matchByNames: { names: ["url", "link"], dataType: EnumT.DataType.text },
  })

export const findDefaultPhotosColumn = (
  columns: ParsedSheetContent["columns"],
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
) =>
  findDefaultColumn({
    columns,
    columnDefinitions,
    field: EnumT.Field.images,
    matchByNames: {
      names: ["photos", "pictures", "images"],
      dataType: EnumT.DataType.textArray,
    },
  })

export const findDefaultSourceColumn = (
  columns: ParsedSheetContent["columns"],
  columnDefinitions: ParsedSheetContent["columnDefinitions"]
) =>
  findDefaultColumn({
    columns,
    columnDefinitions,
    field: EnumT.Field.source,
  })

export const getCellValueForColumn = ({
  sheetContent,
  cellKey,
}: {
  sheetContent: ProcessedSheetContent
  cellKey: CellKey
}) => sheetContent.cellValueLookup[buildCellKey(cellKey)]

export const getColumnIdForField = ({
  columnIds,
  sheetContent,
  field,
}: {
  field: EnumT.Field
  columnIds?: Partial<Record<EnumT.Field, string>>
  sheetContent: ParsedSheetContent
}) => {
  return (
    columnIds?.[field] ??
    findDefaultColumn({
      columns: sheetContent.columns,
      columnDefinitions: sheetContent.columnDefinitions,
      field,
    })
  )
}

const letters = "abcdefghijklmnopqrstuvwxyz"
const numbers = "0123456789-."

export const alphaKeys = new Set([
  ...letters.toLowerCase().split(""),
  ...letters.toUpperCase().split(""),
])

export const numericKeys = new Set(numbers.split(""))

export const otherCommonKeys = new Set([
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "=",
  "+",
  "-",
  "_",
  "[",
  "]",
  "{",
  "}",
  "'",
  '"',
  ";",
  ":",
  ",",
  ".",
  "<",
  ">",
  "?",
  "/",
  "~",
  "`",
  "|",
  "\\",
])

export const isCommonKey = (key: KeyboardEvent["key"]) => {
  return numericKeys.has(key) || alphaKeys.has(key) || otherCommonKeys.has(key)
}

export const keyPressWrapper = <A extends unknown[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: A) => any,
  ...args: A
) => {
  return (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      fn(...args)
    }
  }
}

export const enterWrapper = <A extends unknown[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: A) => any,
  ...args: A
) => {
  return (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      fn(...args)
    }
  }
}

type CN =
  | string
  | Partial<Record<string, boolean | null | undefined>>
  | string[]

export const classNames = (...args: CN[]): string =>
  args.reduce<string>(
    (accum, arg) =>
      `${accum} ${
        typeof arg === "string"
          ? arg
          : Array.isArray(arg)
          ? arg.join(" ")
          : objectEntries(arg)
              .flatMap(([className, active]) => (active ? [className] : []))
              .join(" ")
      }`,
    ""
  )

type FlashOptions = {
  className?: string
  duration?: number
}

export const flashElem = (elem: HTMLElement, opts?: FlashOptions) => {
  const className = opts?.className ?? "flash"
  const duration = opts?.duration ?? 250
  elem.classList.add(className)
  setTimeout(() => elem.classList.remove(className), duration)
}

const mapLibreCSSElemId = "maplibreStylesheet"

export const useMapView = () => {
  const existingElem = document.querySelector(`#${mapLibreCSSElemId}`)
  if (existingElem) {
    return
  }
  const cssElem = document.createElement("link")
  cssElem.href = "https://unpkg.com/maplibre-gl@2.4.0/dist/maplibre-gl.css"
  cssElem.rel = "stylesheet"
  cssElem.id = mapLibreCSSElemId
  document.head.appendChild(cssElem)
}

export const isMobile = () =>
  window.matchMedia("only screen and ((max-width: 767px))").matches

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => unknown>(
  fn: F,
  delay = 300
): F => {
  const queue = newTypedArray<unknown[]>()
  const fnChecker = () => {
    const args = queue.pop()
    if (queue.length === 0) {
      fn(...args!)
    }
  }
  const wrapped = (...args: unknown[]) => {
    queue.unshift(args)
    setTimeout(fnChecker, delay)
  }
  return wrapped as F
}

export const someRowHasLocation = ({
  sheetContent,
}: {
  sheetContent: ProcessedSheetContent
}) =>
  sheetContent.rows.some((row) =>
    getParsedValueByField({
      field: EnumT.Field.location,
      sheetContent,
      rowId: row.id,
    })
  )

export const getBaseUrl = () =>
  assertDefined(import.meta.env.VITE_APP_URL_BASE as string | undefined)

export const getCurrentUrl = () =>
  new URL(window.location.pathname, getBaseUrl()).toString()

export const objectKeys = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, V>
) => Object.keys(obj) as K[]

export const fromEntries = <K extends string | number | symbol, V>(
  entries: [K, V][]
) => Object.fromEntries(entries) as PartialRecord<K, V>

export const filterObj = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, V>,
  filter: (k: K, v: V) => boolean
) => fromEntries(objectEntries(obj).filter(([k, v]) => filter(k, v)))

export const filterObjByValues = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, V>,
  filter: (v: V) => boolean
) => filterObj(obj, (_: K, v: V) => filter(v))

export const filterObjByKeys = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, V>,
  filter: (k: K) => boolean
) => filterObj(obj, (k: K) => filter(k))

export const filterObjValsNonNullish = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, Undefined<V>>
) => filterObjByValues(obj, (v: Undefined<V>) => !!v)

export const preventDefault = (ev: Event) => ev.preventDefault()
export const stopPropagation = (fn?: () => void) => (ev: Event) => {
  ev.stopPropagation()
  fn?.()
}

// Extract event detail
export const eed =
  <T>(cb: (data: T) => void) =>
  (ev: CustomEvent<T>) =>
    cb(ev.detail)

const focusableElements =
  'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])'

export const getFocusableChildrenRecursive = (elem: HTMLElement) => {
  const focusableContent = newTypedArray<Focusable>(
    Array.from(elem.querySelectorAll(focusableElements))
  )
  const firstFocusableElement = focusableContent?.[0]
  const lastFocusableElement =
    focusableContent?.[(focusableContent?.length ?? 0) - 1]
  return {
    focusableContent,
    firstFocusableElement,
    lastFocusableElement,
  }
}

type TrappedFocusItem = {
  id: string
  elem: HTMLElement
  onEscape?: () => void
}

const trappedStack = newTypedArray<TrappedFocusItem>()

const trappedFocusKeydown = (e: KeyboardEvent) => {
  const item = trappedStack[trappedStack.length - 1]
  if (!item) {
    throw "unexpected: event listener should have been removed"
  }
  const { firstFocusableElement, lastFocusableElement } =
    getFocusableChildrenRecursive(item.elem)

  if (e.key === TabKey) {
    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement?.focus()
        e.preventDefault()
      }
    } else if (document.activeElement === lastFocusableElement) {
      firstFocusableElement?.focus()
      e.preventDefault()
    }
  }
  if (EscapeKeys.has(e.key)) {
    item.onEscape?.()
  }
}

export const useTrapFocus = ({
  elem,
  onEscape,
  nthToFocusFirst,
}: {
  elem: HTMLElement
  onEscape?: () => void
  nthToFocusFirst?: number
}) => {
  const id = crypto.randomUUID()
  if (trappedStack.length === 0) {
    document.addEventListener("keydown", trappedFocusKeydown)
  }

  trappedStack.push({ id, elem, onEscape })

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  tick().then(() => {
    const { focusableContent } = getFocusableChildrenRecursive(elem)
    focusableContent[nthToFocusFirst ?? 0]?.focus()
  })

  return {
    release: () => {
      const index = trappedStack.findIndex((item) => item.id === id)
      if (index < 0) {
        return // already released, noop
      }
      trappedStack.splice(index, 1)
      if (trappedStack.length === 0) {
        document.removeEventListener("keydown", trappedFocusKeydown)
      }
    },
  }
}

// Based on: https://stackoverflow.com/a/49186677
export const getScrollParent = (node: Element) => {
  const regex = /(auto|scroll)/
  const parents = (_node: Element, ps: Element[]): Element[] => {
    if (_node.parentNode === null) {
      return ps
    }
    return parents(_node.parentNode as Element, ps.concat([_node]))
  }

  const style = (_node: Element, prop: string) =>
    getComputedStyle(_node, null).getPropertyValue(prop)
  const overflow = (_node: Element) =>
    style(_node, "overflow") +
    style(_node, "overflow-y") +
    style(_node, "overflow-x")
  const scroll = (_node: Element) => regex.test(overflow(_node))

  /* eslint-disable consistent-return */
  const scrollParent = (_node: Element) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return
    }

    if (_node.parentNode === null) {
      return
    }

    const ps = parents(_node.parentNode as Element, [])

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i]
      }
    }

    return document.scrollingElement || document.documentElement
  }

  return scrollParent(node)
  /* eslint-enable consistent-return */
}

export const isDev = () => import.meta.env.DEV

const PHONE_NUMBER_SEPARATOR_CHARS = new Set(["(", ")", "-", "."])
const ALLOWED_PHONE_NUMBER_CHARS = new Set([
  "+",
  ...numbers,
  ...PHONE_NUMBER_SEPARATOR_CHARS,
])
// simple phone number formatter to support the majority of cases
export const formatPhoneNumber = (pn: string): string => {
  const charArr = pn.trim().split("")
  if (charArr.some((c) => PHONE_NUMBER_SEPARATOR_CHARS.has(c))) {
    // assume already formatted
    return pn.trim()
  } else if (!charArr.every((c) => ALLOWED_PHONE_NUMBER_CHARS.has(c))) {
    // assume it's garbage
    return pn.trim()
  }
  const lastTen = charArr.splice(-10).join("")
  const prefix = (
    charArr[0] === "+"
      ? charArr
      : ["+", ...(charArr.length > 0 ? charArr : "1")]
  ).join("")
  return `${prefix} (${lastTen.slice(0, 3)}) ${lastTen.slice(
    3,
    6
  )}-${lastTen.slice(6)}`
}

export const logException = (
  err: unknown,
  message?: string,
  level?: Sentry.SeverityLevel
) => {
  console.error(`exception${message ? ` - ${message}` : ""}:`, err)
  Sentry.captureException(err, { data: message, level: level as undefined })
}
