import { CurrencyCode } from "../schema/currencies.ts"
import { EnumT } from "../schema/index.ts"
import { DateTime } from "luxon"
import type {
  FormatSpec,
  GenericDataValue,
  JsonValue,
  TransitMode,
} from "../types.ts"
import { objectValues } from "../util/index.ts"

export type FieldInfo<
  FT extends EnumT.FormatSpecType,
  DT extends EnumT.DataType
> = {
  name: string
  dataType: DT
  formatSpec: { type: FT } & FormatSpec
  columnBehavior: EnumT.DefaultColumnBehavior
  sortedOrder: number
  llmExtra?: {
    exclude?: true
    hint?: string
    parser?: (value: JsonValue) => GenericDataValue | null
  }
  listingTypes: EnumT.ListingType[]
}

const buildFieldInfo = <
  F extends EnumT.FormatSpecType,
  DT extends EnumT.DataType
>(
  fieldInfo: FieldInfo<F, DT>
) => fieldInfo

export const NULL_FORMAT_SPEC: FormatSpec = { type: EnumT.FormatSpecType.none }

const ALL_LISTING_TYPES = objectValues(EnumT.ListingType)

const dateParser = (value: JsonValue) => {
  if (typeof value !== "string") {
    return null
  }
  const parsed = DateTime.fromFormat(value, "MM-dd-yyyy").toSeconds()
  if (isNaN(parsed)) {
    return null
  }
  return parsed
}

export const fieldInfoMap = {
  [EnumT.Field.url]: buildFieldInfo({
    name: "Link",
    dataType: EnumT.DataType.text,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.include,
    sortedOrder: 500,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.beds]: buildFieldInfo({
    name: "Beds",
    dataType: EnumT.DataType.number,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 600,
    listingTypes: [EnumT.ListingType.house, EnumT.ListingType.apartment],
  }),
  [EnumT.Field.baths]: buildFieldInfo({
    name: "Baths",
    dataType: EnumT.DataType.number,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 700,
    listingTypes: [EnumT.ListingType.house, EnumT.ListingType.apartment],
  }),
  [EnumT.Field.title]: buildFieldInfo({
    name: "Title",
    dataType: EnumT.DataType.text,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 100,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.images]: buildFieldInfo({
    name: "Images",
    dataType: EnumT.DataType.textArray,
    formatSpec: { type: EnumT.FormatSpecType.images },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 400,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.location]: buildFieldInfo({
    name: "Location",
    dataType: EnumT.DataType.json,
    formatSpec: { type: EnumT.FormatSpecType.locationDetails },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 300,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.priceUsd]: buildFieldInfo({
    name: "Price",
    dataType: EnumT.DataType.number,
    formatSpec: {
      type: EnumT.FormatSpecType.currency,
      currencyCode: CurrencyCode.USD,
    },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 200,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.priceGbp]: buildFieldInfo({
    name: "Price",
    dataType: EnumT.DataType.number,
    formatSpec: {
      type: EnumT.FormatSpecType.currency,
      currencyCode: CurrencyCode.GBP,
    },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 200,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.availableAt]: buildFieldInfo({
    name: "Date Available",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 250,
    llmExtra: {
      parser: dateParser,
    },
    listingTypes: [EnumT.ListingType.house, EnumT.ListingType.apartment],
  }),
  [EnumT.Field.source]: buildFieldInfo({
    name: "Source",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.source },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 10,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.dateListed]: buildFieldInfo({
    name: "Date Listed",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 225,
    llmExtra: {
      parser: dateParser,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.sqft]: buildFieldInfo({
    name: "sq ft",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 275,
    listingTypes: [EnumT.ListingType.house, EnumT.ListingType.apartment],
  }),
  [EnumT.Field.predictedPriceUsd]: buildFieldInfo({
    name: "Estimated Price",
    dataType: EnumT.DataType.number,
    formatSpec: {
      type: EnumT.FormatSpecType.currency,
      currencyCode: CurrencyCode.USD,
    },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 201,
    llmExtra: {
      hint: "market price for the item",
    },
    listingTypes: [
      EnumT.ListingType.house,
      EnumT.ListingType.apartment,
      EnumT.ListingType.automobile,
    ],
  }),
  [EnumT.Field.certifiedPreowned]: buildFieldInfo({
    name: "Certified Preowned",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 530,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.usageStatus]: buildFieldInfo({
    name: "Usage",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 529,
    llmExtra: {
      hint: "is it used (true) or new (false)?",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.sellerName]: buildFieldInfo({
    name: "Seller",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 440,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.sellerUrl]: buildFieldInfo({
    name: "Seller URL",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 441,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.sellerPhone]: buildFieldInfo({
    name: "Seller Phone",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.phone },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 442,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.bodyStyle]: buildFieldInfo({
    name: "Body",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    llmExtra: {
      hint: "Body style for car",
    },
    sortedOrder: 202,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.cabType]: buildFieldInfo({
    name: "Cab Type",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 203,
    llmExtra: {
      hint: "Cab type for car",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.cityMpg]: buildFieldInfo({
    name: "City MPG",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 204,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.highwayMpg]: buildFieldInfo({
    name: "Highway MPG",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 205,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.cylinderCount]: buildFieldInfo({
    name: "Cylinders",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 330,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.doorCount]: buildFieldInfo({
    name: "Doors",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 331,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.seatCount]: buildFieldInfo({
    name: "Seats",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 332,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.drivetrain]: buildFieldInfo({
    name: "Drivetrain",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 340,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.engine]: buildFieldInfo({
    name: "Engine",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 341,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.exteriorColor]: buildFieldInfo({
    name: "Exterior",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 410,
    llmExtra: {
      hint: "Exterior color",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.interiorColor]: buildFieldInfo({
    name: "Interior",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 411,
    llmExtra: {
      hint: "Interior color",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.financingEligible]: buildFieldInfo({
    name: "Financing Eligible",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 750,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.fuelType]: buildFieldInfo({
    name: "Fuel",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 275,
    llmExtra: {
      hint: "fuel type (gas, electric, hybrid, etc)",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.sellerType]: buildFieldInfo({
    name: "Seller Type",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 730,
    llmExtra: {
      hint: "seller type (individual, dealer, agent, corporation)",
    },
    listingTypes: [
      EnumT.ListingType.automobile,
      EnumT.ListingType.house,
      EnumT.ListingType.apartment,
    ],
  }),
  [EnumT.Field.trim]: buildFieldInfo({
    name: "Trim",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 735,
    llmExtra: {
      hint: "trim style for vehicle",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.rating]: buildFieldInfo({
    name: "Rating",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.rating },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 265,
    llmExtra: {
      hint: "give in format [averageRating, maxRating], so an example would be [4.21, 5]",
      parser: (value: JsonValue) => {
        if (!Array.isArray(value)) {
          return null
        }
        if (value.length !== 2) {
          return null
        }
        const averageRating = value[0]
        const maxRating = value[1]
        if (
          typeof averageRating !== "number" ||
          typeof maxRating !== "number"
        ) {
          return null
        }
        return averageRating / maxRating
      },
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.cleanTitle]: buildFieldInfo({
    name: "Clean Title",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 520,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.oneOwner]: buildFieldInfo({
    name: "One Owner",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 519,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.noAccidents]: buildFieldInfo({
    name: "No Accidents",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 518,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.openRecall]: buildFieldInfo({
    name: "Open Recall",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 521,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.personalUse]: buildFieldInfo({
    name: "Personal Use",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 522,
    llmExtra: {
      hint: "was the vehicle only used for personal use?",
    },
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.make]: buildFieldInfo({
    name: "Make",
    dataType: EnumT.DataType.text,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 725,
    llmExtra: {
      hint: "the Make of the item",
    },
    listingTypes: [EnumT.ListingType.automobile, EnumT.ListingType.furniture],
  }),
  [EnumT.Field.model]: buildFieldInfo({
    name: "Model",
    dataType: EnumT.DataType.text,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.hide,
    sortedOrder: 726,
    llmExtra: {
      hint: "the Model of the item",
    },
    listingTypes: [EnumT.ListingType.automobile, EnumT.ListingType.furniture],
  }),
  [EnumT.Field.year]: buildFieldInfo({
    name: "Year",
    dataType: EnumT.DataType.number,
    formatSpec: NULL_FORMAT_SPEC,
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 175,
    llmExtra: {
      hint: "the year the item was introduced",
    },
    listingTypes: [EnumT.ListingType.automobile, EnumT.ListingType.furniture],
  }),
  [EnumT.Field.mileage]: buildFieldInfo({
    name: "Mileage",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 241,
    listingTypes: [EnumT.ListingType.automobile],
  }),
  [EnumT.Field.homeDelivery]: buildFieldInfo({
    name: "Home Delivery",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 341,
    listingTypes: [EnumT.ListingType.automobile, EnumT.ListingType.furniture],
  }),
  [EnumT.Field.numReviews]: buildFieldInfo({
    name: "Number of Reviews",
    dataType: EnumT.DataType.number,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 266,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.externalId]: buildFieldInfo({
    name: "External ID",
    dataType: EnumT.DataType.text,
    formatSpec: { type: EnumT.FormatSpecType.none },
    columnBehavior: EnumT.DefaultColumnBehavior.include,
    sortedOrder: 1,
    llmExtra: {
      exclude: true,
    },
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.isAvailable]: buildFieldInfo({
    name: "Is Available",
    dataType: EnumT.DataType.boolean,
    formatSpec: { type: EnumT.FormatSpecType.checkbox },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 24,
    listingTypes: ALL_LISTING_TYPES,
  }),
  [EnumT.Field.notes]: buildFieldInfo({
    name: "Notes",
    dataType: EnumT.DataType.json,
    formatSpec: { type: EnumT.FormatSpecType.markdown },
    columnBehavior: EnumT.DefaultColumnBehavior.create,
    sortedOrder: 312,
    listingTypes: [],
  }),
} as const

type _ExhaustivenessChecker = Record<
  EnumT.Field,
  FieldInfo<EnumT.FormatSpecType, EnumT.DataType>
>

// This is unused, it is only here to throw a static type error if data in
// fieldInfoMap is not properly typed
const _checker: _ExhaustivenessChecker = fieldInfoMap

type AutoCreatedColumn = {
  name: string
  formatSpec: FormatSpec
  dataType: EnumT.DataType
  sortedOrder: number
}

export const AUTO_CREATED_COLUMNS: AutoCreatedColumn[] = []

type ListingTypeInfo = {
  name: {
    singular: string
    plural: string
    indefiniteArticle: string
  }
  emoji: string
  examples: string[]
}

export const listingTypeInfoMap = {
  [EnumT.ListingType.house]: {
    name: {
      singular: "House",
      plural: "Houses",
      indefiniteArticle: "a",
    },
    emoji: "🏡",
    examples: ["house", "home"],
  },
  [EnumT.ListingType.apartment]: {
    name: {
      singular: "Apartment",
      plural: "Apartments",
      indefiniteArticle: "an",
    },
    emoji: "🌆",
    examples: ["apartment", "home"],
  },
  [EnumT.ListingType.furniture]: {
    name: {
      singular: "Furniture",
      plural: "Furniture",
      indefiniteArticle: "some",
    },
    emoji: "🛋️",
    examples: ["desk", "sofa", "couch", "coffee table"],
  },
  [EnumT.ListingType.automobile]: {
    name: {
      singular: "Automobile",
      plural: "Automobiles",
      indefiniteArticle: "an",
    },
    emoji: "🚗",
    examples: ["car", "vehicle", "automobile"],
  },
  [EnumT.ListingType.homestay]: {
    name: {
      singular: "Home rental",
      plural: "Home rentals",
      indefiniteArticle: "a",
    },
    emoji: "🌴",
    examples: ["rental", "room"],
  },
  [EnumT.ListingType.hostel]: {
    name: {
      singular: "Hostel",
      plural: "Hostels",
      indefiniteArticle: "a",
    },
    emoji: "🛌",
    examples: ["hostel"],
  },
}

type ListingTypeInfoMap = Record<EnumT.ListingType, ListingTypeInfo>

const _checker1: ListingTypeInfoMap = listingTypeInfoMap

export const sourceInfoMap: Record<
  EnumT.Source,
  { name: string; bg: string; listingTypes?: EnumT.ListingType[] }
> = {
  [EnumT.Source.streetEasy]: {
    name: "StreetEasy",
    bg: "#006aff",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.apartmentsCom]: {
    name: "Apartments.com",
    bg: "#478500",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.genericLink]: {
    name: "URL",
    bg: "#494949",
  },
  [EnumT.Source.zooplaCouk]: {
    name: "Zoopla",
    bg: "#8046f1",
  },
  [EnumT.Source.noSource]: {
    name: "Text",
    bg: "#494949",
  },
  [EnumT.Source.redfin]: {
    name: "Redfin",
    bg: "#d92228",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.realtor]: {
    name: "Realtor.com",
    bg: "#d92228",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.zillow]: {
    name: "Zillow",
    bg: "#006aff",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.cars]: {
    name: "Cars.com",
    bg: "#532380",
    listingTypes: [EnumT.ListingType.automobile],
  },
  [EnumT.Source.wayfair]: {
    name: "Wayfair",
    bg: "#532380",
    listingTypes: [EnumT.ListingType.furniture],
  },
  [EnumT.Source.trulia]: {
    name: "Trulia",
    bg: "#00adbb",
    listingTypes: [EnumT.ListingType.apartment, EnumT.ListingType.house],
  },
  [EnumT.Source.airbnb]: {
    name: "Airbnb",
    bg: "#ff385c",
    listingTypes: [EnumT.ListingType.homestay],
  },
  [EnumT.Source.vrbo]: {
    name: "VRBO",
    bg: "#245abc",
    listingTypes: [EnumT.ListingType.homestay],
  },
  [EnumT.Source.craigslist]: {
    name: "Craigslist",
    bg: "#532380",
    listingTypes: [
      EnumT.ListingType.automobile,
      EnumT.ListingType.apartment,
      EnumT.ListingType.furniture,
    ],
  },
  [EnumT.Source.hostelworld]: {
    name: "Hostelworld",
    bg: "#f25621",
    listingTypes: [EnumT.ListingType.hostel],
  },
  [EnumT.Source.corcoran]: {
    name: "Corcoran",
    bg: "#000000",
    listingTypes: [EnumT.ListingType.house, EnumT.ListingType.apartment],
  },
}

export const defaultSheetNameForListingType = (
  listingType: EnumT.ListingType
) => `${listingTypeInfoMap[listingType].name.singular} search`

export const TRANSIT_MODE_EMOJI_LOOKUP: Record<TransitMode, string> = {
  walking: "🚶",
  bicycling: "🚲",
  driving: "🚗",
  transit: "🚌",
}
