<script lang="ts" context="module">
  import type { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import Icon from "@/lib/Icon.svelte"

  export type FilterArgumentSuggestionT =
    | {
        type: "column"
        columnId: string
        name: string
        field?: EnumT.Field
      }
    | {
        type: "literal"
        value: GenericDataValue
        dataType: EnumT.DataType
        formatSpec: FormatSpec
      }
</script>

<script lang="ts">
  import DataFormatDisplay from "@/lib/DataFormat/display/DataFormatDisplay.svelte"
  import { fieldToIconMap } from "@/constants"
  export let value: FilterArgumentSuggestionT
</script>

{#key value}
  <div>
    {#if value.type === "column"}
      <span
        >{#if value.field}<Icon
            icon={fieldToIconMap[value.field]}
          />{:else}Field:{/if}
        {value.name}</span
      >
    {:else}
      <DataFormatDisplay
        value={value.value}
        dataType={value.dataType}
        formatSpec={value.formatSpec}
      />
    {/if}
  </div>
{/key}
