import type { PartialRecord } from "../types.ts"
import { newTypedArray, objectEntries } from "../util/index.ts"

type ParserResult<T> = { parsed: T; rest: string }
type ParserFunc<T> = (input: string) => ParserResult<T>
type ExtractParserT<PT> = PT extends Parser<infer T> ? T : never

export class ParserError {
  message: string
  expected: boolean
  extra: PartialRecord<string, unknown> | undefined

  constructor(
    message: string,
    expected: boolean,
    extra?: PartialRecord<string, unknown>
  ) {
    this.message = message
    this.expected = expected
    this.extra = extra
  }

  toString() {
    return `${this.expected ? "unexpected " : ""}parse error: ${this.message}`
  }
}

const checkExpectedError = (err: unknown) => {
  if (err instanceof ParserError && err.expected) {
    return
  }
  throw err
}

export class Parser<T> {
  func: ParserFunc<T>

  constructor(func: ParserFunc<T>) {
    this.func = func
  }

  then = <X>(p: Parser<X>) =>
    new Parser((input) => {
      const result = this.func(input)
      return p.func(result.rest)
    })

  skip = <X>(p: Parser<X>) =>
    new Parser((input) => {
      const result = this.func(input)
      const skippedResult = p.func(result.rest)
      return {
        parsed: result.parsed,
        rest: skippedResult.rest,
      }
    })

  many = () =>
    new Parser<T[]>((input) => {
      let rest = input
      const parsed = newTypedArray<T>()
      while (rest != "") {
        try {
          const result = this.func(rest)
          rest = result.rest
          parsed.push(result.parsed)
        } catch (err: unknown) {
          checkExpectedError(err)
          break
        }
      }
      return {
        parsed,
        rest,
      }
    })

  atleast = (n: number) =>
    new Parser((input) => {
      const result = this.many().func(input)
      if (result.parsed.length < n) {
        throw new ParserError(`${result.parsed.length} < ${n}`, true)
      }
      return result
    })

  optional = <X = T>(defaultValue?: X) =>
    new Parser<X | T | undefined>((input) => {
      try {
        return this.func(input)
      } catch (err: unknown) {
        checkExpectedError(err)
        return {
          rest: input,
          parsed: defaultValue,
        }
      }
    })

  optionalFactory = <X = T>(defaultFactory: () => X) =>
    new Parser<X | T | undefined>((input) => {
      try {
        return this.func(input)
      } catch (err: unknown) {
        checkExpectedError(err)
        return {
          rest: input,
          parsed: defaultFactory(),
        }
      }
    })

  parse = (input: string) => {
    const result = this.func(input)
    if (result.rest !== "") {
      throw `remaining to parse: ${result.rest}`
    }
    return result.parsed
  }

  parsePartial = (input: string) => {
    return this.func(input).parsed
  }

  combine = <X>(mapper: (parsedMap: T) => X) =>
    new Parser<X>((input) => {
      const result = this.func(input)

      return {
        parsed: mapper(result.parsed),
        rest: result.rest,
      }
    })

  result = <X>(overrideValue: X) => this.combine(() => overrideValue)

  become = (p: Parser<T>) => {
    this.func = p.func
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asEnum = <E extends PartialRecord<string | number | symbol, any>>(
    enumeration: E
  ) =>
    new Parser<E[keyof E]>((input) => {
      const result = this.func(input)
      const entries = [...objectEntries(enumeration)].sort(([keyA], [keyB]) =>
        keyB.toString().localeCompare(keyA.toString())
      )
      for (const [key, value] of entries) {
        if (result.parsed === key.toString()) {
          return {
            parsed: value as E[keyof E],
            rest: result.rest,
          }
        }
      }
      throw new ParserError(
        `enum ${JSON.stringify(enumeration)} unmatched on ${input}`,
        true
      )
    })

  debug = (message: string) =>
    new Parser((input) => {
      try {
        const result = this.func(input)
        console.log("DBG:", message, result)
        return result
      } catch (err: unknown) {
        console.log("DBG:", message, err)
        throw err
      }
    })
}

export const seqMap = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  V extends Parser<any>,
  X extends PartialRecord<string | number | symbol, V>
>(
  orderedParserMap: X
) =>
  new Parser<{ [K in keyof X]: ExtractParserT<X[K]> }>((input) => {
    let rest = input
    const parsed = Object.fromEntries(
      objectEntries(orderedParserMap).map(([key, parser]) => {
        const result = parser.func(rest)
        rest = result.rest
        return [key, result.parsed]
      })
    ) as { [K in keyof X]: ExtractParserT<X[K]> }
    return {
      rest,
      parsed,
    }
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const alt = <X extends Parser<any>[]>(...parsers: X) =>
  new Parser<ExtractParserT<X[number]>>((input) => {
    for (const parser of parsers) {
      try {
        return parser.func(input)
      } catch (err: unknown) {
        checkExpectedError(err)
      }
    }
    throw new ParserError(`no parsers matched alt ${input}`, true)
  })

export const term = (t: string) =>
  new Parser((input) => {
    if (!input.startsWith(t)) {
      throw new ParserError(`${input} does not start with ${t}`, true)
    }
    return {
      rest: input.slice(t.length),
      parsed: t,
    }
  })

export const regex = (exp: RegExp) =>
  new Parser((input) => {
    let expStr = exp.source
    if (!expStr.startsWith("^")) {
      expStr = "^" + expStr
    }
    const compiled = new RegExp(expStr)
    const matches = compiled.exec(input)
    if (!matches || matches.length < 1 || matches[0].length < 1) {
      throw new ParserError(`regex failed: ${expStr} on ${input}`, true)
    }
    return {
      parsed: matches[0],
      rest: input.slice(matches[0].length),
    }
  })

export const whitespace = regex(/\s/)

export const lex = <T>(p: Parser<T>) =>
  whitespace.optional().then(p).skip(whitespace.optional())

export const EOF = new Parser((input) => {
  if (input === "") {
    return {
      parsed: null,
      rest: "",
    }
  }
  throw new ParserError(`input not EOF: ${input}`, true)
})

export const forwardDeclaration = <T>() =>
  new Parser<T>((_) => {
    throw "need to call .become first"
  })

export const enumParser = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends PartialRecord<string | number | symbol, any>
>(
  enumeration: E
) =>
  new Parser<E[keyof E]>((input) => {
    const entries = [...objectEntries(enumeration)].sort(([keyA], [keyB]) =>
      keyB.toString().localeCompare(keyA.toString())
    )
    for (const [key, value] of entries) {
      const keyStr = key.toString()
      if (input.startsWith(keyStr)) {
        return {
          parsed: value as E[keyof E],
          rest: input.slice(keyStr.length),
        }
      }
    }
    throw new ParserError(
      `enum ${JSON.stringify(enumeration)} unmatched on ${input}`,
      true
    )
  })
