<script lang="ts">
  import Icon from "@/lib/Icon.svelte"
  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
  import { onMount } from "svelte"
  import { fade, fly } from "svelte/transition"
  import CondWrapper from "./CondWrapper.svelte"

  export let title: string
  export let to: string | undefined
  export let icon: IconDefinition = faLocationDot

  let visible = false
  onMount(() => {
    visible = true
  })
</script>

{#if visible}
  <div class="wrapper" transition:fade={{ duration: 250 }}>
    <CondWrapper component={RouterLink} {to} wrap={!!to}>
      <div transition:fly={{ y: 200, duration: 300 }}>
        <div class="title">
          <Icon {icon} />
          {title}
        </div>
        <div class="content">
          <div class="quick-fact-section">
            <slot />
          </div>
        </div>
      </div>
    </CondWrapper>
  </div>
{/if}

<style>
  .quick-fact-section {
    margin-top: 8px;
    display: flex;
    width: 100%;
    font-size: 115%;
    flex-wrap: wrap;
    gap: 10px;
  }
  .title {
    background-color: var(--action-alt);
    color: var(--action-alt-fg);
    padding: 8px 8px 4px 8px;
    font-weight: 800;
    font-size: 120%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :global(.mapboxgl-popup-content) {
    background: none !important;
    padding: 0 !important;
  }
  :global(.maplibregl-popup-tip) {
    display: none !important;
  }
  :global(.maplibregl-popup-close-button) {
    color: var(--action-alt-fg) !important;
  }
  .content {
    background-color: var(--secondary-bg);
    padding: 4px 8px 4px 8px;
  }
  .wrapper {
    border-radius: 8px;
    overflow: hidden;
    width: 200px;
    box-shadow: 5px 5px 22px 1px rgba(0, 0, 0, 0.5);
  }
</style>
