import type { CachedFuncCall, FunctionCall } from "@shared/types"
import { setDifferenceImmutable } from "@shared/util/index.ts"
import { ApiClient } from "./client"
import { LocalStorageKey, setItem } from "@/util"

export const apiClient = new ApiClient(
  import.meta.env.VITE_APP_API_BASE as string | undefined
)

type CachedFunctionResolverCB = ({
  cachedFuncCalls,
}: {
  cachedFuncCalls: CachedFuncCall[]
}) => void

export class CachedFunctionResolver {
  private pending: Set<string>
  private submitted: Set<string>
  private client: ApiClient

  constructor(client: ApiClient) {
    this.pending = new Set()
    this.submitted = new Set()
    this.client = client
  }

  push = (funcCall: string) => {
    if (this.submitted.has(funcCall)) {
      return
    }
    this.pending.add(funcCall)
  }

  flush = async (callbackFn: CachedFunctionResolverCB) => {
    const toRequest = setDifferenceImmutable(this.pending, this.submitted)
    this.pending = new Set()

    toRequest.forEach((item) => this.submitted.add(item))

    if (toRequest.size === 0) {
      return
    }

    const results = await this.client.call("getCachedFuncCalls", {
      funcCalls: [...toRequest].map(
        (funcCall) => JSON.parse(funcCall) as FunctionCall
      ),
    })

    callbackFn(results)
  }
}

export const cachedFunctionResolver = new CachedFunctionResolver(apiClient)

export const beginAuth = async () => {
  const { authUrl } = await apiClient.call("beginBrowserAuth", null)

  const timestamp = new Date().getTime()
  setItem(LocalStorageKey.authRedirectUrl, {
    href: window.location.href,
    timestamp,
  })
  window.location.href = authUrl
}

export const beginUnauth = async () => {
  const { unauthUrl } = await apiClient.call("beginBrowserUnauth", null)
  window.location.replace(unauthUrl)
}
