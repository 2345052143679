<script lang="ts">
  import { isMobile, keyPressWrapper } from "@/util"
  import {
    faArrowRight,
    faChevronDown,
    faDollarSign,
    faHeart,
    faPhone,
    faTable,
  } from "@fortawesome/free-solid-svg-icons"
  import Icon from "@/lib/Icon.svelte"
  import SmoothScroll from "smooth-scroll"

  import SourceInfoPanel from "./SourceInfoPanel.svelte"
  import TextMessages from "./TextMessages.svelte"
  import DemoTable from "./DemoTable.svelte"
  import FooterPanel from "./FooterPanel.svelte"
  import MainEnterButton from "./MainEnterButton.svelte"
  import {
    listRoute,
    sheetRedirectRoute,
    tableRoute,
    privacyRoute,
  } from "@/routes"
  import AnimationWrapper from "@/lib/AnimationWrapper.svelte"
  import HowItWorks from "./HowItWorks.svelte"
  import { defaultSheetNameForListingType } from "@shared/data/defaults"
  import { navigateTo } from "@/lib/RouterView"
  import { globalChooseListingTypeModal } from "@/lib/modals"
  import { apiClient } from "@/api"
  import type { EnumT } from "@shared/schema"
  import MainNav from "@/lib/MainNav.svelte"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"

  const demoSheetLink = import.meta.env.VITE_APP_DEMO_SHEET_ID
    ? sheetRedirectRoute({ sheetId: import.meta.env.VITE_APP_DEMO_SHEET_ID })
    : undefined

  const smoothScrollToEnd = () =>
    new SmoothScroll().animateScroll(
      document.querySelector("#how-it-works"),
      null,
      { offset: 66 }
    )

  const createSheet = () => {
    globalChooseListingTypeModal.open({
      props: {},
      onAccept: async (listingType: EnumT.ListingType | null) => {
        const { sheetId } = await apiClient.call("createSheet", {
          listingType,
          name: listingType
            ? defaultSheetNameForListingType(listingType)
            : undefined,
        })
        const to = isMobile() ? listRoute({ sheetId }) : tableRoute({ sheetId })
        navigateTo(to)
      },
    })
  }
</script>

<main>
  <div class="background" />
  <MainNav />
  <div class="section-0">
    <div class="section-0-left">
      <div class="section-0-left-inner">
        <div class="subtitle-wrapper">
          <div class="subtitle-1">Moving is stressful.</div>
          <div class="subtitle">Organize your search with Listable.</div>
        </div>
        <div class="create-sheet-wrapper">
          <MainEnterButton {createSheet} />
        </div>
        <div
          class="scroll-hint"
          tabindex="0"
          role="button"
          on:click={smoothScrollToEnd}
          on:keypress={keyPressWrapper(smoothScrollToEnd)}
        >
          <Icon icon={faChevronDown} />
        </div>
      </div>
    </div>
    <div class="section-0-right">
      <SourceInfoPanel />
      <TextMessages />
    </div>
  </div>
  <div class="spacer" />
  <div class="section-0-5" />
  <div class="section-1">
    <AnimationWrapper><DemoTable /></AnimationWrapper>
  </div>
  <div class="section-0-5" />
  <div class="section-2">
    <div class="section-2-inner">
      <HowItWorks {createSheet} />
      <div class="footer-grid">
        <FooterPanel
          variant="action-alt"
          actionText="View demo"
          actionIconRight={faArrowRight}
          icon={faTable}
          title="Demo"
          to={demoSheetLink}
          >See an example of how to use Listable to search for apartments.</FooterPanel
        >
        <FooterPanel
          actionText="Drop us a line"
          actionIconLeft={faPhone}
          emoji="☕"
          title="Let's Chat"
          to="sms://+18606317105"
          >In NYC? Let us buy you a drink and talk about how Listable can serve
          you better. You can also send us a text at <a
            href="sms://+18606317105">(860) 631-7105</a
          >.</FooterPanel
        >
        <FooterPanel
          variant="action"
          actionText="Learn more"
          actionIconRight={faArrowRight}
          icon={faHeart}
          title="Web Stewardship Statement"
          >Listable is intentional about earning your trust on a web filled with
          <a href="https://www.deceptive.design">deceptive patterns</a>. Read
          about the measures we take to be good stewards of our corner of the
          internet.</FooterPanel
        >
        <FooterPanel
          actionText="Learn more"
          variant="success"
          actionIconRight={faArrowRight}
          icon={faDollarSign}
          title="Premium"
          >Listable is free for most use cases, and also offers optional premium
          features. Learn more about our upfront pricing.</FooterPanel
        >
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="made-with-love">
        Made with <img src="/heart.svg" class="heart" alt="pixelated heart" /> in
        NYC
      </div>
      <div class="footer">
        <div class="footer-links">
          <RouterLink to={privacyRoute()}>Privacy</RouterLink>
        </div>
      </div>
    </div>
  </div>
  <div class="dummy" />
</main>

<style>
  :root {
    --square-size: 10vh;
    --logo-margin: var(--spacing-sm);
  }
  .background {
    position: fixed;
    z-index: -1;
    background-position: center top;
    top: calc(50px + 2 * var(--spacing-md));
    left: 0;
    right: 0;
    bottom: 0;
    background-size: var(--square-size) var(--square-size);
    background-image: linear-gradient(
        to right,
        var(--primary-accent-light) 1px,
        transparent 1px
      ),
      linear-gradient(
        to bottom,
        var(--primary-accent-light) 1px,
        transparent 1px
      );
  }
  .spacer {
    width: 1px;
    height: 1px;
  }
  .scroll-hint {
    width: 50px;
    text-align: center;
    display: block;
    font-size: 60px;
    text-align: center;
    z-index: 15;
    cursor: pointer;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--primary-fg);
    margin-left: auto;
    margin-right: auto;
    margin-top: -75px;
  }
  .dummy {
    opacity: 0;
  }
  .section-0 {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .section-0-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 2;
  }
  .section-0-left-inner {
    text-align: center;
  }
  .section-0-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 2;
  }
  .section-2 {
    min-height: 100vh;
  }
  .section-1 {
    height: 300vh;
  }
  .section-0-5 {
    height: 20vh;
  }
  .subtitle {
    margin-top: 55px;
    font-size: 20px;
    max-width: calc(min(500px, 90vw));
    margin-left: auto;
    margin-right: auto;
  }
  .create-sheet-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    z-index: 20;
  }
  .footer-grid {
    margin-top: 100px;
    display: grid;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    gap: 75px 50px;
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 1200px) {
    .section-0-left-inner {
      position: sticky;
      top: 0;
    }
    .section-0 {
      display: flex;
      flex-direction: row;
      max-width: 100vw;
    }
    .footer-grid {
      width: 1000px;
    }
    :root {
      --square-size: 7.5vh;
      --logo-margin: var(--spacing-lg);
    }
  }
  .section-2 {
    display: flex;
    flex-direction: column;
  }
  .section-2-inner {
    flex: 1;
  }
  .footer {
    font-weight: 600;
    padding-top: 20px;
    font-size: 18px;
    padding-bottom: 20px;
    text-align: center;
    font-family: "Sarabun", sans-serif;
  }
  .made-with-love {
    font-size: 20px;
    color: var(--primary-fg);
    font-weight: 800;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 5px;
  }
  .heart {
    aspect-ratio: 1 / 1;
    width: 25px;
    vertical-align: middle;
  }
  .footer-links {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1;
  }
  main {
    overflow-x: clip;
    overflow-y: visible;
  }
  :global(.create-sheet-wrapper:hover .create-sheet-sticker-common) {
    opacity: 1;
    transform: translateX(50%) translateY(-50%) scale(0.85);
  }
  .footer-wrapper {
    background: var(--primary-bg);
    z-index: 50;
    border-top: 4px solid var(--primary-accent);
  }
  .subtitle-1 {
    font-size: 34px;
    font-weight: bold;
  }
  .subtitle-wrapper {
    margin-top: 200px;
    background-color: var(--primary-bg);
    outline: 4px solid var(--primary-fg);
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
    border-radius: var(--default-rounding);
  }
</style>
